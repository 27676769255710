import { Relations, UserId } from "@inrev/common";
import { useState } from "react";
import { ActionItemList } from "../../../../../domain/admin/action-item/api";
import { ActionItem } from "../../../../../domain/admin/action-item/type";
import { AdminActionItemsHeader } from "./components/AdminActionItemsHeader";
import { AdminActionItemsLayout } from "./layouts/AdminActionItemsLayout";
import { AdminActionItemCreateSection } from "./sections/AdminActionItemCreateSection";
import { AdminActionItemListSection } from "./sections/AdminActionItemListSection";

export const AdminActionItemView = ({
	actionItemList,
	relation,
	defaultUnderwriterId,
	setCurrentActionItem,
}: {
	actionItemList: ActionItemList;
	relation: Relations.ActionItems;
	defaultUnderwriterId: UserId;
	setCurrentActionItem: (actionItem: ActionItem) => void;
}) => {
	const [openCreateActionItem, setOpenCreateActionItem] = useState(false);

	const filterComponent = <></>;

	const header = (
		<AdminActionItemsHeader
			filterComponent={filterComponent}
			createActionItem={() => setOpenCreateActionItem(true)}
		/>
	);

	const content = (
		<>
			<div className="sticky top-0 z-10 bg-white">
				<AdminActionItemCreateSection
					isOpen={openCreateActionItem}
					relation={relation}
					defaultUnderwriterId={defaultUnderwriterId}
					close={() => setOpenCreateActionItem(false)}
				/>
			</div>
			<div className="flex flex-col overflow-y-hidden">
				<AdminActionItemListSection
					actionItems={actionItemList}
					setCurrentActionItem={setCurrentActionItem}
				/>
			</div>
		</>
	);

	return <AdminActionItemsLayout header={header} content={content} />;
};
