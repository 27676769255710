import Placeholder from "@tiptap/extension-placeholder";
import Underline from "@tiptap/extension-underline";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { BiBold, BiItalic, BiListOl, BiListUl } from "react-icons/bi";
import { FaUnderline } from "react-icons/fa";
import { cn } from "../lib/utils";

export const RichTextEditor = ({
	content,
	ref,
	placeholder,
	onBlur,
	onUpdate,
}: {
	content: string;
	ref?: React.Ref<HTMLDivElement>;
	placeholder?: string;
	onBlur?: () => void;
	onUpdate?: (content: string) => void;
}) => {
	const editor = useEditor(
		{
			editable: true,
			content,
			extensions: [
				StarterKit.configure({
					bold: {
						HTMLAttributes: {
							class: "font-[550]",
						},
					},
				}),
				Underline,
				Placeholder.configure({
					placeholder: placeholder || "Enter text...",
					emptyEditorClass:
						"before:h-0 before:float-left before:content-[attr(data-placeholder)] before:text-gray-400 before:italic cursor-text",
				}),
			],
			editorProps: {
				attributes: {
					class: "outline-none focus:outline-none py-[12px] px-[16px] min-h-[100px] text-[14px]",
				},
			},
			onBlur: onBlur,
			onUpdate: ({ editor }) => {
				if (editor?.getHTML()) {
					if (content === editor.getHTML() || (editor.getHTML() === "<p></p>" && content === "")) {
						return;
					}
					onUpdate?.(editor.getHTML());
				}
			},
		},
		[content],
	);

	if (!editor) return null;

	return (
		<div tabIndex={1} className={cn(" bg-white rounded-md border border-slate-200 !mx-[-12px]")}>
			<div className="flex items-center h-[32px] px-[4px] space-x-[3px] border-b border-b-gray-100 justify-between bg-gray-50 rounded-t-md">
				<div className="flex items-center space-x-[3px] min-w-fit">
					<div
						onMouseDown={(e) => {
							e.preventDefault();
							e.stopPropagation();
							editor.chain().focus().toggleBold().run();
						}}
						className={cn(
							"flex items-center justify-center w-[26px] h-[26px] rounded text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer",
							editor.isActive("bold")
								? "bg-gray-200/80 hover:bg-gray-200/80 text-gray-900"
								: "hover:bg-gray-100 hover:text-gray-900",
						)}
					>
						<BiBold className="text-[15px]" />
					</div>
					<div
						onMouseDown={(e) => {
							e.preventDefault();
							e.stopPropagation();
							editor.chain().focus().toggleItalic().run();
						}}
						className={cn(
							"flex items-center justify-center w-[26px] h-[26px] rounded text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer",
							editor.isActive("italic")
								? "bg-gray-200/80 hover:bg-gray-200/80 text-gray-900"
								: "hover:bg-gray-100 hover:text-gray-900",
						)}
					>
						<BiItalic className="text-[14px] mt-[-.75px]" />
					</div>
					<div
						onMouseDown={(e) => {
							e.preventDefault();
							e.stopPropagation();
							editor.chain().focus().toggleUnderline().run();
						}}
						className={cn(
							"flex items-center justify-center w-[26px] h-[26px] rounded text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer",
							editor.isActive("underline")
								? "bg-gray-200/80 hover:bg-gray-200/80 text-gray-900"
								: "hover:bg-gray-100 hover:text-gray-800",
						)}
					>
						<FaUnderline className="text-[11.5px] mt-[1px]" />
					</div>
					<div
						onMouseDown={(e) => {
							e.preventDefault();
							e.stopPropagation();
							editor.chain().focus().toggleBulletList().run();
						}}
						className={cn(
							"flex items-center justify-center w-[26px] h-[26px] rounded text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer",
							editor.isActive("bulletList")
								? "bg-gray-200/80 hover:bg-gray-200/80 text-gray-900"
								: "hover:bg-gray-100 hover:text-gray-800",
						)}
					>
						<BiListUl className="text-[18.5px] mt-[1px] mb-[1px]" />
					</div>
					<div
						onMouseDown={(e) => {
							e.preventDefault();
							e.stopPropagation();
							editor.chain().focus().toggleOrderedList().run();
						}}
						className={cn(
							"flex items-center justify-center w-[26px] h-[26px] rounded text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer",
							editor.isActive("orderedList")
								? "bg-gray-200/80 hover:bg-gray-200/80 text-gray-900"
								: "hover:bg-gray-100 hover:text-gray-800",
						)}
					>
						<BiListOl className="text-[18.5px] mt-[1px] mb-[1px]" />
					</div>
				</div>
			</div>
			<EditorContent editor={editor} ref={ref} />
		</div>
	);
};
