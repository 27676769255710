import { ReactNode } from "react";
import { cn } from "../../../../../../components/lib/utils";

export const AdminActionItemsLayout = ({
	header,
	content,
	contentClassName,
	footer,
	footerClassName,
}: {
	header?: ReactNode;
	content: ReactNode;
	contentClassName?: string;
	footer?: ReactNode;
	footerClassName?: string;
}) => {
	return (
		<div className="w-full flex flex-col justify-start bg-white z-50 h-full min-h-fit gap-[8px] pb-[16px]">
			{header ?? <></>}
			<div className="h-full overflow-y-auto">
				<div className={cn("min-h-fit pb-12", contentClassName)}>{content}</div>
			</div>
			<div className={footerClassName}>{footer ?? <></>}</div>
		</div>
	);
};
