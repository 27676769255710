import { NoteTopicId, Relations } from "@inrev/common";
import { DateTime } from "luxon";
import { useState } from "react";
import { AiTwotonePushpin } from "react-icons/ai";
import { AdminNote, AdminNoteTopic } from "../../../../../../domain/admin/note/types";
import { AdminNoteEditor } from "./AdminNoteEditor";

export const AdminNoteComponent = ({
	note,
	topicId,
	relation,
	isUpdateAllowed,
	topics,
	setCurrentTopic,
	noteMenu,
}: {
	note: AdminNote;
	topicId: NoteTopicId;
	relation: Relations.Notes;
	isUpdateAllowed: boolean;
	topics: AdminNoteTopic[];
	setCurrentTopic?: (topic: AdminNoteTopic) => void;
	noteMenu: React.ReactNode;
}) => {
	const [openEditor, setOpenEditor] = useState(false);

	return (
		<div>
			{openEditor ? (
				<AdminNoteEditor
					relation={relation}
					topicId={note.topicId}
					content={note.content}
					currentNoteId={note.id}
					closeEditor={() => setOpenEditor(false)}
					isUpdate={true}
				/>
			) : (
				<div
					className="flex flex-col gap-2 bg-gray-50 border border-gray-200 rounded-[4px]"
					onDoubleClick={() => {
						if (isUpdateAllowed) {
							setOpenEditor(true);
						}
					}}
				>
					<div className="flex flex-col px-3">
						<div className="flex flex-row justify-between items-center pt-3">
							<div className="flex items-center gap-2">
								{note.isPinned && (
									<AiTwotonePushpin className="[&>path:first-child]:fill-[#477CCC] [&>path:last-child]:fill-gray-800 text-[14px]" />
								)}
								<div className="text-[12px] font-[550] text-gray-800">
									{note.fromUserFirstName} {note.fromUserLastName}
								</div>
							</div>
							<div className="flex items-center gap-[10px]">
								<div className="text-gray-400 text-[11px]">
									{DateTime.fromISO(note.createdAt).toFormat("LLL dd, yyyy")}
								</div>
								{noteMenu}
							</div>
						</div>
						{note.topicId !== topicId &&
							topics.some((topic) => topic.id === note.topicId) &&
							setCurrentTopic !== undefined && (
								<div
									className="w-fit text-gray-400 text-[11px] underline cursor-pointer hover:text-gray-600"
									onClick={() =>
										setCurrentTopic(topics.find((topic) => topic.id === note.topicId)!)
									}
								>
									{topics.find((topic) => topic.id === note.topicId)?.name}
								</div>
							)}
					</div>
					<div className="text-gray-800 text-[13px] font-[375] px-3 pb-3">{note.content}</div>
				</div>
			)}
		</div>
	);
};
