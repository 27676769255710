import { z } from 'zod';
import { Get as Get$1 } from '../submitted-surety-quote-preview.dto.mixins.mjs';

var Get;
(function (Get) {
    (function (Response) {
        Response.baseSchema = (contractSuretyType) => Get$1.Response.baseSchema(contractSuretyType).extend({
            suretyType: z.literal("contract"),
        });
    })(Get.Response || (Get.Response = {}));
})(Get || (Get = {}));

export { Get };
