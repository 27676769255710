import {
	AttachmentId,
	FileId,
	FileType,
	UploadedFile,
	defaultAttachmentTypeLabelMap,
} from "@inrev/common";
import { DateTime } from "luxon";
import { useMemo, useState } from "react";
import { HiOutlineEye } from "react-icons/hi";
import {
	HiOutlineArrowDownTray,
	HiOutlineCheck,
	HiOutlineTrash,
	HiOutlineXMark,
	HiXMark,
} from "react-icons/hi2";
import { ConfirmationModalClickWrapper } from "../layout/ConfirmationModalClickWrapper";
import { AddTagButton } from "./AddTagButton";
import { AttachmentTypeMultiSelect } from "./AttachmentTypeMultiSelect";
import { CustomFileIcon } from "./CustomFileIcon";
import { PDFViewerModal } from "./PDFViewerModal";
import { Tag } from "./Tag";

type FileUploadItemProps<TAllowedFileType extends FileType, TFileType extends string> = Omit<
	UploadedFile,
	"id" | "types"
> & {
	id: FileId | AttachmentId;
	types: TAllowedFileType[] | TFileType[];
	typeLabelMap: Partial<Record<TAllowedFileType | TFileType, string>>;
	allowDelete?: boolean | undefined;
	maxTypesPerFile?: number;
	expandedByDefault?: boolean;
	enableTypeSelection?: boolean;
	allowTypeConfirmation?: boolean;
	createdAtMs?: number;
	baseUrl?: string;
	queryKey?: string;
	enablePDFViewer?: boolean;
	onTypeConfirmation?: () => void;
	onTypeConfirmationCancel?: () => void;
	onDelete?: (id: string) => void;
	onDownload?: (id: string) => void;
	onSave?: (file: UploadedFile<TAllowedFileType>, newTypes: TAllowedFileType[]) => void;
	onBlur?: () => void;
	deleting?: boolean;
} & (
		| {
				allowDelete?: false | undefined;
		  }
		| {
				allowDelete: true;
				onDelete: (id: string) => void;
				deleting: boolean;
		  }
	);

export const FileUploadItem = <TAllowedFileType extends FileType, TFileType extends string>({
	id,
	name,
	extension,
	typesConfirmedByAdmin = false,
	types,
	typeLabelMap,
	allowDelete,
	expandedByDefault = false,
	enableTypeSelection,
	allowTypeConfirmation,
	baseUrl,
	queryKey,
	createdAtMs,
	enablePDFViewer = true,
	onTypeConfirmation,
	onTypeConfirmationCancel,
	onDelete,
	onDownload,
	onSave,
	onBlur,
	deleting,
}: FileUploadItemProps<TAllowedFileType, TFileType>) => {
	const [open, setOpen] = useState(false);
	const typeLabelMapKeys = Object.keys(typeLabelMap);
	const [openPDFViewer, setOpenPDFViewer] = useState(false);
	const defaultWithCustomTypeMap = {
		...defaultAttachmentTypeLabelMap,
		...typeLabelMap,
	};
	const showSuggestedTypes = useMemo(
		() => allowTypeConfirmation && types.length > 0,
		[allowTypeConfirmation, types],
	);

	const handleSave = (selectedTypes: TAllowedFileType[] | TFileType[]) => {
		!!onSave &&
			onSave(
				{
					id: id as FileId,
					name,
					extension,
					typesConfirmedByAdmin,
					types: types as TAllowedFileType[],
				},
				selectedTypes as TAllowedFileType[],
			);
	};

	const cancelTypeConfirmation = () => {
		!!onTypeConfirmationCancel && onTypeConfirmationCancel();
	};

	return (
		<div className="relative flex flex-col p-[15px] bg-white border border-gray-200 rounded-[5px] shadow-sm text-[15px] text-gray-900 items">
			<div className="min-h-[50px] break-words flex space-x-[25px]">
				<CustomFileIcon extension={extension} />
				<div className="flex-1 flex flex-col space-y-[2px]">
					<span className="w-full flex justify-between">
						<div className="flex gap-6 mr-20 font-[435]">
							{name}.{extension}
						</div>
						<div className="w-fit min-w-fit h-fit flex items-center gap-[2px] absolute right-[15px] top-[10px]">
							{allowDelete && (
								<ConfirmationModalClickWrapper
									message={
										<div className="w-[375px] text-center break-words">
											Are you sure you want to delete
											<br />
											{name}.{extension}?
										</div>
									}
									className="items-center"
									confirmButtonText="Delete"
									confirmationButtonClassName="border-[1px] border-red-500 bg-white text-red-500 hover:bg-red-500 hover:bg-opacity-10"
									onConfirm={() => onDelete(id)}
								>
									<div className="cursor-pointer p-[4px] text-red-400 hover:text-red-500">
										<HiOutlineTrash className="text-[17px]" />
									</div>
								</ConfirmationModalClickWrapper>
							)}
							{extension === "pdf" && !!onDownload && enablePDFViewer && (
								<div
									color="none"
									onClick={() => setOpenPDFViewer(true)}
									className="cursor-pointer p-[4px] text-gray-600/75 hover:text-gray-800"
								>
									<HiOutlineEye className="text-[18px] stroke-[1.6px]" />
								</div>
							)}
							{!!onDownload && (
								<div
									onClick={() => !!onDownload && onDownload(id)}
									className="cursor-pointer p-[4px] text-gray-600/75 hover:text-gray-800"
								>
									<HiOutlineArrowDownTray className="text-[18px]" />
								</div>
							)}
						</div>
					</span>
					{createdAtMs && (
						<span className="text-gray-500 text-[12px]">
							{DateTime.fromMillis(createdAtMs).toFormat("LLLL dd, yyyy")}
						</span>
					)}
					{!showSuggestedTypes && (
						<div className="pt-4 flex flex-row gap-2 flex-wrap pr-[12px] pb-[6px]">
							{types.map((type, idx) => (
								<Tag key={`${name}-${type}-${idx}`}>{defaultWithCustomTypeMap[type]}</Tag>
							))}
							{enableTypeSelection && !typesConfirmedByAdmin && (
								<AddTagButton
									small={types.length > 0}
									onClick={() => {
										setOpen(true);
									}}
								/>
							)}
							{typesConfirmedByAdmin && !!allowTypeConfirmation && (
								<AddTagButton
									small={types.length > 0}
									onClick={() => {
										setOpen(true);
									}}
								/>
							)}
						</div>
					)}
					{showSuggestedTypes && typesConfirmedByAdmin && (
						<div className="pt-4 flex flex-row gap-2 flex-wrap pr-[12px] pb-[6px]">
							{types.length > 0 &&
								types.map((type, idx) => (
									<Tag key={`${name}-${type}-${idx}`}>{defaultWithCustomTypeMap[type]}</Tag>
								))}
							<AddTagButton
								small={types.length > 0}
								onClick={() => {
									setOpen(true);
								}}
							/>
						</div>
					)}
				</div>
			</div>
			{showSuggestedTypes && !typesConfirmedByAdmin && (
				<div className="w-full bg-gray-100 pl-3 pt-2 pb-3 pr-2 rounded-md border-dashed border-2 border-gray-400 flex flex-col gap-[5px] mt-[4px]">
					<div className="flex justify-between w-full text-[11.5px] font-medium items-center">
						Agent Suggested Types
						<div className="flex gap-[10px]">
							<button
								onClick={cancelTypeConfirmation}
								className="h-6 w-6 flex items-center justify-center rounded-full bg-gray-300 text-center hover:bg-gray-400"
							>
								<HiOutlineXMark className="text-gray-700 stroke-[3px]" />
							</button>
							<button
								onClick={onTypeConfirmation}
								className="h-6 w-6 flex items-center justify-center rounded-full bg-inrev-light-blue hover:bg-[#2c5aa0]"
							>
								<HiOutlineCheck className="text-white stroke-[3px]" />
							</button>
						</div>
					</div>
					<div className="flex flex-row gap-2 flex-wrap">
						{types.map((type, idx) => (
							<Tag
								colorOverride={{ bg: "bg-gray-50", border: "border-gray-300" }}
								key={`${name}-${type}-${idx}`}
							>
								{defaultWithCustomTypeMap[type]}
							</Tag>
						))}
						<AddTagButton
							colorOverride={{ bg: "bg-gray-50", border: "border-gray-300" }}
							small={types.length > 0}
							onClick={() => {
								setOpen(true);
							}}
						/>
					</div>
				</div>
			)}
			{deleting && (
				<div className="absolute top-0 right-0 bottom-0 left-0 rounded-[5px] bg-striped bg-white bg-opacity-80 !m-0"></div>
			)}
			{open && (
				<AttachmentTypeMultiSelect
					availableTypes={typeLabelMapKeys as TAllowedFileType[]}
					initialTypes={types as TAllowedFileType[]}
					onSave={handleSave}
					expandedByDefault={expandedByDefault}
					title={"Select File Type"}
					close={() => setOpen(false)}
					onBlur={onBlur}
				/>
			)}
			{openPDFViewer && baseUrl && queryKey && (
				<PDFViewerModal
					onClose={() => setOpenPDFViewer(false)}
					control={{
						fetchUrl: `${baseUrl}/${id}`,
						queryKey: [queryKey, id, true],
					}}
					header={name}
				/>
			)}
		</div>
	);
};

export const PendingFileUploadItem = <TAllowedFileType extends FileType, TFileType extends string>({
	name,
	extension,
	types,
	typeLabelMap,
}: Omit<
	FileUploadItemProps<TAllowedFileType, TFileType>,
	"fileId" | "onDelete" | "deleting" | "onDownload" | "typesConfirmedByAdmin"
>) => (
	<div className="opacity-60 min-h-[50px] break-words flex items-center space-x-[25px] p-[15px] bg-white border border-gray-200 rounded-[5px] shadow-sm text-[15px] text-gray-900">
		<CustomFileIcon extension={extension} />
		<div className="flex-1 flex flex-col space-y-[2px]">
			<span>
				{name}.{extension}
			</span>
			<span className="text-[13px] text-gray-500">
				{types.map((type) => typeLabelMap[type]).join(", ")}
			</span>
		</div>
		<HiXMark className="text-[19px] opacity-0" />
	</div>
);
