import { z } from 'zod';
import { zswitch } from '../../../../../types/utils.mjs';
import { Get as Get$3 } from './submitted-bid-contract-surety-quote.dto.mjs';
import * as submittedBidContractSuretyQuote_dto from './submitted-bid-contract-surety-quote.dto.mjs';
export { submittedBidContractSuretyQuote_dto as Bid };
import { Get as Get$2 } from './submitted-bid-to-final-contract-surety-quote.dto.mjs';
import * as submittedBidToFinalContractSuretyQuote_dto from './submitted-bid-to-final-contract-surety-quote.dto.mjs';
export { submittedBidToFinalContractSuretyQuote_dto as BidToFinal };
import { Get as Get$1 } from './submitted-final-contract-surety-quote.dto.mjs';
import * as submittedFinalContractSuretyQuote_dto from './submitted-final-contract-surety-quote.dto.mjs';
export { submittedFinalContractSuretyQuote_dto as Final };
import * as submittedContractSuretyQuoteMixins_dto from './submitted-contract-surety-quote-mixins.dto.mjs';
export { submittedContractSuretyQuoteMixins_dto as SubmittedContractSuretyQuoteDtoMixins };

var Get;
(function (Get) {
    (function (Response) {
        Response.schema = zswitch((input) => {
            switch (input.contractSuretyType) {
                case "bid":
                    return Get$3.Response.schema;
                case "bid_to_final":
                    return Get$2.Response.schema;
                case "final":
                    return Get$1.Response.schema;
                default:
                    return z.never();
            }
        });
        (function (Admin) {
            Admin.schema = zswitch((input) => {
                switch (input.contractSuretyType) {
                    case "bid":
                        return Get$3.Response.Admin.schema;
                    case "bid_to_final":
                        return Get$2.Response.Admin.schema;
                    case "final":
                        return Get$1.Response.Admin.schema;
                    default:
                        return z.never();
                }
            });
        })(Response.Admin || (Response.Admin = {}));
    })(Get.Response || (Get.Response = {}));
})(Get || (Get = {}));

export { Get };
