import { ActionItemId, Dtos, Relations, UserId } from "@inrev/common";
import { DateTime } from "luxon";
import { useContext, useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { match } from "ts-pattern";
import { GlobalErrorMessageModalContext } from "../../../providers/GlobalErrorHandlingProvider";
import { useRequest } from "../../../utils/request";
import { ActionItem } from "./type";

export const adminActionItemQueryKeys = {
	all: () => ["admin", "actionItems"], // Used for the dashboard
	actionItemsByRelation: (relation: Relations.ActionItems) => [
		"admin",
		"actionItems",
		relation.relationType,
		relation.relationId,
	], // Used for individual page views
};

export type ActionItemList = Record<ActionItemId, ActionItem>;

export const useFetchAdminActionItems = () => {
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);
	const { get } = useRequest();

	const actionItemsRequest = useQuery({
		queryKey: adminActionItemQueryKeys.all(),
		queryFn: async () => {
			const response = await get<Dtos.ActionItem.Get.Response[]>("/v2/action-items");
			return transformActionItems(response);
		},
		onError: (error: Error) => {
			console.error(error);
			triggerErrorModal(error);
		},
		refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		cacheTime: 5,
		staleTime: 5,
	});

	return {
		actionItems: actionItemsRequest.data,
		actionItemsIsLoading: actionItemsRequest.isLoading,
	};
};

// the idea is to use the id as the key for the object so we don't need to search through the array
const transformActionItems = (actionItems: Dtos.ActionItem.Get.Response[]): ActionItemList =>
	Object.fromEntries(
		actionItems.map((actionItem) => {
			return [actionItem.id, transformActionItem(actionItem)];
		}),
	);

// used mainly to transform incoming dates to DateTime instead of doing it in the component
const transformActionItem = (actionItem: Dtos.ActionItem.Get.Response): ActionItem => {
	return Dtos.ActionItem.Get.Response.schema.parse(actionItem);
};

export const useFetchAdminActionItemsForRelation = (relation: Relations.ActionItems) => {
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);
	const { get } = useRequest();

	const actionItemsRequest = useQuery({
		queryKey: adminActionItemQueryKeys.actionItemsByRelation(relation),
		queryFn: async () => {
			const data = await get<Dtos.ActionItem.Get.Response[]>(
				`/v2/action-items?relationType=${relation.relationType}&relationId=${relation.relationId}`,
			);
			return transformActionItems(data);
		},
		onError: (error: Error) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return useMemo(() => {
		const kvActionItems = (() => {
			const actionItems = actionItemsRequest.data;
			if (actionItems !== undefined && actionItems !== null) {
				return actionItems;
			}
			return {};
		})();
		return {
			actionItems: kvActionItems,
			actionItemsIsLoading: actionItemsRequest.isLoading,
		};
	}, [actionItemsRequest, actionItemsRequest.data]);
};

export const useCreateActionItem = () => {
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);
	const queryClient = useQueryClient();
	const { post } = useRequest();

	const mutation = useMutation({
		mutationFn: async (actionItem: Dtos.ActionItem.Create.Request) =>
			await post<Dtos.ActionItem.Create.Response>("/v2/action-items", actionItem),
		onMutate: async (actionItem) => {
			const relation = {
				relationType: actionItem.relationType,
				relationId: actionItem.relationId,
			} as Relations.ActionItems;

			await queryClient.cancelQueries(adminActionItemQueryKeys.actionItemsByRelation(relation));
			const previousActionItems = queryClient.getQueryData<ActionItemList>(
				adminActionItemQueryKeys.actionItemsByRelation(relation),
			);
			const tempId = crypto.randomUUID() as ActionItemId;
			queryClient.setQueryData<ActionItemList>(
				adminActionItemQueryKeys.actionItemsByRelation(relation),
				(old) => {
					if (old === undefined) return {};
					return {
						...old,
						[tempId]: {
							...{
								...actionItem,
								dueAt: actionItem.dueAt,
								completedAt: actionItem.completedAt,
							},
							...relation,
							id: tempId,
							createdAt: DateTime.now(),
							updatedAt: DateTime.now(),
						},
					};
				},
			);
			return { previousActionItems, tempId, relation };
		},
		onSuccess: (newActionItem, inputData, context) => {
			const relation = {
				relationType: inputData.relationType,
				relationId: inputData.relationId,
			} as Relations.ActionItems;
			if (!context) {
				throw new Error("Missing context of previous state");
			}

			queryClient.invalidateQueries({ queryKey: adminActionItemQueryKeys.all(), exact: true });

			queryClient.setQueryData(
				adminActionItemQueryKeys.actionItemsByRelation(relation),
				(old: ActionItemList | undefined) => {
					if (old === undefined) return transformActionItems([newActionItem]);
					delete old[context.tempId];
					return {
						...old,
						[newActionItem.id]: transformActionItem(newActionItem),
					};
				},
			);
		},
		onError: (error: Error) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return {
		createActionItem: mutation.mutate,
		createActionItemLoading: mutation.isLoading,
	};
};

export const useUpdateActionItem = () => {
	const queryClient = useQueryClient();
	const { patch } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const mutation = useMutation({
		mutationFn: async ({
			actionItemId,
			updatedFields,
		}: {
			actionItemId: ActionItemId;
			updatedFields: Dtos.ActionItem.Update.Request;
			relation: Relations.ActionItems;
		}) => {
			return await patch<Dtos.ActionItem.Update.Response>(
				`/v2/action-items/${actionItemId}`,
				updatedFields,
			);
		},
		onMutate: async ({
			actionItemId,
			updatedFields,
			relation,
		}: {
			actionItemId: ActionItemId;
			updatedFields: Dtos.ActionItem.Update.Request;
			relation: Relations.ActionItems;
		}) => {
			await queryClient.cancelQueries(adminActionItemQueryKeys.actionItemsByRelation(relation));
			const previousActionItems = queryClient.getQueryData<ActionItemList>(
				adminActionItemQueryKeys.actionItemsByRelation(relation),
			);

			queryClient.setQueryData(
				adminActionItemQueryKeys.actionItemsByRelation(relation),
				(old: ActionItemList | undefined) => {
					if (old === undefined) return {};
					const existing = old[actionItemId];
					if (existing === undefined) return old;
					const updated = {
						...existing,
						...updatedFields,
					};

					return {
						...old,
						[actionItemId]: transformActionItem(updated),
					};
				},
			);

			return { previousActionItems, updatedFields, relation };
		},
		onSuccess: (result, { relation }) => {
			if (result) {
				queryClient.invalidateQueries({ queryKey: adminActionItemQueryKeys.all(), exact: true });
				queryClient.setQueryData(
					adminActionItemQueryKeys.actionItemsByRelation(relation),
					(old: ActionItemList | undefined) => {
						if (old === undefined) return transformActionItems([result]);
						return {
							...old,
							[result.id]: transformActionItem(result),
						};
					},
				);
			}
		},
		onError: (error: Error) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	// helper method to mark an action item as completed
	const toggleCompleted = (actionItem: ActionItem, completedByUserId: UserId) => {
		const relation = {
			relationType: actionItem.relationType,
			relationId: actionItem.relationId,
		} as Relations.ActionItems;

		match(actionItem)
			.with({ status: "open" }, (actionItem) => {
				mutation.mutate({
					actionItemId: actionItem.id,
					updatedFields: {
						completedAt: DateTime.now(),
						status: "complete",
						completedByUserId,
					},
					relation,
				});
			})
			.with({ status: "complete" }, (actionItem) => {
				mutation.mutate({
					actionItemId: actionItem.id,
					updatedFields: {
						completedAt: null,
						status: "open",
						completedByUserId: null,
					},
					relation,
				});
			})
			.exhaustive();
	};

	return {
		updateActionItem: mutation.mutate,
		toggleCompleted: toggleCompleted,
		updateActionItemLoading: mutation.isLoading,
	};
};

export const useDeleteActionItem = () => {
	const queryClient = useQueryClient();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);
	const { _delete } = useRequest();

	const mutation = useMutation({
		mutationFn: async ({
			actionItemId,
		}: { actionItemId: ActionItemId; relation: Relations.ActionItems }) =>
			await _delete(`/v2/action-items/${actionItemId}`, undefined, "none"),
		onMutate: async ({
			actionItemId,
			relation,
		}: { actionItemId: ActionItemId; relation: Relations.ActionItems }) => {
			await queryClient.cancelQueries(adminActionItemQueryKeys.actionItemsByRelation(relation));

			queryClient.setQueryData<ActionItemList>(
				adminActionItemQueryKeys.actionItemsByRelation(relation),
				(old: ActionItemList | undefined) => {
					if (old === undefined) return {};
					// Interesting note, react useMemo only shallow compares the object, so we can't just
					// delete the key from the object and return it, we need to create a new object
					const { [actionItemId]: _, ...rest } = old;
					return rest;
				},
			);

			return { actionItemId, relation };
		},
		onError: (error: Error) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return {
		deleteActionItem: mutation.mutate,
		deleteActionItemLoading: mutation.isLoading,
	};
};
