import { z } from 'zod';
import { companyContactId, individualContactId, email } from '../../opaque.mjs';

const physicalCompanySignerSchema = z.object({
    contactId: companyContactId,
});
const physicalIndividualSignerSchema = z.object({
    contactId: individualContactId,
});
const electronicCompanySignerSchema = physicalCompanySignerSchema.extend({
    signerEmail: email,
});
const electronicIndividualSignerSchema = physicalIndividualSignerSchema.extend({
    signerEmail: email,
});

export { electronicCompanySignerSchema, electronicIndividualSignerSchema, physicalCompanySignerSchema, physicalIndividualSignerSchema };
