import { z } from 'zod';
import { Data as Data$1 } from './issued-bid-contract-surety-bond-form.types.mjs';
import * as issuedBidContractSuretyBondForm_types from './issued-bid-contract-surety-bond-form.types.mjs';
export { issuedBidContractSuretyBondForm_types as Bid };
import { Data as Data$2 } from './issued-final-contract-surety-bond-form.types.mjs';
import * as issuedFinalContractSuretyBondForm_types from './issued-final-contract-surety-bond-form.types.mjs';
export { issuedFinalContractSuretyBondForm_types as Final };

var Data;
(function (Data) {
    Data.schema = z.discriminatedUnion("contractSuretyType", [
        Data$1.schema,
        Data$2.schema,
    ]);
})(Data || (Data = {}));

export { Data };
