import type { Relations, UserId } from "@inrev/common";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import {
	ToolsExpandedLayout,
	ToolsLayout,
	ToolsSidebarLayout,
} from "../../../../components/layout/ToolsLayout";
import { AdminActionItemTool } from "./actionItems/AdminActionItemsTool";
import { AdminActionItemIcon } from "./actionItems/components/AdminActionItemIcon";
import { AdminNotesTool } from "./notes/AdminNotesTool";
import { AdminNoteIcon } from "./notes/components/AdminNoteIcon";

type AdminTool = keyof ReturnType<typeof buildAdminToolMap>;

const buildAdminToolMap = (relation: Relations.Notes, defaultUnderwriterId: UserId) => ({
	notes: {
		icon: AdminNoteIcon,
		content: <AdminNotesTool relation={relation} />,
	},
	...(import.meta.env.VITE_TOOLS_ADMIN_ACTION_ITEMS_ENABLED === "true" // Used to feature flag the action items tool
		? {
				actionItems: {
					icon: AdminActionItemIcon,
					content: (
						<AdminActionItemTool relation={relation} defaultUnderwriterId={defaultUnderwriterId} />
					),
				},
			}
		: {}),
});

export const AdminTools = ({
	relation,
	defaultUnderwriterId,
}: { relation: Relations.Notes; defaultUnderwriterId: UserId }) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const paramTool = useMemo(() => {
		return (searchParams.get("adminTool") as AdminTool) || undefined;
	}, [searchParams]);

	const toolMap = useMemo(
		() => buildAdminToolMap(relation, defaultUnderwriterId),
		[relation, defaultUnderwriterId],
	);

	const currentTool = useMemo(() => {
		return toolMap[paramTool] ? paramTool : undefined;
	}, [paramTool, toolMap]);

	const setCurrentTool = (tool?: AdminTool) => {
		if (tool === undefined) {
			setSearchParams({});
		} else {
			setSearchParams((prev) => {
				prev.set("adminTool", tool);
				return prev;
			});
		}
	};

	const collapseToolbar = () => {
		setSearchParams({});
	};

	return (
		<ToolsLayout
			sidebar={
				<ToolsSidebarLayout
					currentTool={currentTool}
					toolMap={toolMap}
					setCurrentTool={setCurrentTool}
					relation={relation}
				/>
			}
			expandedSidebar={
				<ToolsExpandedLayout
					currentTool={currentTool}
					close={collapseToolbar}
					setCurrentTool={setCurrentTool}
					toolMap={toolMap}
					relation={relation}
				/>
			}
		/>
	);
};
