import { z } from 'zod';
import { suretyBondStatusSchema, suretyTypeSchema, suretyBondTypeSchema } from '../../../../enums.mjs';
import { suretyBondId, carrierName, suretyAccountId, companyContactId, rateSchema, isoDateSchema, userId } from '../../../../types/opaque.mjs';
import { currencySchema } from '../../../../types/utils.mjs';
import '../../../../types/agency/carrier-agency-relationship.types.mjs';
import '../../../../types/credit-report/company-credit-report.mjs';
import '../../../../types/credit-report/individual-credit-report.mjs';
import '../../../../types/payment/payment-template.mjs';
import '../../../../types/surety/account/surety-account.types.mjs';
import '../../../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../../../types/surety/bond/surety-bond.types.mjs';
import '../../../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../../../types/surety/work-in-progress-statement/surety-work-in-progress-statement.mjs';
import '../../../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../../../types/surety/indemnity/indemnity-agreement.mjs';
import '../../../../types/user/user.types.mjs';
import '../../../../types/entityRelation/entity-relation.mjs';

var Get;
(function (Get) {
    (function (Response) {
        Response.schema = z.object({
            id: suretyBondId,
            status: suretyBondStatusSchema,
            suretyType: suretyTypeSchema,
            type: suretyBondTypeSchema,
            number: z.string(),
            carrierName: carrierName,
            carrierDisplayName: z.string(),
            accountId: suretyAccountId,
            accountName: z.string(),
            principalCompanyContactId: companyContactId,
            principalName: z.string(),
            amount: currencySchema,
            rate: rateSchema,
            premium: currencySchema,
            agencyCommission: currencySchema,
            effectiveDate: isoDateSchema,
            expectedCompletionDate: isoDateSchema.optional(),
            description: z.string(),
            obligeeName: z.string().optional(),
            underwriterUserId: userId,
        });
    })(Get.Response || (Get.Response = {}));
})(Get || (Get = {}));

export { Get };
