import { z } from 'zod';
import { suretyAccountStatusSchema, SuretyAccountStatus } from '../../../../enums.mjs';
import { addressSchema, userId } from '../../../../types/opaque.mjs';
import { attachmentSchema } from '../../../../types/utils.mjs';
import '../../../../types/agency/carrier-agency-relationship.types.mjs';
import '../../../../types/credit-report/company-credit-report.mjs';
import '../../../../types/credit-report/individual-credit-report.mjs';
import '../../../../types/payment/payment-template.mjs';
import '../../../../types/surety/account/surety-account.types.mjs';
import '../../../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../../../types/surety/bond/surety-bond.types.mjs';
import '../../../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../../../types/surety/work-in-progress-statement/surety-work-in-progress-statement.mjs';
import '../../../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../../../types/surety/indemnity/indemnity-agreement.mjs';
import '../../../../types/user/user.types.mjs';
import '../../../../types/entityRelation/entity-relation.mjs';
import { Get as Get$3 } from '../../bond/preview/surety-bond-preview.dto.mjs';
import { Get as Get$2 } from '../../quote/preview/submitted/submitted-surety-quote-preview.dto.mjs';
import { Get as Get$1 } from '../surety-account.dto.mixins.mjs';
import { Get as Get$4 } from './contract/submitted-contract-surety-account.dto.mjs';
import * as submittedContractSuretyAccount_dto from './contract/submitted-contract-surety-account.dto.mjs';
export { submittedContractSuretyAccount_dto as Contract };

var Get;
(function (Get) {
    (function (Response) {
        Response.schema = Get$1.Response.baseSchema.extend({
            status: suretyAccountStatusSchema.exclude([SuretyAccountStatus.draft]),
            displayName: z.string(),
            primaryCompanyId: z.string().uuid(),
            companies: z.array(z.object({
                id: z.string().uuid(),
                name: z.string(),
                address: addressSchema,
            })),
            individuals: z.array(z.object({
                id: z.string().uuid(),
                name: z.string(),
            })),
            fundNames: z.array(z.string()),
            quotes: z.array(Get$2.Response.schema),
            bonds: z.array(Get$3.Response.schema),
            attachments: z.array(attachmentSchema()),
            contract: Get$4.Response.schema.optional(),
            underwriterUserId: userId,
        });
    })(Get.Response || (Get.Response = {}));
})(Get || (Get = {}));

export { Get };
