import { z } from 'zod';
import { finalContractSuretyBondFormTypeSchema, BidSuretyBondClosedReason, bidSuretyBondClosedReasonSchema } from '../../../../enums.mjs';
import { isoDateSchema, rateSchema } from '../../../opaque.mjs';
import { currencySchema, zswitch } from '../../../utils.mjs';
import { Data as Data$1 } from './contract-surety-bond.types.mixins.mjs';

var Data;
(function (Data) {
    Data.schema = Data$1.baseSchema.extend({
        contractSuretyType: z.literal("bid"),
        bidAmount: currencySchema,
        bidDate: isoDateSchema,
        finalBondFormType: finalContractSuretyBondFormTypeSchema,
        finalBondContingentRate: rateSchema,
        finalBondContingentRateTemplateVersion: z.string(),
        finalBondContingentPremium: currencySchema,
        closedReason: z.nativeEnum(BidSuretyBondClosedReason).nullish(),
        tListingRequired: z.boolean(),
        isFinalBondCompletionBond: z.boolean(),
    });
})(Data || (Data = {}));
var Close;
(function (Close) {
    (function (Payload) {
        Payload.baseSchema = z.object({
            suretyType: z.literal("contract"),
            contractSuretyType: z.literal("bid"),
            closedReason: bidSuretyBondClosedReasonSchema.exclude(["bid_won"]),
            closedNote: z.string().optional(),
        });
        Payload.bidCompletedReasonsSchema = z.object({
            closedReason: bidSuretyBondClosedReasonSchema.extract(["bid_lost", "not_awarded"]),
        });
        Payload.allOtherReasonsSchema = z.object({
            closedReason: bidSuretyBondClosedReasonSchema.extract(["returned_by_obligee", "other"]),
            closedNote: z.string(),
        });
        Payload.schema = Payload.baseSchema.and(zswitch((input) => {
            switch (input.closedReason) {
                case "bid_lost":
                case "not_awarded":
                    return Payload.bidCompletedReasonsSchema;
                case "returned_by_obligee":
                case "other":
                    return Payload.allOtherReasonsSchema;
            }
        }));
    })(Close.Payload || (Close.Payload = {}));
})(Close || (Close = {}));

export { Close, Data };
