import {
	AttachmentFile,
	ContractDamageDescriptions,
	ContractHazardDescriptions,
	contractSuretyTypeToLabelMap,
	defaultAttachmentTypeLabelMap,
	formatToDollar,
} from "@inrev/common";
import { useEffect, useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { useNavigate, useParams } from "react-router-dom";
import { useGetFileUrl } from "../../../api";
import { AttachmentSection } from "../../../components/layout/AttachmentSection";
import { CommentsSection } from "../../../components/layout/CommentsSection";
import { LoadingModal } from "../../../components/layout/LoadingModal";
import { Button } from "../../../components/ui/Button";
import { Icon } from "../../../components/ui/Icon";
import { useAdminFetchBond } from "../../../domain/admin/bond/api";
import { AdminSuretyBond } from "../../../domain/admin/bond/types";
import { useComments } from "../../../domain/shared/comments/api";
import { useFollowers } from "../../../domain/shared/followers/api";
import { AdminWorkflowLayout } from "../layout/AdminWorkflowLayout";
import { AdminBondFormCard } from "../shared/AdminBondFormCard";
import { AdminDataListProps } from "../shared/AdminDataList";
import { AdminFollowerControls } from "../shared/AdminFollowerControls";
import { AdminProjectCard } from "../shared/AdminProjectCard";
import { NAICSCodesList } from "../shared/NAICSCodeList";
import { AdminTools } from "../shared/tools/AdminTools";
import { BondCard } from "./components/AdminBondCard";
import { AdminBondClosingDetailsCard } from "./components/AdminBondClosingDetailsCard";
import { AdminCloseBondModal } from "./components/AdminCloseBondModal";

const AdminBondViewCore = ({ bond }: { bond: AdminSuretyBond }) => {
	const [attachments, setAttachments] = useState<AttachmentFile[]>(bond.attachments ?? []);
	const followersControl = useFollowers("bonds", bond.id, true);
	const { comments, commentsLoading, createComment, createCommentLoading } = useComments(
		"bonds",
		bond.id,
		true,
	);
	const { getFileUrl, fileUrlLoading } = useGetFileUrl();

	return (
		<AdminWorkflowLayout
			title={`${contractSuretyTypeToLabelMap[bond.contractSuretyType]} Bond ${bond.number}`}
			contentClassName="bg-gray-50 bg-opacity-[.45]"
			toolbar={
				<AdminTools
					relation={{ relationType: "bond", relationId: bond.id }}
					defaultUnderwriterId={bond.underwriterUserId}
				/>
			}
		>
			<div className="flex justify-center w-full h-full overflow-y-auto pt-[25px]">
				<div className="w-[785px] max-w-[785px] h-fit flex flex-col items-center space-y-[30px] pb-[125px]">
					<BondCard
						bond={bond}
						actionButtons={
							<div className="flex flex-col space-y-[5px] max-w-[165px]">
								{bond.status === "open" && (
									<AdminCloseBondModal
										bond={{
											id: bond.id,
											suretyType: bond.suretyType,
											contractSuretyType: bond.contractSuretyType,
											bondType: bond.contractSuretyType,
											amount: bond.amount,
											number: bond.number,
										}}
										button={
											<Button color="gray" thinFont className="w-full hover:">
												<div className="flex items-center space-x-[8px]">
													<Icon
														type="shield-slash"
														className="stroke-[1] text-gray-500 h-[14px]"
														width={13}
														height={17}
													/>
													<span>Close Bond</span>
												</div>
											</Button>
										}
									/>
								)}
								<div className="flex flex-col space-y-[5px] max-w-[165px]">
									<Button
										color="gray"
										thinFont
										onClick={() =>
											getFileUrl({
												baseUrl: `/v2/admin/surety/bonds/${bond.id}/bond-form`,
												queryKey: ["bondFormFileUrl", bond.id, true],
												asDownload: true,
											})
										}
										loading={fileUrlLoading}
									>
										Download Bond
									</Button>
								</div>
							</div>
						}
						followersControl={followersControl}
					/>
					{bond.status === "closed" && <AdminBondClosingDetailsCard bond={bond} />}
					<AdminProjectCard
						projectDescription={bond.project.input.description}
						data={(() => {
							const data: AdminDataListProps["data"] = [];

							if (bond.contractSuretyType === "bid") {
								data.push({ label: "Bid Amount", value: formatToDollar(bond.bidAmount) });
							}
							if (bond.contractSuretyType === "final") {
								data.push({
									label: "Contract Amount",
									value: formatToDollar(bond.contractAmount),
								});
							}
							data.push({
								label: "Location",
								value: `${bond.project.input.address.city}, ${bond.project.input.address.state}`,
							});
							data.push({
								label: "Months to Complete",
								value: bond.project.input.monthsToComplete.toString(),
							});
							data.push({
								label: "Contract Warranty Months",
								value: bond.project.input.contractWarrantyMonths.toString(),
							});
							data.push({
								label: "Scope of Work",
								value: (
									<NAICSCodesList
										naicsCodes={bond.project.input.scopeOfWork}
										showRatio
										className="min-w-[400px] max-w-[400px]"
									/>
								),
							});
							data.push({
								label: "Contract Hazards",
								value: bond.project.input.contractHazards.length ? (
									<div className="w-fit flex flex-col space-y-[2px] text-[13px] text-right">
										{bond.project.input.contractHazards.map((hazard, index) => (
											<span
												key={index}
											>{`${ContractHazardDescriptions[hazard][0].toLocaleUpperCase()}${ContractHazardDescriptions[hazard].slice(1)}`}</span>
										))}
									</div>
								) : null,
							});
							data.push({
								label: "Contract Damages",
								value: bond.project.input.contractDamages.length ? (
									<div className="w-fit flex flex-col space-y-[2px] text-[13px] text-right">
										{bond.project.input.contractDamages.map((damage, index) => (
											<span
												key={index}
											>{`${ContractDamageDescriptions[damage][0].toLocaleUpperCase()}${ContractDamageDescriptions[damage].slice(1)}`}</span>
										))}
									</div>
								) : null,
							});

							return data;
						})()}
					/>
					<AdminBondFormCard
						bondForm={bond.bondForm}
						bondFormViewUrl={`/v2/admin/surety/bonds/${bond.id}/bond-form`}
						bondFormViewQueryKey={["bondFormFileUrl", bond.id, false]}
					/>
					<CommentsSection
						comments={comments}
						commentsLoading={commentsLoading}
						createComment={createComment}
						createCommentLoading={createCommentLoading}
						commentDownloadHeader={`${bond.displayTitle} For ${bond.principalName}`}
						headerEnd={
							<AdminFollowerControls
								{...followersControl}
								manageFollowersSubtext="Followers will receive email notifications for all comments and changes to the status of this bond"
								agencyId={bond.agencyId}
							/>
						}
					/>
					<AttachmentSection
						attachments={attachments}
						allowTypeConfirmation={true}
						showTabs={true}
						allowDelete={true}
						downloadAllName={`${bond.accountName} ${bond.displayTitle} Attachments`}
						typeLabelMap={defaultAttachmentTypeLabelMap}
						upload={{
							url: `/v2/admin/surety/bonds/${bond.id}/attachments`,
							onChange: (val) => setAttachments(val),
							allowedTypesAndLabels: defaultAttachmentTypeLabelMap,
							invalidateQueryKeys: [["bonds", bond.id]],
						}}
						download={{
							baseUrl: `/v2/admin/surety/bonds/${bond.id}/attachments`,
							baseQueryKey: "adminBondAttachments",
						}}
					/>
				</div>
			</div>
		</AdminWorkflowLayout>
	);
};

const AdminBondViewContainer = () => {
	const { id } = useParams();
	const { bond, bondError, bondLoading } = useAdminFetchBond(id!);

	useEffect(() => {
		if (bondError) {
			navigate("/bonds");
		}
	}, [bondError]);

	const navigate = useNavigate();

	if (bondLoading || bond === undefined) return <LoadingModal />;
	return <AdminBondViewCore bond={bond} />;
};

export const AdminBondView = withErrorBoundary(AdminBondViewContainer, {
	fallback: <div>Something went wrong</div>,
});
