import { Navigate, Route, Routes } from "react-router-dom";
import { Tooltip as TT } from "react-tooltip";
import { AdminPrefetchProvider } from "./providers/AdminPrefetchProvider";
import { AuthGuard } from "./utils/guards";
import { AdminSuretyAccountView } from "./views/admin/account/AdminAccountView";
import { AdminAccountsView } from "./views/admin/accounts/AdminAccountsView";
import { AdminBondView } from "./views/admin/bond/AdminBondView";
import { BondsView } from "./views/admin/bonds/BondsView";
import { AdminDashboardView } from "./views/admin/dashboard/AdminDasboardView";
import { AdminImportBondView } from "./views/admin/import/AdminImportBondView";
import { AdminMainLayout } from "./views/admin/layout/AdminMainLayout";
import { AdminTableLayout } from "./views/admin/layout/AdminTableLayout";
import { AdminBondRequestView } from "./views/admin/request/AdminBondRequestView";
import { AdminRequestsView } from "./views/admin/requests/RequestsView";
import { AdminUserMenu } from "./views/admin/settings/AdminUserMenu";
import { AgencyAssignmentsView } from "./views/admin/settings/AgencyAssignmentsView";

export const AdminApp = () => {
	return (
		<AdminPrefetchProvider>
			<AdminMainLayout>
				<Routes>
					{import.meta.env.VITE_TOOLS_ADMIN_ACTION_ITEMS_ENABLED === "true" && (
						<Route path="/" element={<AuthGuard component={AdminDashboardView} />} />
					)}
					<Route path="requests" element={<AuthGuard component={AdminRequestsView} />} />
					<Route path="requests/:id" element={<AuthGuard component={AdminBondRequestView} />} />
					<Route
						path="bonds"
						element={
							<AuthGuard
								component={() => (
									<BondsView userMenu={AdminUserMenu} tableLayout={AdminTableLayout} />
								)}
							/>
						}
					/>
					<Route path="bonds/:id" element={<AuthGuard component={AdminBondView} />} />
					<Route path="accounts" element={<AuthGuard component={AdminAccountsView} />} />
					<Route path="accounts/:id" element={<AuthGuard component={AdminSuretyAccountView} />} />
					<Route path="import">
						<Route path="bonds" element={<AuthGuard component={AdminImportBondView} />} />
					</Route>
					<Route
						path="agency-assignments"
						element={<AuthGuard component={AgencyAssignmentsView} />}
					/>
					<Route path="*" element={<Navigate to="/requests" />} />
				</Routes>
			</AdminMainLayout>
			<TT anchorSelect="[id^='tt-']" />
		</AdminPrefetchProvider>
	);
};
