import { toISODate } from "@inrev/common";
import { DateTime } from "luxon";
import { forwardRef, useEffect, useMemo, useState } from "react";
import { DayPicker, useDayPicker, useNavigation } from "react-day-picker";
import { FieldError } from "react-hook-form";
import { HiChevronLeft, HiChevronRight, HiOutlineCalendar } from "react-icons/hi2";
import { useIsFirstRender } from "../../utils";
import { Modal } from "../layout/Modal";
import { FormError } from "../layout/form/FormError";
import { cn } from "../lib/utils";

const CustomCaption = () => {
	const { currentMonth, goToMonth, nextMonth, previousMonth } = useNavigation();
	const { fromDate, toDate } = useDayPicker();
	const fromDt = fromDate ? DateTime.fromJSDate(fromDate) : undefined;
	const currentDt = useMemo(() => DateTime.fromJSDate(currentMonth), [currentMonth]);
	const toDt = toDate ? DateTime.fromJSDate(toDate) : undefined;

	return (
		<div className="w-full min-h-fit flex items-center space-x-[10px] text-[15px] text-gray-800 font-normal px-[12px] select-none mb-[14px]">
			<div className="flex w-[155px] h-full items-center space-x-[3px] py-[4px] px-[12px] rounded border border-gray-200 bg-gray-50">
				<span className="flex-1 font-[450]">{currentDt.monthLong}</span>
				<div className="h-full flex items-center text-gray-500">
					<div
						onClick={() => previousMonth && goToMonth(previousMonth)}
						className="rounded-md px-[4px] py-[6px] hover:bg-gray-200/40 cursor-pointer hover:text-gray-800"
					>
						<HiChevronLeft className="text-[15px] stroke-[.75]" />
					</div>
					<div
						onClick={() => nextMonth && goToMonth(nextMonth)}
						className="rounded-md px-[5px] py-[6px] hover:bg-gray-200/40 cursor-pointer hover:text-gray-800"
					>
						<HiChevronRight className="text-[15px] stroke-[.75]" />
					</div>
				</div>
			</div>
			<div className="flex flex-1 h-full items-center space-x-[3px] py-[4px] px-[8px] rounded border border-gray-200 bg-gray-50">
				<span className="flex-1 font-[450]">{currentDt.year}</span>
				<div className="h-full flex items-center text-gray-500">
					<div
						onClick={() =>
							goToMonth(
								fromDt
									? DateTime.max(currentDt.minus({ years: 1 }), fromDt).toJSDate()
									: currentDt.minus({ years: 1 }).toJSDate(),
							)
						}
						className="rounded-md px-[4px] py-[6px] hover:bg-gray-200/40 cursor-pointer hover:text-gray-800"
					>
						<HiChevronLeft className="h-[15px] stroke-[.75]" />
					</div>
					<div
						onClick={() =>
							goToMonth(
								toDt
									? DateTime.min(currentDt.plus({ years: 1 }), toDt).toJSDate()
									: currentDt.plus({ years: 1 }).toJSDate(),
							)
						}
						className="rounded-md px-[5px] py-[6px] hover:bg-gray-200/40 cursor-pointer hover:text-gray-800"
					>
						<HiChevronRight className="h-[15px] stroke-[.75]" />
					</div>
				</div>
			</div>
		</div>
	);
};

type DatePickerProps = {
	value: string | "";
	defaultMonth?: DateTime;
	placeholder?: string;
	onChange?: (value: string | "") => void;
	onBlur?: () => void;
	className?: string;
	fromDate?: DateTime;
	toDate?: DateTime;
	error?: FieldError;
	errorMessage?: string;
	readonly?: boolean;
	disabled?: boolean;
	condensed?: true;
};

export const DatePickerModal = forwardRef<HTMLElement, DatePickerProps>(
	(
		{
			value,
			defaultMonth,
			placeholder,
			onChange,
			onBlur,
			className,
			fromDate,
			toDate,
			error,
			errorMessage,
			readonly,
			condensed,
			disabled,
		},
		_ref,
	) => {
		const isFirstRender = useIsFirstRender();
		const [open, setOpen] = useState<boolean>(false);
		const selectedValue = useMemo(() => {
			if (value === "") return undefined;
			const parsedDt = DateTime.fromISO(value).toUTC();
			return new Date(parsedDt.year, parsedDt.month - 1, parsedDt.day, 0, 0, 0, 0);
		}, [value]);
		const formattedValue = useMemo(
			() => (value !== "" ? DateTime.fromISO(value, { zone: "utc" }).toFormat("MM/dd/yyyy") : ""),
			[value],
		);

		useEffect(() => {
			if (!open && !isFirstRender && onBlur) {
				onBlur();
			}
		}, [open]);

		return (
			<>
				<button
					type="button"
					className={cn(
						"group flex h-[50px] w-[192px] rounded-md bg-gray-50 disabled:bg-gray-100 disabled:bg-opacity-100 bg-opacity-70 outline outline-[1px] outline-offset-[-1px] outline-gray-300 leading-[20px] cursor-pointer disabled:cursor-default outline-none focus-visible:ring focus-visible:ring-offset-[3px] focus-visible:ring-inrev-light-blue focus-visible:ring-opacity-30",
						disabled ? "cursor-not-allowed opacity-50" : "hover:outline-gray-500",
						className,
						condensed ? "!h-[46px] text-[16px]" : undefined,
						readonly ? "opacity-80" : undefined,
						error ? "outline-red-500 relative" : undefined,
					)}
					onClick={() => !readonly && setOpen(true)}
					disabled={readonly}
				>
					<div className="h-full w-[54px] flex items-center justify-center rounded-l-md bg-gray-100 border-r border-r-gray-300">
						<HiOutlineCalendar className="text-[20px] text-gray-700 group-disabled:text-gray-500" />
					</div>
					<div
						className={cn(
							"flex-1 flex h-full items-center justify-center text-[16.5px]",
							condensed ? "text-[16px]" : undefined,
						)}
					>
						{value !== "" && (
							<span className="text-gray-900 group-disabled:text-gray-800">{formattedValue}</span>
						)}
						{value === "" && (
							<span className="text-gray-500/85">{placeholder ?? "Select a date"}</span>
						)}
					</div>
					<FormError error={error} errorMessage={errorMessage} className="left-[46px]" />
				</button>
				{open && (
					<Modal onClickOutside={() => setOpen(false)} itemClassName="mb-[5%]">
						<DatePicker
							selectedValue={selectedValue}
							defaultMonth={defaultMonth}
							fromDate={fromDate}
							toDate={toDate}
							onChange={onChange}
							setOpen={setOpen}
						/>
					</Modal>
				)}
			</>
		);
	},
);

export const DatePicker = ({
	selectedValue,
	defaultMonth,
	fromDate,
	toDate,
	onChange,
	setOpen,
}: {
	selectedValue: Date | undefined;
	defaultMonth?: DateTime;
	fromDate?: DateTime;
	toDate?: DateTime;
	onChange?: (value: string | "") => void;
	setOpen: (value: boolean) => void;
}) => {
	return (
		<div className="w-fit h-fit rounded-md bg-white shadow-lg px-[14px] pb-[10px] pt-[16px]">
			<DayPicker
				selected={selectedValue}
				defaultMonth={selectedValue ?? defaultMonth?.toJSDate()}
				mode="single"
				captionLayout="dropdown-buttons"
				fromDate={fromDate?.toJSDate()}
				toDate={toDate?.toJSDate()}
				showOutsideDays
				fixedWeeks
				components={{
					Caption: () => <CustomCaption />,
				}}
				onSelect={(value) => {
					onChange?.(
						value !== undefined ? toISODate(DateTime.fromJSDate(value, { zone: "utc" })) ?? "" : "",
					);
					setOpen(false);
				}}
			/>
		</div>
	);
};
