import { z } from 'zod';
import { finalContractSuretyBondFormTypeSchema } from '../../../../../enums.mjs';
import { isoDateSchema } from '../../../../opaque.mjs';
import { currencySchema } from '../../../../utils.mjs';
import { Data as Data$1 } from './issued-contract-surety-bond-form.types.mixins.mjs';

var Data;
(function (Data) {
    let FillData;
    (function (FillData) {
        FillData.schema = Data$1.FillData.baseSchema.extend({
            contractAmount: currencySchema,
            contractDate: isoDateSchema,
            contractDescription: z.string(),
        });
    })(FillData = Data.FillData || (Data.FillData = {}));
    let Attributes;
    (function (Attributes) {
        Attributes.schema = Data$1.Attributes.baseSchema.extend({
            cancellable: z.boolean(),
            continuous: z.boolean(),
            renewable: z.boolean(),
        });
    })(Attributes = Data.Attributes || (Data.Attributes = {}));
    Data.schema = z.object({
        suretyType: z.literal("contract"),
        contractSuretyType: z.literal("final"),
        bondFormType: finalContractSuretyBondFormTypeSchema,
        fillData: FillData.schema,
        attributes: Attributes.schema,
    });
})(Data || (Data = {}));

export { Data };
