import { z } from 'zod';
import { individualContactId, firstName, lastName, partialAddressSchema, decryptedSSN, email, suretyAccountId, agencyId, companyContactId, addressSchema, fein, isoDateTimeSchema, userId } from '../../../../types/opaque.mjs';
import { attachmentSchema, zswitch, suretyAccountBondingLineSchema } from '../../../../types/utils.mjs';
import '../../../../types/agency/carrier-agency-relationship.types.mjs';
import '../../../../types/credit-report/company-credit-report.mjs';
import '../../../../types/credit-report/individual-credit-report.mjs';
import '../../../../types/payment/payment-template.mjs';
import { ContactRelationshipConfig, Exposure } from '../../../../types/surety/account/surety-account.types.mjs';
import '../../../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../../../types/surety/bond/surety-bond.types.mjs';
import '../../../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../../../types/surety/work-in-progress-statement/surety-work-in-progress-statement.mjs';
import '../../../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../../../types/surety/indemnity/indemnity-agreement.mjs';
import '../../../../types/user/user.types.mjs';
import '../../../../types/entityRelation/entity-relation.mjs';
import { nameSuffixSchema, suretyAccountStatusSchema, SuretyAccountStatus, SuretyType, suretyTypeSchema } from '../../../../enums.mjs';
import '../../admin-user.dto.mjs';
import '../../agency/agency.dto.mjs';
import '../../company-credit-report-preview.dto.mjs';
import '../../company-credit-report.dto.mjs';
import { Get as Get$3 } from '../../financial-statement/admin-financial-statement.dto.mjs';
import '../../individual-credit-report-preview.dto.mjs';
import '../../individual-credit-report.dto.mjs';
import '../../individual-ofac-check-preview.dto.mjs';
import '../../individual-ofac-check.dto.mjs';
import './contact/company-contact.dto.mjs';
import './contact/individual-contact.dto.mjs';
import '../agency/carrier-agency-relationship.dto.mjs';
import '../bond-form/surety-bond-form.dto.mjs';
import '../bond/admin-surety-bond.dto.mjs';
import '../carrier-bonding-line/admin-surety-account-bonding-line.dto.mjs';
import '../indemnity-agreement/surety-indemnity-agreement.dto.mjs';
import '../indemnity-agreement/surety-indemnity-waiver.dto.mjs';
import '../quote/surety-quote.dto.mjs';
import '../../underwriter-assignment.dto.mjs';
import { Get as Get$4 } from '../../wip-statement/admin-wip-statement.dto.mjs';
import '../../note/admin-note.dto.mjs';
import '../../note/admin-note-topic.dto.mjs';
import '../../../agent/index.mjs';
import '../../../comment/comment.dto.mjs';
import { Admin as Admin$1 } from '../../../credit-report/company-credit-report.dto.mjs';
import { OFAC, Admin } from '../../../credit-report/individual-credit-report.dto.mjs';
import '../../../follower/follower.dto.mjs';
import { Submit as Submit$1 } from '../../../surety/account/surety-account.dto.mjs';
import '../../../surety/bond-form/surety-bond-form-template.dto.mjs';
import '../../../surety/bond-form/surety-bond-form.dto.mjs';
import '../../../surety/bond-issuance/surety-bond-issuance.dto.mjs';
import '../../../surety/bond/surety-bond.dto.mjs';
import '../../../surety/indemnity/indemnity-agreement.dto.mjs';
import '../../../surety/quote/surety-quote.dto.mjs';
import '../../../user/user.dto.mjs';
import '../../../action-item/action-item.dto.mjs';
import { Get as Get$2 } from '../../../surety/bond/preview/surety-bond-preview.dto.mjs';
import { Get as Get$1 } from '../../../surety/quote/preview/submitted/submitted-surety-quote-preview.dto.mjs';
import { Get as Get$5, Update as Update$1, Submit as Submit$2 } from './contract/admin-contract-surety-account.dto.mjs';
import * as adminContractSuretyAccount_dto from './contract/admin-contract-surety-account.dto.mjs';
export { adminContractSuretyAccount_dto as Contract };
import * as adminSuretyAccountDraft_dto from './draft/admin-surety-account-draft.dto.mjs';
export { adminSuretyAccountDraft_dto as Draft };
import { Metadata } from '../../../../types/surety/account/contract/contract-surety-account.types.mjs';

var Get;
(function (Get) {
    (function (Response) {
        let Individual;
        (function (Individual) {
            Individual.schema = z.object({
                id: individualContactId,
                firstName: firstName,
                middleInitial: z.string().optional(),
                lastName: lastName,
                suffix: nameSuffixSchema.optional(),
                address: partialAddressSchema,
                ssn: decryptedSSN.optional(),
                email: email.optional(),
                phone: z.string().optional(),
                spouse: z
                    .object({
                    id: individualContactId,
                    firstName: firstName,
                    middleInitial: z.string().optional(),
                    lastName: lastName,
                    suffix: nameSuffixSchema.optional(),
                    address: partialAddressSchema,
                    email: email.optional(),
                })
                    .optional(),
                ownsAHome: z.boolean().optional(),
                ofacCheck: OFAC.Admin.Response.schema.optional(),
                creditReports: z.array(Admin.Response.schema),
                config: ContactRelationshipConfig.schema,
            });
        })(Individual = Response.Individual || (Response.Individual = {}));
        Response.schema = z.object({
            id: suretyAccountId,
            agency: z.object({
                id: agencyId,
                displayName: z.string(),
            }),
            status: suretyAccountStatusSchema.exclude([SuretyAccountStatus.draft]),
            displayName: z.string(),
            primaryCompanyId: companyContactId,
            companies: z.array(z.object({
                id: companyContactId,
                name: z.string(),
                address: addressSchema,
                fein: fein.optional(),
                creditReports: z.array(Admin$1.Response.schema),
                config: ContactRelationshipConfig.schema,
            })),
            individuals: z.array(Individual.schema),
            fundNames: z.array(z.string()),
            adjustedFSR: z.number().optional(),
            averageFICO: z.number().optional(),
            quotes: z.array(Get$1.Response.schema),
            bonds: z.array(Get$2.Response.schema),
            exposure: Exposure.schema,
            financialStatements: z.array(Get$3.Response.schema),
            wipStatements: z.array(Get$4.Response.schema),
            attachments: z.array(attachmentSchema()),
            contract: Get$5.Response.schema.optional(),
            createdAt: isoDateTimeSchema.nullable(),
            underwriterUserId: userId,
        });
    })(Get.Response || (Get.Response = {}));
})(Get || (Get = {}));
var Update;
(function (Update) {
    (function (Status) {
        (function (Request) {
            Request.schema = zswitch((input) => {
                switch (input.suretyType) {
                    case SuretyType.contract:
                        return Update$1.Status.Request.schema;
                    default:
                        return z.never();
                }
            });
        })(Status.Request || (Status.Request = {}));
    })(Update.Status || (Update.Status = {}));
    (function (BondingLine) {
        (function (Request) {
            Request.schema = z.object({
                suretyType: suretyTypeSchema,
                bondingLine: suretyAccountBondingLineSchema,
            });
        })(BondingLine.Request || (BondingLine.Request = {}));
    })(Update.BondingLine || (Update.BondingLine = {}));
    (function (Metadata$1) {
        (function (Request) {
            Request.schema = z.discriminatedUnion("suretyType", [
                Metadata.schema
                    .omit({ bondingLine: true })
                    .partial()
                    .extend({
                    suretyType: z.literal(SuretyType.contract),
                }),
            ]);
        })(Metadata$1.Request || (Metadata$1.Request = {}));
    })(Update.Metadata || (Update.Metadata = {}));
})(Update || (Update = {}));
var Submit;
(function (Submit) {
    (function (Request) {
        (function (Base) {
            Base.schema = Submit$1.Request.Base.schema.and(z
                .object({
                options: z.object({
                    notifyFollowers: z.boolean(),
                }),
            })
                .passthrough());
        })(Request.Base || (Request.Base = {}));
        (function (Existing) {
            Existing.buildDtoValidationSchema = (formSchema) => zswitch((input) => {
                const typedInput = z
                    .object({
                    suretyType: z.literal(SuretyType.contract),
                })
                    .safeParse(input);
                if (!typedInput.success)
                    return z.never();
                switch (typedInput.data.suretyType) {
                    case "contract":
                        return Submit$2.Request.Existing.buildDtoValidationSchema(formSchema);
                    default:
                        return z.never();
                }
            });
        })(Request.Existing || (Request.Existing = {}));
    })(Submit.Request || (Submit.Request = {}));
    (function (Response) {
        Response.schema = Get.Response.schema;
    })(Submit.Response || (Submit.Response = {}));
})(Submit || (Submit = {}));

export { Get, Submit, Update };
