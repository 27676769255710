import type { ReactNode } from "react";
import { cn } from "../../../../../../components/lib/utils";

export const AdminNotesLayout = ({
	header,
	content,
	contentClassName,
	footer,
	footerClassName,
}: {
	header?: ReactNode;
	content: ReactNode;
	contentClassName?: string;
	footer?: ReactNode;
	footerClassName?: string;
}) => {
	return (
		<div className="w-full flex flex-col justify-start bg-white z-50 h-full min-h-fit gap-[8px] pb-[16px]">
			{header ?? <></>}
			<div
				className={cn(
					"flex-1 flex flex-col gap-[28px] pt-[8px] px-6 overflow-y-auto",
					contentClassName,
				)}
			>
				{content}
			</div>
			<div className={footerClassName}>{footer ?? <></>}</div>
		</div>
	);
};
