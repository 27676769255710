import { z } from 'zod';
import { SuretyType, ContractSuretyType, dateTimeSchema } from '../../../../../enums.mjs';
import { principalSignerSchema } from '../../../../../types/surety/bond-form/issued/contract/issued-contract-surety-bond-form.types.mixins.mjs';
import { Obligee } from '../../../../../types/surety/bond/surety-bond.types.mjs';

var Approve;
(function (Approve) {
    (function (Request) {
        Request.schema = z.object({
            suretyType: z.literal(SuretyType.contract),
            contractSuretyType: z.literal(ContractSuretyType.bid),
            bondFormType: z.literal("bid"),
            cancellable: z
                .boolean()
                .default(false)
                .refine((val) => val === false),
            continuous: z
                .boolean()
                .default(true)
                .refine((val) => val === true),
            renewable: z
                .boolean()
                .default(false)
                .refine((val) => val === false),
        });
    })(Approve.Request || (Approve.Request = {}));
})(Approve || (Approve = {}));
var Upload;
(function (Upload) {
    (function (AgentIssue) {
        (function (Request) {
            Request.schema = z.object({
                suretyType: z.literal(SuretyType.contract),
                contractSuretyType: z.literal(ContractSuretyType.bid),
                bondForm: z.object({
                    file: z.object({
                        name: z.string(),
                        base64: z.string(),
                    }),
                    data: z.object({
                        bondNumber: z.string(),
                        effectiveDate: dateTimeSchema,
                        bidDate: dateTimeSchema,
                        projectDescription: z.string(),
                        obligees: Obligee.schema.array(),
                        principalSigner: principalSignerSchema.nullish(),
                    }),
                }),
            });
        })(AgentIssue.Request || (AgentIssue.Request = {}));
    })(Upload.AgentIssue || (Upload.AgentIssue = {}));
    (function (PhysicalIssue) {
        (function (Request) {
            Request.schema = z.object({
                suretyType: z.literal(SuretyType.contract),
                contractSuretyType: z.literal(ContractSuretyType.bid),
                bondFormType: z.literal("bid"),
                attributes: z.object({
                    cancellable: z
                        .boolean()
                        .default(false)
                        .refine((val) => val === false),
                    continuous: z
                        .boolean()
                        .default(true)
                        .refine((val) => val === true),
                    renewable: z
                        .boolean()
                        .default(false)
                        .refine((val) => val === false),
                }),
                properties: z.object({
                    effectiveDate: dateTimeSchema,
                }),
                file: z.object({
                    name: z.string(),
                    base64: z.string(),
                }),
            });
        })(PhysicalIssue.Request || (PhysicalIssue.Request = {}));
    })(Upload.PhysicalIssue || (Upload.PhysicalIssue = {}));
})(Upload || (Upload = {}));

export { Approve, Upload };
