import { ReactNode, useContext } from "react";
import { QueryClientProvider as QCProvider, QueryClient } from "react-query";
import { GlobalErrorMessageModalContext } from "./GlobalErrorHandlingProvider";

export const QueryClientProvider = ({
	queryClient,
	children,
}: { queryClient: QueryClient; children: ReactNode }) => {
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);
	queryClient.setDefaultOptions({
		queries: {
			staleTime: 60000 * 5,
			cacheTime: 60000 * 5,
			refetchOnWindowFocus: false,
			refetchIntervalInBackground: false,
			onError: (error) => triggerErrorModal(error as Error),
		},
		mutations: {
			onError: (error) => triggerErrorModal(error as Error),
		},
	});
	return <QCProvider client={queryClient}>{children}</QCProvider>;
};
