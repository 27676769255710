import { Dtos, FirstName, LastName, Types, UserId } from "@inrev/common";
import { useState } from "react";
import { match } from "ts-pattern";
import { Modal } from "../../../components/layout/Modal";
import { ModalItemWithHeader } from "../../../components/layout/ModalItemWithHeader";
import { Button } from "../../../components/ui/Button";
import { CheckboxOption } from "../../../components/ui/CheckboxOption";
import { Dropdown } from "../../../components/ui/Dropdown";
import {
	useAdminReassignUnderwriter,
	useFetchAdminUsers,
} from "../../../domain/admin/admin-user/api";
import { formatName } from "../../../utils";

export type AdminAssigneeControlsProps = {
	assignedEntity: Types.UnderwriterAssignment.UnderwriterAssignmentEntity;
	currentAssignee: UserId;
};

export const AdminAssigneeControls = (props: AdminAssigneeControlsProps) => {
	const [manageAssigneeModalOpen, setManageAssigneeModalOpen] = useState<boolean>(false);
	const { adminUsers = [] } = useFetchAdminUsers();
	const currentAssignee = adminUsers.find((u) => u.id === props.currentAssignee);

	return (
		<>
			<div
				className="flex items-center justify-center w-[28px] h-[28px] text-[12px] font-medium text-gray-700 border border-gray-400 bg-gray-100 hover:text-gray-800 hover:border-gray-600 rounded-full cursor-pointer"
				onClick={(e) => {
					e.preventDefault();
					setManageAssigneeModalOpen(true);
				}}
			>
				{currentAssignee
					? `${currentAssignee.firstName[0]}${currentAssignee.lastName[0]}`.toUpperCase()
					: "??"}
			</div>
			{manageAssigneeModalOpen && (
				<ManageAssignee
					{...props}
					onClose={() => setManageAssigneeModalOpen(false)}
					adminUsers={adminUsers}
				/>
			)}
		</>
	);
};

// Using this type to standardize the shape of the user object we are working with
export type AssigneeUser = {
	id: UserId;
	firstName: FirstName;
	lastName: LastName;
};

// To make this more reusable, we can also add the DTO for agent users as a valid input
export function assigneeUserFromQuery(user: Dtos.Admin.AdminUser.Get.Response): AssigneeUser {
	return {
		id: user.id,
		firstName: user.firstName,
		lastName: user.lastName,
	};
}

type ManageAssigneeModalProps = {
	assignedEntity: Types.UnderwriterAssignment.UnderwriterAssignmentEntity;
	currentAssignee: UserId;
	adminUsers: Dtos.Admin.AdminUser.Get.Response[];
	onClose: () => void;
};

export const ManageAssignee = ({
	assignedEntity,
	onClose,
	adminUsers,
	currentAssignee,
}: ManageAssigneeModalProps) => {
	const { reassignUnderwriter, reassignUnderwriterLoading } = useAdminReassignUnderwriter();

	const handleSubmit = (userId: UserId, reassignAll?: boolean) => {
		if (!userId) return;
		const dto: Dtos.Admin.UnderwriterAssignment.Assign.Request = match(assignedEntity)
			.with({ entityType: "account" }, (assignedEntity) => ({
				...assignedEntity,
				underwriterUserId: userId,
				reassignAll: reassignAll ?? false,
			}))
			.otherwise((assignedEntity) => ({
				...assignedEntity,
				underwriterUserId: userId,
			}));

		reassignUnderwriter(dto);
		onClose();
	};

	return (
		<ManageAssigneeModal
			userList={adminUsers.map(assigneeUserFromQuery)}
			selectedUserId={currentAssignee}
			header={
				<div className="flex flex-col">
					<span className="text-[15px] font-[550]">Reassign Underwriter</span>
				</div>
			}
			confirmText="Submit"
			cancelText="Cancel"
			showReassignAll={assignedEntity.entityType === "account"}
			loading={reassignUnderwriterLoading}
			onSubmit={handleSubmit}
			onClose={onClose}
		/>
	);
};

export const ManageAssigneeModal = ({
	userList,
	selectedUserId,
	header,
	confirmText = "Submit",
	cancelText = "Cancel",
	showReassignAll = false,
	loading,
	onSubmit,
	onClose,
}: {
	userList: AssigneeUser[];
	selectedUserId: UserId;
	header: React.ReactNode;
	confirmText?: string;
	cancelText?: string;
	showReassignAll?: boolean;
	loading?: boolean;
	onClose: () => void;
	onSubmit: (userId: UserId, reassignAll?: boolean) => void;
}) => {
	const [dropdownUserId, setDropdownUserId] = useState(selectedUserId);
	const [reassignAll, setReassignAll] = useState(false);
	const dropdownOptions = userList.map((user) => ({
		label: formatName(user),
		value: user.id,
	}));

	const submitFn = () => {
		dropdownUserId && onSubmit(dropdownUserId, reassignAll);
	};

	return (
		<Modal onClickOutside={onClose}>
			<ModalItemWithHeader
				onClose={onClose}
				header={header}
				className="w-[400px]"
				bodyClassName="p-[24px]"
			>
				<div className="flex flex-col space-y-[24px]">
					<div className="flex flex-col items-left">
						<Dropdown
							value={dropdownUserId}
							options={dropdownOptions}
							onChange={(userId: UserId) => setDropdownUserId(userId)}
						/>
					</div>
					{showReassignAll && (
						<div className="flex justify-left">
							<CheckboxOption
								label="Reassign all requests and bonds?"
								labelClassName="text-[14px]"
								checked={reassignAll}
								onChange={setReassignAll}
							/>
						</div>
					)}
					<div className="flex justify-center space-x-[10px]">
						<Button color="gray" onClick={onClose} className="w-[120px]">
							{cancelText}
						</Button>
						<Button
							onClick={submitFn}
							color="light-blue"
							filled
							className="w-[120px]"
							tabIndex={2}
							loading={loading}
							disabled={!selectedUserId || loading}
						>
							{confirmText}
						</Button>
					</div>
				</div>
			</ModalItemWithHeader>
		</Modal>
	);
};
