import { ColumnDef } from "@tanstack/react-table";
import { CheckMarkBubble } from "../../../../components/ui/CheckMarkBubble";
import { RowActionButtons } from "../../../../components/ui/row-action-buttons/RowActionButtons";
import { useUpdateActionItem } from "../../../../domain/admin/action-item/api";
import { useAuthenticatedUser } from "../../../../providers/AuthenticatedUserProvider";
import { formatEmpty } from "../../../../utils";
import { AdminAssigneeControls } from "../../shared/AdminAssigneeControls";
import { ActionItemWithDisplayName } from "./ActionItemView";

const entityNameMap = {
	account: "Account",
	bond: "Bond",
	quote: "Request",
};

export const actionItemTableColumns: ColumnDef<ActionItemWithDisplayName>[] = [
	{
		id: "status",
		accessorKey: "status",
		header: "Status",
		cell: (props) => {
			const { toggleCompleted } = useUpdateActionItem();
			const { user } = useAuthenticatedUser();
			return (
				<RowActionButtons alwaysShow={true}>
					<CheckMarkBubble
						toggleComplete={() => toggleCompleted(props.row.original, user.id)}
						isComplete={props.row.original.status === "complete"}
					/>
				</RowActionButtons>
			);
		},
		meta: { noTruncate: true },
		minSize: 70,
		size: 70,
		maxSize: 60,
		enableResizing: false,
		enableSorting: true,
		enableGlobalFilter: false,
		enableHiding: false,
		enableColumnFilter: true,
		enablePinning: false,
		enableGrouping: false,
		enableMultiSort: false,
	},
	{
		id: "relationType",
		accessorKey: "relationType",
		header: "Type",
		cell: (props) => formatEmpty(entityNameMap[props.row.original.relationType]),
		meta: { noTruncate: true },
		minSize: 80,
		size: 80,
		enableResizing: false,
		enableSorting: true,
		enableColumnFilter: true,
	},
	{
		id: "displayName",
		accessorKey: "displayName",
		header: "Entity Description",
		cell: (props) => formatEmpty(props.row.original.displayName),
		minSize: 120,
		size: 340,
		enableResizing: true,
		enableSorting: true,
		enableColumnFilter: true,
	},
	{
		id: "Description",
		accessorKey: "title",
		header: "Description",
		cell: (props) => formatEmpty(props.row.original.title),
		minSize: 320,
		size: 320,
		enableResizing: true,
		enableSorting: true,
		enableColumnFilter: true,
	},
	{
		id: "dueAt",
		accessorKey: "dueAt",
		header: "Due Date",
		cell: (props) => formatEmpty(props.row.original.dueAt?.toFormat("LLL dd, yyyy")),
	},
	{
		id: "underwriter",
		header: undefined,
		accessorKey: "underwriterUserId",
		cell: (props) => (
			<RowActionButtons alwaysShow={true}>
				<AdminAssigneeControls
					assignedEntity={{ entityType: "actionItem", entityId: props.row.original.id }}
					currentAssignee={props.row.original.assigneeId}
				/>
			</RowActionButtons>
		),
		meta: {
			noTruncate: true,
		},
		minSize: 60,
		size: 60,
		maxSize: 60,
		enableResizing: false,
		enableSorting: true,
		enableGlobalFilter: false,
		enableHiding: false,
		enableColumnFilter: true,
		enablePinning: false,
		enableGrouping: false,
		enableMultiSort: false,
	},
];
