import { ReactNode } from "react";
import { cn } from "../../../../../../components/lib/utils";

export const AdminActionItemLayout = ({
	containerClassName,
	statusIcon,
	content,
	footer,
}: {
	statusIcon: ReactNode;
	content: ReactNode;
	footer?: ReactNode;
	containerClassName?: string;
}) => {
	return (
		<div className={cn("p-[24px] border-b border-gray-100", containerClassName)}>
			<div className="flex justify-between items-center">
				<div className="flex gap-5 items-start justify-start w-full">
					<div className="self-start min-w-fit">{statusIcon}</div>
					<>{content}</>
				</div>
			</div>
			{footer && <div className="flex justify-center gap-4 w-full mt-5">{footer}</div>}
		</div>
	);
};
