import { z } from 'zod';
import { zswitch } from '../../utils.mjs';
import { Obligee as Obligee$1, Data as Data$1, Close as Close$1 } from './contract/contract-surety-bond.types.mjs';
import * as contractSuretyBond_types from './contract/contract-surety-bond.types.mjs';
export { contractSuretyBond_types as Contract };

var Obligee;
(function (Obligee) {
    Obligee.schema = Obligee$1.schema;
})(Obligee || (Obligee = {}));
var Data;
(function (Data) {
    Data.schema = zswitch((input) => {
        switch (input.suretyType) {
            case "contract":
                return Data$1.schema;
            default:
                return z.never();
        }
    });
})(Data || (Data = {}));
var Close;
(function (Close) {
    (function (Payload) {
        Payload.schema = zswitch((input) => {
            switch (input.suretyType) {
                case "contract":
                    return Close$1.Payload.schema;
                default:
                    return z.never();
            }
        });
    })(Close.Payload || (Close.Payload = {}));
})(Close || (Close = {}));

export { Close, Data, Obligee };
