import { z } from 'zod';
import { Underwriting } from '../../quote/submitted/contract/submitted-contract-surety-quote.types.mixins.mjs';

var Data;
(function (Data) {
    Data.projectSchema = Underwriting.Project.schema;
    Data.baseSchema = z.object({
        suretyType: z.literal("contract"),
        closedNote: z.string().nullish(),
        project: Data.projectSchema,
    });
})(Data || (Data = {}));

export { Data };
