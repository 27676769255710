import { Dtos } from "@inrev/common";
import { z } from "zod";
import { BondRequest } from "./types";

export const getBondIssuanceFormValidationSchema = (
	request: BondRequest & Extract<BondRequest, { status: "accepted" }>,
) => {
	const bondFormBaseSchema = Dtos.SuretyBondIssuance.Contract.Create.Request.schema.refine((data) =>
		request.bondIssuance.bondForm.allowedIssuanceMethods.includes(data.issuanceMethod),
	);
	const indemnityAgreementSchema = Dtos.IndemnityAgreement.Create.Request.schema.refine((data) => {
		return (
			request.bondIssuance.indemnityAgreement.allowedSignatureMethods.includes(
				data.signatureMethod,
			) &&
			request.bondIssuance.indemnityAgreement.indemnitors.companies.length ===
				data.companySigners.length &&
			data.companySigners.every((signer) =>
				request.bondIssuance.indemnityAgreement.indemnitors.companies.some(
					(company) => company.id === signer.contactId,
				),
			) &&
			request.bondIssuance.indemnityAgreement.indemnitors.individuals.length ===
				data.individualSigners.length &&
			data.individualSigners.every((signer) =>
				request.bondIssuance.indemnityAgreement.indemnitors.individuals.some(
					(individual) => individual.id === signer.contactId,
				),
			)
		);
	});
	const bondFormSchema = bondFormBaseSchema;

	if (request.bondIssuance.indemnityAgreement.status === "incomplete") {
		return z.object({ bondForm: bondFormSchema, indemnityAgreement: indemnityAgreementSchema });
	}
	return z.object({ bondForm: bondFormSchema });
};

export const getBondRequestDataValidationSchema =
	Dtos.SuretyQuote.Contract.Submit.Request.Data.buildDataValidationSchema;
