import { z } from 'zod';
import { FileType } from '../../../../../enums.mjs';

var Data;
(function (Data) {
    Data.bondSchema = z.object({
        contractAmount: z.union([z.string(), z.literal("")]),
        completionBond: z.union([z.boolean(), z.literal("")]),
    });
    Data.projectFileTypes = [FileType.contract];
})(Data || (Data = {}));

export { Data };
