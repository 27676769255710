// must get the data of all action items for the current user and display them in a sortable table

import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { match } from "ts-pattern";
import { LoadingModal } from "../../../../components/layout/LoadingModal";
import { CheckboxOption } from "../../../../components/ui/CheckboxOption";
import { DataTable } from "../../../../components/ui/DataTable/DataTable";
import { SearchInput } from "../../../../components/ui/SearchInput";
import { useFetchAdminActionItems } from "../../../../domain/admin/action-item/api";
import { ActionItem } from "../../../../domain/admin/action-item/type";
import {
	useFetchAccountPreviews,
	useFetchBondPreviews,
	useFetchBondRequestPreviews,
} from "../../../../domain/shared/previews/api";
import { AdminTableLayout } from "../../layout/AdminTableLayout";
import { AdminUserMenu } from "../../settings/AdminUserMenu";
import { actionItemTableColumns } from "./columns";

export type ActionItemWithDisplayName = ActionItem & {
	displayName: string;
};

// this component is what should be handling the getting of data
export const ActionItemView = () => {
	const { actionItems, actionItemsIsLoading } = useFetchAdminActionItems();
	const { accountPreviews } = useFetchAccountPreviews();
	const { bondPreviews } = useFetchBondPreviews();
	const { bondRequestPreviews } = useFetchBondRequestPreviews();
	const [showComplete, setShowComplete] = useState<boolean>(false);
	const [searchString, setSearchString] = useState<string>("");

	const statusColumnFilter = showComplete ? [] : [{ id: "status", value: "open" }];

	const navigate = useNavigate();

	const filteredSortedLists: {
		allActionItems: ActionItemWithDisplayName[];
	} = useMemo(() => {
		if (actionItems) {
			const withDisplayName = Object.values(actionItems).map((actionItem) => {
				return match(actionItem)
					.with({ relationType: "account" }, (actionItem) => {
						const account = accountPreviews?.find(
							(account) => account.id === actionItem.relationId,
						);
						return { ...actionItem, displayName: account?.displayName ?? "Unknown Account" };
					})
					.with({ relationType: "bond" }, (actionItem) => {
						const bond = bondPreviews?.find((bond) => bond.id === actionItem.relationId);
						return { ...actionItem, displayName: bond?.description ?? "Unknown Bond" };
					})
					.with({ relationType: "quote" }, (actionItem) => {
						const quote = bondRequestPreviews?.find((quote) => quote.id === actionItem.relationId);
						return { ...actionItem, displayName: quote?.description ?? "Unknown Quote" };
					})
					.exhaustive();
			});
			const allActionItems = Object.values(withDisplayName).sort((a, b) => {
				const aCompleted = !!a.completedAt;
				const bCompleted = !!b.completedAt;

				if (aCompleted !== bCompleted) {
					return aCompleted ? 1 : -1;
				}

				const aEffectiveDate = a.dueAt ? a.dueAt : a.createdAt;
				const bEffectiveDate = b.dueAt ? b.dueAt : b.createdAt;

				return aEffectiveDate.toMillis() - bEffectiveDate.toMillis();
			});

			return { allActionItems };
		}
		return { allActionItems: [] };
	}, [actionItems, accountPreviews, bondPreviews, bondRequestPreviews]);

	if (actionItemsIsLoading) {
		return <LoadingModal />;
	}

	const handleRowClick = (row: ActionItem) => {
		match(row)
			.with({ relationType: "account" }, (actionItem) => {
				navigate(`/accounts/${actionItem.relationId}`);
			})
			.with({ relationType: "bond" }, (actionItem) => {
				navigate(`/bonds/${actionItem.relationId}`);
			})
			.with({ relationType: "quote" }, (actionItem) => {
				navigate(`/requests/${actionItem.relationId}`);
			})
			.exhaustive();
	};

	return (
		<AdminTableLayout
			userMenu={AdminUserMenu}
			title={
				<div className="w-full h-full">
					<div className="flex items-center h-full space-x-[25px]">
						<div className="leading-[23px]">Action Items</div>
						<div className="flex items-center space-x-[12px]">
							<SearchInput
								className="w-[250px] bg-gray-50 border border-gray-200 focus-within:outline-gray-400 focus-within:shadow-none"
								inputClassName="placeholder:text-gray-500"
								placeholder="Search action items..."
								onChange={(value) => setSearchString(value)}
							/>
							<CheckboxOption
								checked={showComplete}
								onChange={() => setShowComplete(!showComplete)}
								label="Show completed"
								labelClassName="text-[14px] text-gray-500"
								compact
							/>
						</div>
					</div>
				</div>
			}
		>
			<div className="w-full h-full flex flex-col">
				<DataTable
					name="Action Items"
					columnDefs={actionItemTableColumns}
					onRowClick={handleRowClick}
					rowUrl={(row: ActionItem) =>
						match(row)
							.with({ relationType: "account" }, (actionItem) => {
								return `/accounts/${actionItem.relationId}`;
							})
							.with({ relationType: "bond" }, (actionItem) => {
								return `/bonds/${actionItem.relationId}`;
							})
							.with({ relationType: "quote" }, (actionItem) => {
								return `/requests/${actionItem.relationId}`;
							})
							.exhaustive()
					}
					searchString={searchString}
					columnFilters={[...statusColumnFilter]}
					data={filteredSortedLists.allActionItems}
					loadingMessage="Loading action items..."
				/>
			</div>
		</AdminTableLayout>
	);
};
