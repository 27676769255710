import { formatToDollar, toISODate } from "@inrev/common";
import { ColumnDef } from "@tanstack/react-table";
import { DateTime } from "luxon";
import { HiOutlineArrowTopRightOnSquare } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import { CSVDownloadButton } from "../../../../components/ui/CSVDownloadButton";
import { DataTable } from "../../../../components/ui/DataTable/DataTable";
import { getStatusTagByStatus } from "../../../../components/ui/StatusTag";
import { bondRequestTypeLabelMap } from "../../../../constants";
import { SubmittedSuretyAccount } from "../../../../domain/agent/account/types";
import { BondRequestPreview } from "../../../../domain/agent/request/types";
import { formatEmpty, formatFileName } from "../../../../utils";

type AccountRequestsTableProps = {
	account: SubmittedSuretyAccount;
};

const columns: ColumnDef<BondRequestPreview>[] = [
	{
		id: "status",
		accessorKey: "status",
		header: "Status",
		size: 200,
		filterFn: "equalsString",
		cell: (props) => getStatusTagByStatus(props.row.original.status),
	},
	{
		id: "principalName",
		accessorKey: "principalName",
		header: "Principal",
		size: 180,
	},
	{
		id: "submittedAt",
		accessorKey: "submittedAt",
		header: "Submitted",
		size: 122,
		accessorFn: ({ submittedAt }) =>
			submittedAt ? DateTime.fromISO(submittedAt).toLocaleString(DateTime.DATE_MED) : undefined,
		enableGlobalFilter: false,
	},
	{
		id: "amount",
		accessorKey: "amount",
		header: "Amount",
		size: 130,
		cell: (context) => formatToDollar(context.getValue<string>(), undefined),
	},
	{
		id: "description",
		accessorKey: "description",
		header: "Description",
		size: 145,
	},
	{
		id: "suretySubType",
		accessorKey: "suretySubType",
		header: "Type",
		size: 77,
		accessorFn: ({ suretySubType }) =>
			formatEmpty(!!suretySubType ? bondRequestTypeLabelMap[suretySubType] : undefined),
	},
];

export const AccountRequestsTable = ({ account }: AccountRequestsTableProps) => {
	const navigate = useNavigate();
	const handleRequestClick = (request: BondRequestPreview) => {
		navigate(`/requests/${request.id}`);
	};

	if (account.status === "approved" && account.quotes.length === 0) return null;
	return (
		<div className="flex flex-col w-full space-y-[20px]">
			<div className="flex items-center justify-between w-full h-fit min-h-fit">
				<div className="flex items-center space-x-[12px]">
					<span className="text-[20px] text-gray-900 font-medium min-w-fit">Requests</span>
					<div className="flex items-center justify-center h-[28px] w-[28px] min-w-fit bg-gray-100/70 text-[13px] font-medium rounded-full">
						{account.quotes.length}
					</div>
				</div>
				{account.quotes.length > 0 ? (
					<CSVDownloadButton
						fileName={`${formatFileName(account.displayName)}_requests_${toISODate(DateTime.now())}`}
						data={account.quotes}
						keys={[
							"principalName",
							"accountName",
							"amount",
							"description",
							"suretySubType",
							"status",
							"submittedAt",
						]}
						className="text-gray-600/65 hover:text-gray-800"
						loadingStateClassName="text-gray-400"
					>
						<HiOutlineArrowTopRightOnSquare className="text-[19px]" />
					</CSVDownloadButton>
				) : (
					<></>
				)}
			</div>
			{account.quotes.length > 0 ? (
				<div className="w-full h-fit min-h-fit">
					<DataTable
						name="account_bond_requests"
						columnDefs={columns}
						data={account.quotes}
						tableBodyBorderColor="gray-200"
						tableClassName="shadow-sm max-h-[400px]"
						onRowClick={handleRequestClick}
						rowUrl={(row) => `/requests/${row.id}`}
						expandToRows
					/>
				</div>
			) : (
				<span className="text-[15px] text-gray-600 font-normal italic">No requests</span>
			)}
		</div>
	);
};
