import { PopoverButton, PopoverPanelProps } from "@headlessui/react";
import { ReactNode } from "react";
import { HiDotsVertical } from "react-icons/hi";
import { cn } from "../../../../../../components/lib/utils";
import { Menu } from "../../../../../../components/ui/Menu";

export const ToolContextMenu = ({
	children,
	anchor,
}: {
	children: React.ReactNode;
	anchor?: PopoverPanelProps["anchor"];
}) => {
	return (
		<Menu
			button={<HiDotsVertical />}
			anchor={anchor ?? "left"}
			buttonClassName="h-[14px] w-[14px] cursor-pointer text-gray-400 hover:text-gray-800"
			panelClassName="flex flex-col items-start mt-6 w-[81px] bg-white border border-slate-100 rounded-md shadow-lg"
		>
			{children}
		</Menu>
	);
};

export const ToolContextMenuItem = ({
	disabled,
	onClick,
	label,
}: {
	disabled?: boolean;
	onClick: () => void;
	label: ReactNode;
}) => {
	return (
		<PopoverButton
			className={cn(
				"flex justify-start p-[10px] cursor-pointer hover:bg-gray-50 text-[12px] w-full",
				disabled && "text-gray-300 cursor-default hover:text-gray-300",
			)}
			onClick={() => {
				if (!disabled) {
					onClick();
				}
			}}
		>
			{label}
		</PopoverButton>
	);
};
