import { z } from 'zod';
import { workInProgressStatementId, companyContactId, isoDateSchema, isoDateTimeSchema } from '../../../types/opaque.mjs';
import '../../../types/utils.mjs';
import '../../../types/agency/carrier-agency-relationship.types.mjs';
import '../../../types/credit-report/company-credit-report.mjs';
import '../../../types/credit-report/individual-credit-report.mjs';
import '../../../types/payment/payment-template.mjs';
import '../../../types/surety/account/surety-account.types.mjs';
import '../../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../../types/surety/bond/surety-bond.types.mjs';
import '../../../types/surety/financial-statement/surety-financial-statement.mjs';
import { WorkInProgressStatement } from '../../../types/surety/work-in-progress-statement/surety-work-in-progress-statement.mjs';
import '../../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../../types/surety/indemnity/indemnity-agreement.mjs';
import '../../../types/user/user.types.mjs';
import '../../../types/entityRelation/entity-relation.mjs';
import '../../../enums.mjs';
import '../admin-user.dto.mjs';
import '../agency/agency.dto.mjs';
import '../company-credit-report-preview.dto.mjs';
import '../company-credit-report.dto.mjs';
import '../financial-statement/admin-financial-statement.dto.mjs';
import '../individual-credit-report-preview.dto.mjs';
import '../individual-credit-report.dto.mjs';
import '../individual-ofac-check-preview.dto.mjs';
import '../individual-ofac-check.dto.mjs';
import '../surety/account/admin-surety-account.dto.mjs';
import '../surety/account/contact/company-contact.dto.mjs';
import '../surety/account/contact/individual-contact.dto.mjs';
import '../surety/agency/carrier-agency-relationship.dto.mjs';
import '../surety/bond-form/surety-bond-form.dto.mjs';
import '../surety/bond/admin-surety-bond.dto.mjs';
import '../surety/carrier-bonding-line/admin-surety-account-bonding-line.dto.mjs';
import '../surety/indemnity-agreement/surety-indemnity-agreement.dto.mjs';
import '../surety/indemnity-agreement/surety-indemnity-waiver.dto.mjs';
import '../surety/quote/surety-quote.dto.mjs';
import '../underwriter-assignment.dto.mjs';
import '../note/admin-note.dto.mjs';
import '../note/admin-note-topic.dto.mjs';
import '../../agent/index.mjs';
import '../../comment/comment.dto.mjs';
import '../../credit-report/company-credit-report.dto.mjs';
import '../../credit-report/individual-credit-report.dto.mjs';
import '../../follower/follower.dto.mjs';
import '../../surety/account/surety-account.dto.mjs';
import '../../surety/bond-form/surety-bond-form-template.dto.mjs';
import '../../surety/bond-form/surety-bond-form.dto.mjs';
import '../../surety/bond-issuance/surety-bond-issuance.dto.mjs';
import '../../surety/bond/surety-bond.dto.mjs';
import '../../surety/indemnity/indemnity-agreement.dto.mjs';
import '../../surety/quote/surety-quote.dto.mjs';
import '../../user/user.dto.mjs';
import '../../action-item/action-item.dto.mjs';

var Get;
(function (Get) {
    (function (Response) {
        Response.schema = z.object({
            id: workInProgressStatementId,
            companyContactId: companyContactId,
            projectBacklogCost: z.number(),
            projectBacklogGrossProfit: z.number().optional(),
            date: isoDateSchema,
            createdAt: isoDateTimeSchema,
            updatedAt: isoDateTimeSchema,
        });
    })(Get.Response || (Get.Response = {}));
})(Get || (Get = {}));
var Create;
(function (Create) {
    (function (Request) {
        Request.schema = WorkInProgressStatement;
    })(Create.Request || (Create.Request = {}));
})(Create || (Create = {}));
var Update;
(function (Update) {
    (function (Request) {
        Request.schema = Create.Request.schema;
    })(Update.Request || (Update.Request = {}));
})(Update || (Update = {}));

export { Create, Get, Update };
