import { z } from 'zod';
import { suretyAccountId, suretyBondId, suretyQuoteId } from '../opaque.mjs';
import { zswitch } from '../utils.mjs';

var Relations;
(function (Relations) {
    const baseRelations = z.discriminatedUnion("relationType", [
        z.object({
            relationType: z.literal("account"),
            relationId: suretyAccountId,
        }),
        z.object({
            relationType: z.literal("bond"),
            relationId: suretyBondId,
        }),
        z.object({
            relationType: z.literal("quote"),
            relationId: suretyQuoteId,
        }),
    ]);
    (function (Notes) {
        Notes.schema = baseRelations;
    })(Relations.Notes || (Relations.Notes = {}));
    (function (ActionItems) {
        ActionItems.schema = baseRelations;
        ActionItems.partialSchema = zswitch((input) => {
            if (input.relationType) {
                return Relations.ActionItems.schema;
            }
            return z.object({});
        });
    })(Relations.ActionItems || (Relations.ActionItems = {}));
})(Relations || (Relations = {}));

export { Relations };
