export const ActionItemContentDisplay = ({ content }: { content?: string | null }) => {
	if (!content) return null;
	return (
		<div
			className="text-gray-800 text-[13px] text-[375] cursor-default select-none w-full"
			dangerouslySetInnerHTML={{
				__html: content.length > 0 && content !== "<p></p>" ? filterXSS(content) : "<p></p>",
			}}
		/>
	);
};
