import { ToolContextMenuItem } from "../../shared/components/ToolContextMenu";

export const PinMenuItem = ({
	isPinned,
	pinNote,
	disabled,
}: {
	isPinned: boolean;
	pinNote: () => void;
	disabled?: boolean;
}) => {
	return (
		<ToolContextMenuItem disabled={disabled} onClick={pinNote} label={isPinned ? "Unpin" : "Pin"} />
	);
};

export const ArchiveMenuItem = ({
	isArchived,
	archiveNote,
	disabled,
}: {
	isArchived: boolean;
	archiveNote: () => void;
	disabled?: boolean;
}) => {
	return (
		<ToolContextMenuItem
			disabled={disabled}
			onClick={archiveNote}
			label={isArchived ? "Unarchive" : "Archive"}
		/>
	);
};
