import { z } from 'zod';
import { nameSuffixSchema, suretyBondRenewalMethodSchema } from '../../../../../enums.mjs';
import { firstName, lastName, isoDateSchema, addressSchema, timePeriodSchema, calendarDay } from '../../../../opaque.mjs';
import { currencySchema } from '../../../../utils.mjs';
import { Obligee } from '../../../bond/contract/contract-surety-bond.types.mjs';

const principalSignerSchema = z.object({
    firstName: firstName,
    lastName: lastName,
    suffix: nameSuffixSchema.nullish(),
    title: z.string(),
});
var Data;
(function (Data) {
    (function (FillData) {
        FillData.baseSchema = z.object({
            bondNumber: z.string(),
            effectiveDate: isoDateSchema,
            bondAmount: currencySchema,
            obligees: z.array(Obligee.schema.omit({ role: true })).min(1),
            principal: z.object({
                name: z.string(),
                address: addressSchema,
            }),
            carrier: z.object({
                legalName: z.string(),
                address: addressSchema,
                attorneyInFactFullName: z.string().nullish(),
                attorneyInFactWitnessFullName: z.string().nullish(),
            }),
            agency: z.object({
                displayName: z.string(),
                address: addressSchema,
            }),
            principalSigner: principalSignerSchema.nullish(),
        });
    })(Data.FillData || (Data.FillData = {}));
    (function (Attributes) {
        Attributes.baseSchema = z.object({
            cancellable: z.boolean().nullish(),
            continuous: z.boolean().nullish(),
            numberOfPeriods: z.number().nullish(),
            periodLength: timePeriodSchema.nullish(),
            renewOnDay: calendarDay.nullish(),
            renewThroughYear: z.number().min(1980).nullish(),
            renewable: z.boolean().nullish(),
            renewalMethod: suretyBondRenewalMethodSchema.nullish(),
        });
    })(Data.Attributes || (Data.Attributes = {}));
})(Data || (Data = {}));

export { Data, principalSignerSchema };
