import { z } from 'zod';
import { ContractSuretyType, FileType, finalContractSuretyBondFormTypeDef } from '../../../../../enums.mjs';
import { suretyBondId, isoDateSchema } from '../../../../../types/opaque.mjs';
import { nonNegativeCurrencySchema, uploadedFileSchema, bondFormSubmissionSchema } from '../../../../../types/utils.mjs';
import '../../../../../types/agency/carrier-agency-relationship.types.mjs';
import '../../../../../types/credit-report/company-credit-report.mjs';
import '../../../../../types/credit-report/individual-credit-report.mjs';
import '../../../../../types/payment/payment-template.mjs';
import '../../../../../types/surety/account/surety-account.types.mjs';
import '../../../../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../../../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../../../../types/surety/bond/surety-bond.types.mjs';
import '../../../../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../../../../types/surety/work-in-progress-statement/surety-work-in-progress-statement.mjs';
import '../../../../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../../../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../../../../types/surety/indemnity/indemnity-agreement.mjs';
import '../../../../../types/user/user.types.mjs';
import '../../../../../types/entityRelation/entity-relation.mjs';
import { Submit as Submit$1 } from './contract-surety-quote.dto.mixins.mjs';

var Submit;
(function (Submit) {
    (function (Request) {
        let Bond;
        (function (Bond) {
            Bond.schema = Submit$1.Request.Bond.baseSchema.extend({
                type: z.literal(ContractSuretyType.bid_to_final),
                bidBondId: suretyBondId,
                contractAmount: nonNegativeCurrencySchema,
                actualBidAmount: nonNegativeCurrencySchema,
                secondBid: nonNegativeCurrencySchema.optional(),
                actualBidDate: isoDateSchema,
                project: z.object({
                    contractDate: isoDateSchema,
                    files: z.array(uploadedFileSchema([FileType.contract])).min(1),
                }),
                bondForm: bondFormSubmissionSchema([...finalContractSuretyBondFormTypeDef]),
            });
        })(Bond = Request.Bond || (Request.Bond = {}));
        (function (Base) {
            const baseSchema = Submit$1.Request.Base.baseSchema;
            Base.schema = baseSchema.extend({
                data: baseSchema.shape.data.extend({
                    bond: Bond.schema,
                }),
            });
        })(Request.Base || (Request.Base = {}));
    })(Submit.Request || (Submit.Request = {}));
})(Submit || (Submit = {}));

export { Submit };
