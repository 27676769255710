import { CompanyContactId, SuretyBondFormType, formatToDollar } from "@inrev/common";
import { DateTime } from "luxon";
import { Fragment, ReactElement, useContext, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { SummaryItem, SummaryItemProps } from "../../../../../../components/layout/SummaryItem";
import { SummaryItemGroup } from "../../../../../../components/layout/SummaryItemGroup";
import { SummaryItemSection } from "../../../../../../components/layout/SummaryItemSection";
import {
	SummarySection,
	SummarySectionProps,
} from "../../../../../../components/layout/SummarySection";
import { Separator } from "../../../../../../components/ui/Separator";
import {
	contractDamageOptions,
	contractHazardOptions,
	exposureSizeOptions,
	financialsPreparationMethodOptions,
} from "../../../../../../constants";
import { BondRequestDraftData } from "../../../../../../domain/agent/request/types";
import {
	boolToYN,
	formatAddress,
	formatName,
	formatSummaryDateFromEmpty,
} from "../../../../../../utils";
import { NAICSCodesList } from "../../../../../admin/shared/NAICSCodeList";
import { DraftBondRequestContext, DraftBondRequestSectionName } from "../DraftBondRequestView";
import { bondFormTypesAndLabels } from "../constants";
import { bondTypeOptions } from "./DraftBondRequestBondSection";

type DraftBondRequestSummarySectionProps = {
	sectionNames: DraftBondRequestSectionName[];
};

export const DraftBondRequestSummarySection = (props: DraftBondRequestSummarySectionProps) => {
	const { pathname } = useLocation();
	const { request, principalPreviewsMap, bondFormTemplates } = useContext(DraftBondRequestContext);
	const schema = request.draft.schema;
	const { watch, formState } = useFormContext<BondRequestDraftData>();
	const principalData = watch("principal");
	const principalSchema = schema.principal;
	const selectedPrincipalId = watch("principal.principalCompanyId");
	const selectedPrincipal = useMemo(
		() =>
			selectedPrincipalId !== undefined
				? principalPreviewsMap[selectedPrincipalId as CompanyContactId]
				: undefined,
		[selectedPrincipalId],
	);
	const newPrincipalCompanyOwners = watch("principal.companies");
	const principalExposureSizeLabel = exposureSizeOptions.find(
		(option) => option.value === principalData.exposureSize,
	)?.label;
	const newPrincipalIndividuals = watch("principal.individuals");
	const newPrincipalIndividualOwners = useMemo(
		() => newPrincipalIndividuals.filter((individual) => individual.type === "owner"),
		[newPrincipalIndividuals],
	);
	const creditReportPermission = watch("principal.creditReportPermission");
	const historyData = watch("history");
	const financialsData = watch("financials");
	const bondData = watch("bond");
	const bondSchema = schema.bond;
	const bondFormTemplate = useMemo(() => {
		if (bondData.bondForm.templateId !== "") {
			return bondFormTemplates.find((template) => template.id === bondData.bondForm.templateId);
		}
	}, [bondData]);
	const bondFormTypeLabel = useMemo(() => {
		const type: SuretyBondFormType | undefined =
			bondFormTemplate?.type ??
			(bondData.bondForm.upload ? bondData.bondForm.upload.type || undefined : undefined);
		if (type) return bondFormTypesAndLabels[type];
	}, [bondData, bondFormTemplate]);

	const summarySectionMap: Record<
		DraftBondRequestSectionName,
		ReactElement<SummarySectionProps> | null
	> = {
		principal: (
			<SummarySection header="Principal" to={{ pathname, section: "principal" }}>
				<SummaryItem
					value={selectedPrincipal?.name ?? "No principal selected"}
					schemaInclude={Boolean(principalSchema?.include.principalCompanyId)}
					error={formState.errors.principal?.principalCompanyId}
					to={{ pathname, section: "principal" }}
				/>
				<SummaryItemGroup>
					<SummaryItem
						label="12 Month Bonding Need"
						value={principalExposureSizeLabel}
						schemaInclude={Boolean(principalSchema?.include.exposureSize)}
						error={formState.errors.principal?.exposureSize}
						to={{ pathname, section: "principal", id: "exposureSize" }}
					/>
				</SummaryItemGroup>
				<SummaryItemGroup>
					<SummaryItem
						label="Name"
						value={principalData.company.name}
						schemaInclude={Boolean(principalSchema?.include.company?.name)}
						error={formState.errors.principal?.company?.name}
						to={{ pathname, section: "principal", id: "name" }}
					/>
					<SummaryItem
						label="FEIN"
						value={principalData.company.fein}
						schemaInclude={Boolean(principalSchema?.include.company?.fein)}
						error={formState.errors.principal?.company?.fein}
						to={{ pathname, section: "principal", id: "fein" }}
					/>
					<SummaryItem
						label="Address"
						value={formatAddress(principalData.company.address)}
						schemaInclude={Boolean(principalSchema?.include.company?.address)}
						error={formState.errors.principal?.company?.address}
						to={{ pathname, section: "principal", id: "address" }}
					/>
				</SummaryItemGroup>
				<SummaryItemSection
					header="Company Owners, Subsidiaries, and Affiliates"
					error={formState.errors.principal?.companies?.root}
					to={{ pathname, section: "principal", id: "companies" }}
					schemaInclude={principalSchema?.include.companies}
				>
					{!newPrincipalCompanyOwners.length && (
						<div className="text-[14px] font-normal text-gray-900">None</div>
					)}
					{newPrincipalCompanyOwners.map((company, index) => (
						<Fragment key={company.draftId}>
							<SummaryItemGroup key={company.draftId}>
								<SummaryItem
									label="Name"
									value={company.name}
									error={formState.errors.principal?.companies?.[index]?.name}
									schemaInclude={Boolean(principalSchema?.include.companies)}
									to={{ pathname, section: "principal", id: "companies" }}
								/>
								<SummaryItem
									label="FEIN"
									value={company.fein}
									error={formState.errors.principal?.companies?.[index]?.fein}
									schemaInclude={Boolean(principalSchema?.include.companies)}
									to={{ pathname, section: "principal", id: "companies" }}
								/>
								<SummaryItem
									label="Address"
									value={formatAddress(company.address)}
									error={formState.errors.principal?.companies?.[index]?.address}
									schemaInclude={Boolean(principalSchema?.include.companies)}
									to={{ pathname, section: "principal", id: "companies" }}
								/>
							</SummaryItemGroup>
							{index !== principalData.companies.length - 1 && (
								<Separator orientation="horizontal" className="bg-gray-200" />
							)}
						</Fragment>
					))}
				</SummaryItemSection>
				<SummaryItemSection
					header="Individual Owners"
					error={formState.errors.principal?.individuals}
					to={{ pathname, section: "principal", id: "individuals" }}
					schemaInclude={principalSchema?.include.individuals}
				>
					{!principalData.individuals.length && (
						<div className="text-[14px] font-normal text-gray-900">None</div>
					)}
					{newPrincipalIndividualOwners.map((owner, index) => (
						<Fragment key={owner.draftId}>
							<SummaryItemGroup key={owner.draftId}>
								<SummaryItem
									label="First Name"
									value={owner.firstName}
									schemaInclude={Boolean(principalSchema?.include.individuals)}
									to={{ pathname, section: "principal", id: "individuals" }}
								/>
								<SummaryItem
									label="Middle Initial"
									value={owner.middleInitial}
									schemaInclude={Boolean(principalSchema?.include.individuals)}
									to={{ pathname, section: "principal", id: "individuals" }}
								/>
								<SummaryItem
									label="Last Name"
									value={owner.lastName}
									schemaInclude={Boolean(principalSchema?.include.individuals)}
									to={{ pathname, section: "principal", id: "individuals" }}
								/>
								<SummaryItem
									label="Suffix"
									value={owner.suffix}
									schemaInclude={Boolean(principalSchema?.include.individuals)}
									to={{ pathname, section: "principal", id: "individuals" }}
								/>
								<SummaryItem
									label="Address"
									value={formatAddress(owner.address)}
									schemaInclude={Boolean(principalSchema?.include.individuals)}
									to={{ pathname, section: "principal", id: "individuals" }}
								/>
								<SummaryItem
									label="Email"
									value={owner.email}
									schemaInclude={Boolean(principalSchema?.include.individuals)}
									to={{ pathname, section: "principal", id: "individuals" }}
								/>
								<SummaryItem
									label="SSN"
									value={owner.ssn}
									schemaInclude={Boolean(principalSchema?.include.individuals)}
									to={{ pathname, section: "principal", id: "individuals" }}
								/>
								<SummaryItem
									label="Credit Report Permission"
									value={boolToYN(
										creditReportPermission.find((permission) => permission.id === owner.draftId)
											?.permission,
									)}
									error={
										formState.errors.principal?.creditReportPermission?.[
											creditReportPermission.findIndex(
												(permission) => permission.id === owner.draftId,
											)
										]
									}
									schemaInclude={Boolean(principalSchema?.include.individuals)}
									to={{ pathname, section: "principal", id: "individuals" }}
								/>
								{owner.spouseDraftId ? (
									((): ReactElement<SummaryItemProps> => {
										const spouse = newPrincipalIndividuals.find(
											(individual) => individual.draftId === owner.spouseDraftId,
										);
										if (!spouse) throw new Error();
										if (spouse.type === "owner")
											return (
												<SummaryItem
													label="Spouse"
													value={formatName(spouse)}
													schemaInclude={Boolean(principalSchema?.include.individuals)}
													to={{ pathname, section: "principal", id: "individuals" }}
												/>
											);
										else
											return (
												<>
													<SummaryItem
														label="Spouse First Name"
														value={spouse.firstName}
														schemaInclude={Boolean(principalSchema?.include.individuals)}
														to={{ pathname, section: "principal", id: "individuals" }}
													/>
													<SummaryItem
														label="Spouse Middle Initial"
														value={spouse.middleInitial}
														schemaInclude={Boolean(principalSchema?.include.individuals)}
														to={{ pathname, section: "principal", id: "individuals" }}
													/>
													<SummaryItem
														label="Spouse Last Name"
														value={spouse.lastName}
														schemaInclude={Boolean(principalSchema?.include.individuals)}
														to={{ pathname, section: "principal", id: "individuals" }}
													/>
													<SummaryItem
														label="Spouse Suffix"
														value={spouse.suffix}
														schemaInclude={Boolean(principalSchema?.include.individuals)}
														to={{ pathname, section: "principal", id: "individuals" }}
													/>
													<SummaryItem
														label="Spouse Address"
														value={formatAddress(spouse.address)}
														schemaInclude={Boolean(principalSchema?.include.individuals)}
														to={{ pathname, section: "principal", id: "individuals" }}
													/>
													<SummaryItem
														label="Spouse Email"
														value={spouse.email}
														schemaInclude={Boolean(principalSchema?.include.individuals)}
														to={{ pathname, section: "principal", id: "individuals" }}
													/>
												</>
											);
									})()
								) : (
									<></>
								)}
							</SummaryItemGroup>
							{index !== newPrincipalIndividualOwners.length - 1 && (
								<Separator orientation="horizontal" className="bg-gray-200" />
							)}
						</Fragment>
					))}
				</SummaryItemSection>
				<SummaryItemSection
					header="Credit Report Permission"
					error={formState.errors.principal?.creditReportPermission}
					to={{ pathname, section: "principal", id: "creditReportPermission" }}
					schemaInclude={
						!principalData.newPrincipal && principalSchema?.include.creditReportPermission
					}
				>
					{principalData.creditReportPermission.map((permission, index) => (
						<SummaryItemGroup key={permission.id}>
							<SummaryItem
								label="Name"
								value={permission.name}
								schemaInclude={Boolean(principalSchema?.include.creditReportPermission)}
								to={{ pathname, section: "principal", id: "creditReportPermission" }}
							/>
							<SummaryItem
								label="Permission"
								value={boolToYN(permission.permission)}
								error={formState.errors.principal?.creditReportPermission?.[index]}
								schemaInclude={Boolean(principalSchema?.include.creditReportPermission)}
								to={{ pathname, section: "principal", id: "creditReportPermission" }}
							/>
						</SummaryItemGroup>
					))}
				</SummaryItemSection>
			</SummarySection>
		),
		history: (
			<SummarySection header="History" to={{ pathname, section: "history" }}>
				<SummaryItemGroup>
					<SummaryItem
						vertical
						label="What type of work does this account specialize in?"
						value={
							<NAICSCodesList
								naicsCodes={historyData.naicsCodes}
								emptyMessage="--"
								className="my-[8px] !bg-gray-100 bg-opacity-100"
								hideExpand
								showCount
							/>
						}
						error={formState.errors.history?.naicsCodes}
						schemaInclude={Boolean(request.draft.schema.history?.include.naicsCodes)}
						to={{ pathname, section: "history", id: "naicsCode" }}
					/>
					<SummaryItem
						vertical
						label="What is the value of the largest project completed by any company in this account?"
						value={formatToDollar(historyData.largestProjectCompleted)}
						error={formState.errors.history?.largestProjectCompleted}
						schemaInclude={Boolean(request.draft.schema.history?.include.largestProjectCompleted)}
						to={{ pathname, section: "history", id: "largestProjectCompleted" }}
					/>
					<SummaryItem
						vertical
						label="Has any company in this account ever failed to complete a project?"
						value={boolToYN(historyData.failedToCompleteAProject)}
						error={formState.errors.history?.failedToCompleteAProject}
						schemaInclude={Boolean(request.draft.schema.history?.include.failedToCompleteAProject)}
						to={{ pathname, section: "history", id: "failedToCompleteAProject" }}
					/>
					<SummaryItem
						vertical
						label="In the past 3 years, has any company in this account experienced a gross loss on any project greater than 10% of that project's contract value?"
						value={boolToYN(historyData.constructionProjectGrossLoss)}
						error={formState.errors.history?.constructionProjectGrossLoss}
						schemaInclude={Boolean(
							request.draft.schema.history?.include.constructionProjectGrossLoss,
						)}
						to={{ pathname, section: "history", id: "constructionProjectGrossLoss" }}
					/>
					<SummaryItem
						vertical
						label="Does any company in this account expect a gross loss on a project they are currently working on?"
						value={boolToYN(historyData.currentConstructionProjectGrossLoss)}
						error={formState.errors.history?.currentConstructionProjectGrossLoss}
						schemaInclude={Boolean(
							request.draft.schema.history?.include.currentConstructionProjectGrossLoss,
						)}
						to={{ pathname, section: "history", id: "currentConstructionProjectGrossLoss" }}
					/>
					<SummaryItem
						vertical
						label="Has any company in this account ever lost a payment suit?"
						value={boolToYN(historyData.lostAPaymentSuit)}
						error={formState.errors.history?.lostAPaymentSuit}
						schemaInclude={Boolean(request.draft.schema.history?.include.lostAPaymentSuit)}
						to={{ pathname, section: "history", id: "lostAPaymentSuit" }}
					/>
					<SummaryItem
						vertical
						label="Has any company in this account ever caused a loss to a surety?"
						value={boolToYN(historyData.causedSuretyLoss)}
						error={formState.errors.history?.causedSuretyLoss}
						schemaInclude={Boolean(request.draft.schema.history?.include.causedSuretyLoss)}
						to={{ pathname, section: "history", id: "causedSuretyLoss" }}
					/>
				</SummaryItemGroup>
			</SummarySection>
		),
		financials: (
			<SummarySection header="Financials" to={{ pathname, section: "financials" }}>
				<SummaryItemGroup>
					<SummaryItem
						label="Statement Date"
						value={
							typeof financialsData.statementDate === "object"
								? DateTime.fromJSDate(financialsData.statementDate).toLocaleString(
										DateTime.DATE_FULL,
									)
								: formatSummaryDateFromEmpty(financialsData.statementDate)
						}
						error={formState.errors.financials?.statementDate}
						schemaInclude={Boolean(request.draft.schema.financials?.include.statementDate)}
						to={{ pathname, section: "financials", id: "statementDate" }}
					/>
					<SummaryItem
						label="Preparation Method"
						value={
							financialsPreparationMethodOptions.find(
								(option) => option.value === financialsData.preparationMethod,
							)?.label
						}
						error={formState.errors.financials?.preparationMethod}
						schemaInclude={Boolean(request.draft.schema.financials?.include.preparationMethod)}
						to={{ pathname, section: "financials", id: "preparationMethod" }}
					/>
				</SummaryItemGroup>
				<SummaryItemSection
					header="Balance Sheet"
					schemaInclude={Boolean(request.draft.schema.financials?.include.balanceSheet)}
					to={{ pathname, section: "financials", id: "balanceSheet" }}
				>
					<SummaryItemGroup>
						<SummaryItem
							label="Cash"
							value={formatToDollar(financialsData.balanceSheet.corporateCash)}
							error={formState.errors.financials?.balanceSheet?.corporateCash}
							schemaInclude={Boolean(
								request.draft.schema.financials?.include.balanceSheet?.corporateCash,
							)}
							to={{ pathname, section: "financials", id: "corporateCash" }}
						/>
						<SummaryItem
							label="Accounts Receivable (< 90 days)"
							value={formatToDollar(financialsData.balanceSheet.accountsReceivable)}
							error={formState.errors.financials?.balanceSheet?.accountsReceivable}
							schemaInclude={Boolean(
								request.draft.schema.financials?.include.balanceSheet?.accountsReceivable,
							)}
							to={{ pathname, section: "financials", id: "accountsReceivable" }}
						/>
						<SummaryItem
							label="Underbillings"
							value={formatToDollar(financialsData.balanceSheet.underbillings)}
							error={formState.errors.financials?.balanceSheet?.underbillings}
							schemaInclude={Boolean(
								request.draft.schema.financials?.include.balanceSheet?.underbillings,
							)}
							to={{ pathname, section: "financials", id: "underbillings" }}
						/>
						<SummaryItem
							label="Current Assets"
							value={formatToDollar(financialsData.balanceSheet.currentAssets)}
							error={formState.errors.financials?.balanceSheet?.currentAssets}
							schemaInclude={Boolean(
								request.draft.schema.financials?.include.balanceSheet?.currentAssets,
							)}
							to={{ pathname, section: "financials", id: "currentAssets" }}
						/>
						<SummaryItem
							label="Total Assets"
							value={formatToDollar(financialsData.balanceSheet.totalAssets)}
							error={formState.errors.financials?.balanceSheet?.totalAssets}
							schemaInclude={Boolean(
								request.draft.schema.financials?.include.balanceSheet?.totalAssets,
							)}
							to={{ pathname, section: "financials", id: "totalAssets" }}
						/>
						<SummaryItem
							label="Accounts Payable"
							value={formatToDollar(financialsData.balanceSheet.accountsPayable)}
							error={formState.errors.financials?.balanceSheet?.accountsPayable}
							schemaInclude={Boolean(
								request.draft.schema.financials?.include.balanceSheet?.accountsPayable,
							)}
							to={{ pathname, section: "financials", id: "accountsPayable" }}
						/>
						<SummaryItem
							label="Credit Cards Payable"
							value={formatToDollar(financialsData.balanceSheet.creditCardsPayable)}
							error={formState.errors.financials?.balanceSheet?.creditCardsPayable}
							schemaInclude={Boolean(
								request.draft.schema.financials?.include.balanceSheet?.creditCardsPayable,
							)}
							to={{ pathname, section: "financials", id: "creditCardsPayable" }}
						/>
						<SummaryItem
							label="Revolving Line of Credit"
							value={formatToDollar(financialsData.balanceSheet.bankLinePayable)}
							error={formState.errors.financials?.balanceSheet?.bankLinePayable}
							schemaInclude={Boolean(
								request.draft.schema.financials?.include.balanceSheet?.bankLinePayable,
							)}
							to={{ pathname, section: "financials", id: "bankLinePayable" }}
						/>
						<SummaryItem
							label="Accrued Expenses"
							value={formatToDollar(financialsData.balanceSheet.accruedExpenses)}
							error={formState.errors.financials?.balanceSheet?.accruedExpenses}
							schemaInclude={Boolean(
								request.draft.schema.financials?.include.balanceSheet?.accruedExpenses,
							)}
							to={{ pathname, section: "financials", id: "accruedExpenses" }}
						/>
						<SummaryItem
							label="Overbillings"
							value={formatToDollar(financialsData.balanceSheet.overbillings)}
							error={formState.errors.financials?.balanceSheet?.overbillings}
							schemaInclude={Boolean(
								request.draft.schema.financials?.include.balanceSheet?.overbillings,
							)}
							to={{ pathname, section: "financials", id: "overbillings" }}
						/>
						<SummaryItem
							label="Current Portion of Long Term Debt"
							value={formatToDollar(financialsData.balanceSheet.currentPortionOfLtd)}
							error={formState.errors.financials?.balanceSheet?.currentPortionOfLtd}
							schemaInclude={Boolean(
								request.draft.schema.financials?.include.balanceSheet?.currentPortionOfLtd,
							)}
							to={{ pathname, section: "financials", id: "currentPortionOfLtd" }}
						/>
						<SummaryItem
							label="Current Liabilities"
							value={formatToDollar(financialsData.balanceSheet.currentLiabilities)}
							error={formState.errors.financials?.balanceSheet?.currentLiabilities}
							schemaInclude={Boolean(
								request.draft.schema.financials?.include.balanceSheet?.currentLiabilities,
							)}
							to={{ pathname, section: "financials", id: "currentLiabilities" }}
						/>
						<SummaryItem
							label="Term Loan Debt"
							value={formatToDollar(financialsData.balanceSheet.termLoanDebt)}
							error={formState.errors.financials?.balanceSheet?.termLoanDebt}
							schemaInclude={Boolean(
								request.draft.schema.financials?.include.balanceSheet?.termLoanDebt,
							)}
							to={{ pathname, section: "financials", id: "termLoanDebt" }}
						/>
						<SummaryItem
							label="Total Liabilities"
							value={formatToDollar(financialsData.balanceSheet.totalLiabilities)}
							error={formState.errors.financials?.balanceSheet?.totalLiabilities}
							schemaInclude={Boolean(
								request.draft.schema.financials?.include.balanceSheet?.totalLiabilities,
							)}
							to={{ pathname, section: "financials", id: "totalLiabilities" }}
						/>
					</SummaryItemGroup>
				</SummaryItemSection>
				<SummaryItemSection
					header="Income Statement"
					schemaInclude={Boolean(request.draft.schema.financials?.include.incomeStatement)}
					to={{ pathname, section: "financials", id: "incomeStatement" }}
				>
					<SummaryItemGroup>
						<SummaryItem
							label="Revenue"
							value={formatToDollar(financialsData.incomeStatement.revenue)}
							error={formState.errors.financials?.incomeStatement?.revenue}
							schemaInclude={Boolean(
								request.draft.schema.financials?.include.incomeStatement?.revenue,
							)}
							to={{ pathname, section: "financials", id: "revenue" }}
						/>
						<SummaryItem
							label="General Administrative Expense"
							value={formatToDollar(financialsData.incomeStatement.gaExpense)}
							error={formState.errors.financials?.incomeStatement?.gaExpense}
							schemaInclude={Boolean(
								request.draft.schema.financials?.include.incomeStatement?.gaExpense,
							)}
							to={{ pathname, section: "financials", id: "gaExpense" }}
						/>
						<SummaryItem
							label="Profitable YTD"
							value={boolToYN(financialsData.incomeStatement.profitableYtd)}
							error={formState.errors.financials?.incomeStatement?.profitableYtd}
							schemaInclude={Boolean(
								request.draft.schema.financials?.include.incomeStatement?.profitableYtd,
							)}
							to={{ pathname, section: "financials", id: "profitableYtd" }}
						/>
						<SummaryItem
							label="Profitable Last FYE"
							value={boolToYN(financialsData.incomeStatement.profitableLastFye)}
							error={formState.errors.financials?.incomeStatement?.profitableLastFye}
							schemaInclude={Boolean(
								request.draft.schema.financials?.include.incomeStatement?.profitableLastFye,
							)}
							to={{ pathname, section: "financials", id: "profitableLastFye" }}
						/>
						<SummaryItem
							label="Profitable year prior to last fiscal year"
							value={boolToYN(financialsData.incomeStatement.profitableYearPriorToLastFiscalYear)}
							error={
								formState.errors.financials?.incomeStatement?.profitableYearPriorToLastFiscalYear
							}
							schemaInclude={Boolean(
								request.draft.schema.financials?.include.incomeStatement
									?.profitableYearPriorToLastFiscalYear,
							)}
							to={{ pathname, section: "financials", id: "profitableYearPriorToLastFiscalYear" }}
						/>
					</SummaryItemGroup>
				</SummaryItemSection>
				<SummaryItemSection
					header="Work In Progress"
					schemaInclude={Boolean(request.draft.schema.financials?.include.wip)}
					to={{ pathname, section: "financials", id: "wip" }}
				>
					<SummaryItemGroup>
						<SummaryItem
							label="Backlog Cost-to-Complete"
							value={formatToDollar(financialsData.wip.projectBacklogCost)}
							error={formState.errors.financials?.wip?.projectBacklogCost}
							schemaInclude={Boolean(
								request.draft.schema.financials?.include.wip?.projectBacklogCost,
							)}
							to={{ pathname, section: "financials", id: "projectBacklogCost" }}
						/>
						<SummaryItem
							label="Gross Profit in the Backlog"
							value={formatToDollar(financialsData.wip.projectBacklogGrossProfit)}
							error={formState.errors.financials?.wip?.projectBacklogGrossProfit}
							schemaInclude={Boolean(
								request.draft.schema.financials?.include.wip?.projectBacklogGrossProfit,
							)}
							to={{ pathname, section: "financials", id: "projectBacklogGrossProfit" }}
						/>
					</SummaryItemGroup>
				</SummaryItemSection>
				<SummaryItemSection
					header="Bank Details"
					schemaInclude={Boolean(request.draft.schema.financials?.include.bankDetails)}
					to={{ pathname, section: "financials", id: "bankDetails" }}
				>
					<SummaryItemGroup>
						<SummaryItem
							label="Revolving Line of Credit Limit"
							value={formatToDollar(financialsData.bankDetails.blocSize)}
							error={formState.errors.financials?.bankDetails?.blocSize}
							schemaInclude={Boolean(
								request.draft.schema.financials?.include.bankDetails?.blocSize,
							)}
							to={{ pathname, section: "financials", id: "blocSize" }}
						/>
					</SummaryItemGroup>
				</SummaryItemSection>
			</SummarySection>
		),
		bond: bondSchema ? (
			<SummarySection header="Bond" to={{ pathname, section: "bond" }}>
				<SummaryItemGroup>
					<SummaryItem
						label="Bond Type"
						value={bondTypeOptions.find((option) => option.value === bondData.type)?.label}
						error={formState.errors.bond?.type}
						schemaInclude={Boolean(request.draft.schema.bond?.include.type)}
						to={{ pathname, section: "bond", id: "type" }}
					/>
					<SummaryItem
						label="Bid Amount"
						value={formatToDollar(bondData.bidAmount)}
						error={formState.errors.bond?.bidAmount}
						schemaInclude={Boolean(request.draft.schema.bond?.include.bidAmount)}
						to={{ pathname, section: "bond", id: "bidAmount" }}
					/>
					<SummaryItem
						label="Bond Amount"
						value={formatToDollar(bondData.bondAmount)}
						error={formState.errors.bond?.bondAmount}
						schemaInclude={Boolean(request.draft.schema.bond?.include.bondAmount)}
						to={{ pathname, section: "bond", id: "bondAmount" }}
					/>
					<SummaryItem
						label="Contract Amount"
						value={formatToDollar(bondData.contractAmount)}
						error={formState.errors.bond?.contractAmount}
						schemaInclude={Boolean(request.draft.schema.bond?.include.contractAmount)}
						to={{ pathname, section: "bond", id: "contractAmount" }}
					/>
					<SummaryItem
						label="What type of work will be performed?"
						value={
							<NAICSCodesList
								naicsCodes={bondData.project.scopeOfWork}
								emptyMessage="--"
								className="my-[8px] !bg-gray-100 bg-opacity-100"
								hideExpand
								showCount
							/>
						}
						error={formState.errors.bond?.project?.scopeOfWork}
						vertical
						schemaInclude={Boolean(request.draft.schema.bond?.include.project?.scopeOfWork)}
						to={{ pathname, section: "bond", id: "scopeOfWork" }}
					/>
					<SummaryItem
						label="Project Description"
						value={bondData.project.description}
						error={formState.errors.bond?.project?.description}
						schemaInclude={Boolean(request.draft.schema.bond?.include.project?.description)}
						to={{ pathname, section: "bond", id: "description" }}
					/>
					<SummaryItem
						label="Project City"
						value={bondData.project.address.city}
						error={formState.errors.bond?.project?.address?.city}
						schemaInclude={Boolean(request.draft.schema.bond?.include.project?.address?.city)}
						to={{ pathname, section: "bond", id: "city" }}
					/>
					<SummaryItem
						label="Project State"
						value={bondData.project.address.state}
						error={formState.errors.bond?.project?.address?.state}
						schemaInclude={Boolean(request.draft.schema.bond?.include.project?.address?.state)}
						to={{ pathname, section: "bond", id: "state" }}
					/>
				</SummaryItemGroup>
				<SummaryItemGroup>
					<SummaryItem
						label="Does the bond require a Treasury Listing?"
						value={boolToYN(bondData.tListingRequired)}
						error={formState.errors.bond?.tListingRequired}
						vertical
						schemaInclude={Boolean(request.draft.schema.bond?.include.tListingRequired)}
						to={{ pathname, section: "bond", id: "tListingRequired" }}
					/>
					<SummaryItem
						label="Is the principal a developer and/or is this a completion bond?"
						value={boolToYN(bondData.completionBond)}
						error={formState.errors.bond?.completionBond}
						vertical
						schemaInclude={Boolean(request.draft.schema.bond?.include.completionBond)}
						to={{ pathname, section: "bond", id: "completionBond" }}
					/>
					<SummaryItem
						label="Does the contract include any of the following damages?"
						value={
							!bondData.project.contractDamages.length
								? "None"
								: bondData.project.contractDamages
										.map(
											(damage) =>
												contractDamageOptions.find((option) => option.value === damage)?.label,
										)
										.join(", ")
						}
						vertical
						schemaInclude={Boolean(request.draft.schema.bond?.include.project?.contractDamages)}
						to={{ pathname, section: "bond", id: "contractDamages" }}
					/>
					<SummaryItem
						label="Do any of the following apply to the contract?"
						value={
							!bondData.project.contractHazards.length
								? "None"
								: bondData.project.contractHazards
										.map(
											(damage) =>
												contractHazardOptions.find((option) => option.value === damage)?.label,
										)
										.join(", ")
						}
						vertical
						schemaInclude={Boolean(request.draft.schema.bond?.include.project?.contractHazards)}
						to={{ pathname, section: "bond", id: "contractHazards" }}
					/>
				</SummaryItemGroup>
				{bondData.type === "bid" && (
					<SummaryItemSection
						header="RFP / Bid Package"
						schemaInclude={Boolean(request.draft.schema.bond?.include.project?.files !== undefined)}
						to={{ pathname, section: "bond", id: "rfp_bid_package" }}
					>
						<SummaryItem
							label="File"
							value={
								bondData.project.files[0] !== undefined
									? `${bondData.project.files[0].name}.${bondData.project.files[0].extension}`
									: "--"
							}
							error={formState.errors.bond?.project?.files}
							schemaInclude={Boolean(bondSchema.include.project?.files)}
							to={{ pathname, section: "bond", id: "rfp_bid_package" }}
						/>
					</SummaryItemSection>
				)}
				{bondData.type === "final" && (
					<SummaryItemSection
						header="Contract"
						schemaInclude={Boolean(request.draft.schema.bond?.include.project?.files !== undefined)}
						to={{ pathname, section: "bond", id: "contract" }}
					>
						<SummaryItem
							label="File"
							value={
								bondData.project.files[0] !== undefined
									? `${bondData.project.files[0].name}.${bondData.project.files[0].extension}`
									: "--"
							}
							error={formState.errors.bond?.project?.files}
							schemaInclude={Boolean(bondSchema.include.project?.files)}
							to={{ pathname, section: "bond", id: "contract" }}
						/>
					</SummaryItemSection>
				)}
				<SummaryItemSection
					header="Bond Form"
					schemaInclude={Boolean(request.draft.schema.bond?.include.bondForm)}
					to={{ pathname, section: "bond", id: "form" }}
				>
					<SummaryItemGroup>
						<SummaryItem
							label="Form"
							value={
								bondFormTemplate?.name ??
								(bondData.bondForm.upload
									? bondData.bondForm.upload.files
											.map((file) => `${file.name}.${file.extension}`)
											.join(", ")
									: undefined)
							}
							error={formState.errors.bond?.bondForm}
							schemaInclude={Boolean(request.draft.schema.bond?.include.bondForm)}
							to={{ pathname, section: "bond", id: "form" }}
						/>
						<SummaryItem
							label="Type"
							value={bondFormTypeLabel}
							error={formState.errors.bond?.bondForm}
							schemaInclude={Boolean(request.draft.schema.bond?.include.bondForm)}
							to={{ pathname, section: "bond", id: "form" }}
						/>
					</SummaryItemGroup>
				</SummaryItemSection>
			</SummarySection>
		) : null,
	};

	return (
		<div className="w-full flex flex-col space-y-[25px] pt-[18px] pb-[125px]">
			{props.sectionNames.map((sectionName) => (
				<Fragment key={sectionName}>{summarySectionMap[sectionName]}</Fragment>
			))}
		</div>
	);
};
