import { Relations, actionItemStatus } from "@inrev/common";
import { CheckMarkBubble } from "../../../../../../components/ui/CheckMarkBubble";
import {
	useDeleteActionItem,
	useUpdateActionItem,
} from "../../../../../../domain/admin/action-item/api";
import { ActionItem } from "../../../../../../domain/admin/action-item/type";
import { useAuthenticatedUser } from "../../../../../../providers/AuthenticatedUserProvider";
import { AdminAssigneeControls } from "../../../AdminAssigneeControls";
import { ToolContextMenu, ToolContextMenuItem } from "../../shared/components/ToolContextMenu";
import { AdminActionItemLayout } from "../layouts/AdminActionItemLayout";
import { ActionItemDateDisplay } from "./ActionItemDateDisplay";
import { ActionItemTitleDisplay } from "./ActionItemTitleDisplay";

export const AdminActionItem = ({
	actionItem,
	setCurrentActionItem,
	containerClassName,
}: {
	actionItem: ActionItem;
	setCurrentActionItem: (actionItem: ActionItem, isEditing?: boolean) => void;
	containerClassName?: string;
}) => {
	const { user } = useAuthenticatedUser();
	const { toggleCompleted } = useUpdateActionItem();
	const { deleteActionItem } = useDeleteActionItem();
	const statusIcon = (
		<CheckMarkBubble
			isComplete={actionItem.status === actionItemStatus.complete}
			toggleComplete={() => toggleCompleted(actionItem, user.id)}
		/>
	);

	const content = (
		<>
			<div
				className="flex flex-col justify-start gap-2 flex-grow"
				onDoubleClick={() => setCurrentActionItem(actionItem, false)}
			>
				<ActionItemTitleDisplay title={actionItem.title} completed={!!actionItem.completedAt} />
				<ActionItemDateDisplay dueAt={actionItem.dueAt} completedAt={actionItem.completedAt} />
			</div>
			<div className="flex items-center gap-2 justify-center self-start min-w-fit">
				<AdminAssigneeControls
					assignedEntity={{ entityType: "actionItem", entityId: actionItem.id }}
					currentAssignee={actionItem.assigneeId}
				/>
				<ToolContextMenu>
					<ToolContextMenuItem
						onClick={() =>
							navigator.clipboard.writeText(`${window.location}&actionItemId=${actionItem.id}`)
						}
						label="Copy Link"
					/>
					<ToolContextMenuItem
						onClick={() => {
							setCurrentActionItem(actionItem, true);
						}}
						label="Edit"
					/>
					<ToolContextMenuItem
						onClick={() => {
							deleteActionItem({
								actionItemId: actionItem.id,
								relation: {
									relationType: actionItem.relationType,
									relationId: actionItem.relationId,
								} as Relations.ActionItems,
							});
						}}
						label="Delete"
					/>
				</ToolContextMenu>
			</div>
		</>
	);

	return (
		<AdminActionItemLayout
			statusIcon={statusIcon}
			content={content}
			containerClassName={containerClassName}
		/>
	);
};
