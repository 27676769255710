import { InfoCard } from "../../../../components/ui/InfoCard";

export const WetSignatureDisclosureCard = () => {
	return (
		<InfoCard>
			By selecting "Wet Signatures", you are responsible for collecting signatures from indemnitors,
			sending a scanned copy of the signed indemnity agreement to your inRev underwriter, and
			mailing the hard copy of the signed indemnity agreement to inRev
		</InfoCard>
	);
};
