import { zodResolver } from "@hookform/resolvers/zod";
import {
	AgencyId,
	BidBondAmountType,
	BidSuretyBondClosedReason,
	ContractDamage,
	ContractHazard,
	ContractSuretyType,
	Dtos,
	FileType,
	FinalContractSuretyBondClosedReason,
	FinalContractSuretyBondFormType,
	NAICSCode,
	SuretyBondFormType,
	SuretyBondIssuanceMethod,
	SuretyBondStatus,
	USStateOrTerritory,
	bidSuretyBondClosedReasonDef,
	finalContractSuretyBondClosedReasonDef,
} from "@inrev/common";
import { mergeDeepRight } from "rambda";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { LoadingModal } from "../../../components/layout/LoadingModal";
import { FormItem } from "../../../components/layout/form/FormItem";
import { FormItemGroup } from "../../../components/layout/form/FormItemGroup";
import { FormRow } from "../../../components/layout/form/FormRow";
import { FormSchemaInclude } from "../../../components/layout/form/FormSchemaInclude";
import { FormSection } from "../../../components/layout/form/FormSection";
import { Button } from "../../../components/ui/Button";
import { LocalFileUpload } from "../../../components/ui/LocalFileUpload";
import { FormCheckboxGroup } from "../../../components/ui/form/FormCheckboxGroup";
import { FormCurrencyInput } from "../../../components/ui/form/FormCurrencyInput";
import { FormDatePicker } from "../../../components/ui/form/FormDatePicker";
import { FormDropdown } from "../../../components/ui/form/FormDropdown";
import { FormInput } from "../../../components/ui/form/FormInput";
import { FormItemLabel } from "../../../components/ui/form/FormItemLabel";
import { FormNAICSCodeSelect } from "../../../components/ui/form/FormNAICSCodeSelect";
import { FormNumberInput } from "../../../components/ui/form/FormNumberInput";
import { FormPercentInput } from "../../../components/ui/form/FormPercentInput";
import { FormQuestionLabel } from "../../../components/ui/form/FormQuestionLabel";
import { FormYesNo } from "../../../components/ui/form/FormYesNo";
import {
	contractDamageOptions,
	contractHazardOptions,
	obligeeRoleOptions,
	usStateOrTerritoryOptions,
} from "../../../constants";
import {
	useAdminFetchAgencyAccountPreviews,
	useAdminFetchAgencyPreviews,
} from "../../../domain/admin/agency/api";
import { useAdminFetchAgentPreviewsForAgency } from "../../../domain/admin/agent/api";
import { useImportBond } from "../../../domain/admin/bond/api";
import { useFetchBondPreviews } from "../../../domain/shared/previews/api";
import { LocallyUploadedFile } from "../../../types";
import { checkLocalStorageAvailable, formatName } from "../../../utils";
import { stripEmptyResolver, useAutoSave } from "../../../utils/form";

type AdminImportBondViewFormData = {
	suretyType: "contract";
	contractSuretyType: ContractSuretyType | "";
	agencyId: string | "";
	agentId: string | "";
	rate: string | "";
	premium: string | "";
	agencyCommissionPercentage: string | "";
	bond: {
		status: SuretyBondStatus;
		number: string | "";
		bondAmount: string | "";
		carrierName: string | "";
		suretyAccountId: string | "";
		closedAt: string | "";
		continuous: boolean | "";
		cancellable: boolean | "";
		notes: string[];
		sfaaCode: string | "";
		companyContactId: string | "";
		bondCreatedFromId: string | "";
		data: {
			closedReason: FinalContractSuretyBondClosedReason | BidSuretyBondClosedReason | "";
			contractAmount: string | "";
			contractDate: string | "";
			expectedCompletionDate: string | "";
			actualCompletionDate: string | "";
			actualContractAmount: string | "";
			bidAmount: string | "";
			bidDate: string | "";
			finalBondFormType: FinalContractSuretyBondFormType | "";
			finalBondContingentRate: string | "";
			finalBondContingentPremium: string | "";
			tListingRequired: boolean | "";
			isFinalBondCompletionBond: boolean | "";
			project: {
				description: string | "";
				address: {
					city: string | "";
					state: USStateOrTerritory | "";
				};
				scopeOfWork: NAICSCode[];
				monthsToComplete: string | "";
				contractWarrantyMonths: string | "";
				contractHazards: ContractHazard[];
				contractDamages: ContractDamage[];
			};
		};
		obligees: [
			{
				name: string | "";
				address: {
					street: string | "";
					city: string | "";
					state: USStateOrTerritory | "";
					zip: string | "";
				};
				role: "project_owner" | "lender" | "gc" | "other" | "";
			},
		];
	};
	bondForm: {
		issuanceMethod: SuretyBondIssuanceMethod | "";
		effectiveDate: string | "";
		data: {
			bondFormType: SuretyBondFormType | "";
			contractDescription: string | "";
			bidBondAmountType: BidBondAmountType | "";
			bondAmountPercentOfBid: string | "";
		};
	};
	issuedBondFormFile?: LocallyUploadedFile<"filled_bond_form">;
};

const getDefaultFormData = (): AdminImportBondViewFormData => {
	const defaultFormData: AdminImportBondViewFormData = {
		suretyType: "contract",
		contractSuretyType: "",
		agencyId: "",
		agentId: "",
		rate: "",
		premium: "",
		agencyCommissionPercentage: "",
		bond: {
			status: "open",
			number: "",
			bondAmount: "",
			carrierName: "",
			suretyAccountId: "",
			closedAt: "",
			continuous: true,
			cancellable: false,
			notes: [],
			sfaaCode: "",
			companyContactId: "",
			bondCreatedFromId: "",
			data: {
				closedReason: "",
				contractAmount: "",
				contractDate: "",
				expectedCompletionDate: "",
				actualCompletionDate: "",
				actualContractAmount: "",
				bidAmount: "",
				bidDate: "",
				finalBondFormType: "",
				finalBondContingentRate: "",
				finalBondContingentPremium: "",
				tListingRequired: "",
				isFinalBondCompletionBond: false,
				project: {
					description: "",
					address: {
						city: "",
						state: "",
					},
					scopeOfWork: [],
					monthsToComplete: "12",
					contractWarrantyMonths: "12",
					contractHazards: [],
					contractDamages: [],
				},
			},
			obligees: [
				{
					name: "",
					address: {
						street: "",
						city: "",
						state: "",
						zip: "",
					},
					role: "",
				},
			],
		},
		bondForm: {
			issuanceMethod: "",
			effectiveDate: "",
			data: {
				bondFormType: "",
				contractDescription: "",
				bidBondAmountType: "",
				bondAmountPercentOfBid: "",
			},
		},
		issuedBondFormFile: undefined,
	};

	if (checkLocalStorageAvailable()) {
		return mergeDeepRight(
			defaultFormData,
			JSON.parse(window.localStorage.getItem("inrev_import_bond") ?? "{}"),
		);
	}

	return defaultFormData;
};

export const AdminImportBondView = () => {
	const navigate = useNavigate();
	const { bondPreviews } = useFetchBondPreviews();
	const { importBond, importBondLoading, importedBondId } = useImportBond();
	const formMethods = useForm<
		AdminImportBondViewFormData,
		any,
		Dtos.Admin.SuretyBond.Import.Request
	>({
		defaultValues: getDefaultFormData(),
		reValidateMode: "onBlur",
		resolver: stripEmptyResolver(zodResolver(Dtos.Admin.SuretyBond.Import.Request.schema)),
	});
	useAutoSave(
		(data) => {
			if (checkLocalStorageAvailable()) {
				window.localStorage.setItem("inrev_import_bond", JSON.stringify(data));
			}
		},
		(data) => {
			if (checkLocalStorageAvailable()) {
				window.localStorage.setItem("inrev_import_bond", JSON.stringify(data));
			}
		},
		formMethods,
	);

	const contractSuretyType = formMethods.watch("contractSuretyType");
	const agencyId = formMethods.watch("agencyId") as AgencyId | undefined;
	const suretyAccountId = formMethods.watch("bond.suretyAccountId");
	const principalCompanyContactId = formMethods.watch("bond.companyContactId");
	const bidBondId = formMethods.watch("bond.bondCreatedFromId");
	const filteredBondPreviews = useMemo(() => {
		if (contractSuretyType === "bid_to_final" && suretyAccountId !== "" && !!bondPreviews) {
			return bondPreviews.filter(
				(bond) =>
					bond.principalCompanyContactId === principalCompanyContactId && bond.type === "bid",
			);
		}
	}, [contractSuretyType, principalCompanyContactId, bondPreviews]);
	const bidBondPreview = useMemo(() => {
		if (bidBondId === "") return undefined;
		return filteredBondPreviews?.find((preview) => preview.id === bidBondId);
	}, [filteredBondPreviews, bidBondId]);
	const status = formMethods.watch("bond.status");
	const contractAmount = formMethods.watch("bond.data.contractAmount");
	const bidAmount = formMethods.watch("bond.data.bidAmount");
	const rate = formMethods.watch("rate");
	const finalBondContingentRate = formMethods.watch("bond.data.finalBondContingentRate");
	const premium = formMethods.watch("premium");
	const finalBondContingentPremium = formMethods.watch("bond.data.finalBondContingentPremium");
	const issuedBondFormFile = formMethods.watch("issuedBondFormFile");

	const { agencyPreviews, agencyPreviewsLoading } = useAdminFetchAgencyPreviews();
	const { agentPreviews, agentPreviewsLoading } = useAdminFetchAgentPreviewsForAgency(
		agencyId === "" ? undefined : agencyId,
	);
	const { agencyAccountPreviews, agencyAccountPreviewsLoading } =
		useAdminFetchAgencyAccountPreviews(agencyId === "" ? undefined : agencyId);

	const suretyAccount = useMemo(() => {
		if (!!!agencyAccountPreviews) return undefined;
		return agencyAccountPreviews.find((account) => account.id === suretyAccountId);
	}, [agencyAccountPreviews, suretyAccountId]);

	useEffect(() => {
		if (!!importedBondId) {
			if (checkLocalStorageAvailable()) {
				window.localStorage.removeItem("inrev_import_bond");
			}
			navigate(`/bonds/${importedBondId}`);
		}
	}, [importedBondId]);

	useEffect(() => {
		formMethods.setValue("agentId", "", { shouldDirty: true });
		formMethods.setValue("bond.suretyAccountId", "", { shouldDirty: true });
	}, [agencyId]);

	useEffect(() => {
		formMethods.setValue("bond.companyContactId", "", { shouldDirty: true });
	}, [suretyAccountId]);

	useEffect(() => {
		formMethods.setValue("bond.bondCreatedFromId", "", { shouldDirty: true });
	}, [principalCompanyContactId]);

	useEffect(() => {
		if (rate !== "") {
			if (contractAmount !== "") {
				formMethods.setValue(
					"premium",
					(parseFloat(rate) * parseFloat(contractAmount)).toString(),
					{ shouldDirty: true },
				);
			} else if (premium !== "") {
				formMethods.setValue("premium", "", { shouldDirty: true });
			}
		}
	}, [rate]);

	useEffect(() => {
		if (finalBondContingentRate !== "") {
			if (bidAmount !== "") {
				formMethods.setValue(
					"bond.data.finalBondContingentPremium",
					(parseFloat(finalBondContingentRate) * parseFloat(bidAmount)).toString(),
					{ shouldDirty: true },
				);
			} else if (finalBondContingentPremium !== "") {
				formMethods.setValue("bond.data.finalBondContingentPremium", "", { shouldDirty: true });
			}
		}
	}, [finalBondContingentRate]);

	useEffect(() => {
		if (contractSuretyType === "bid") {
			formMethods.setValue("rate", "0", { shouldDirty: true });
			formMethods.setValue("premium", "0", { shouldDirty: true });
			formMethods.setValue("bond.data.finalBondContingentRate", "", { shouldDirty: true });
			formMethods.setValue("bond.data.finalBondContingentPremium", "", { shouldDirty: true });
			formMethods.setValue("bondForm.data.bondFormType", "bid", { shouldDirty: true });
		}
		if (contractSuretyType !== "bid") {
			formMethods.setValue("bondForm.data.bondFormType", "", { shouldDirty: true });
		}
		if (contractSuretyType !== "final") {
			formMethods.setValue("rate", "", { shouldDirty: true });
			formMethods.setValue("premium", "", { shouldDirty: true });
		}
	}, [contractSuretyType]);

	useEffect(() => {
		if (!!bidBondPreview) {
			formMethods.setValue("bond.carrierName", bidBondPreview.carrierName, { shouldDirty: true });
		}
	}, [bidBondPreview]);

	const handleSubmit = (data: Dtos.Admin.SuretyBond.Import.Request) => {
		importBond(data);
	};

	if (agencyPreviewsLoading || !!!agencyPreviews) return <LoadingModal />;
	return (
		<div className="flex justify-center w-full h-full overflow-y-auto pb-[75px]">
			<div className="flex flex-col gap-y-[25px] w-[900px] max-w-[900px] mt-[50px] mx-[40px] shrink-0 h-fit">
				<div className="flex justify-between items-center gap-x-[20px]">
					<span className="text-[26px] font-bold text-gray-800">Import Bond</span>
					<span
						className="text-[12px] text-gray-400 cursor-pointer hover:underline"
						onClick={() => {
							if (checkLocalStorageAvailable()) {
								window.localStorage.removeItem("inrev_import_bond");
							}
							formMethods.reset(getDefaultFormData());
						}}
					>
						Clear
					</span>
				</div>
				<form className="flex flex-col gap-[45px]">
					<FormItemGroup condensed>
						<FormItem condensed>
							<FormItemLabel condensed>Type</FormItemLabel>
							<FormDropdown
								control={formMethods.control}
								name="contractSuretyType"
								options={[
									{ label: "Bid", value: ContractSuretyType.bid },
									{ label: "Final (From Bid)", value: ContractSuretyType.bid_to_final },
									{ label: "Final", value: ContractSuretyType.final },
								]}
								placeholder="Select one"
								condensed
							/>
						</FormItem>
						{contractSuretyType !== "" && (
							<>
								<FormRow>
									<FormItem condensed>
										<FormItemLabel condensed>Agency</FormItemLabel>
										<FormDropdown
											control={formMethods.control}
											name="agencyId"
											options={agencyPreviews.map((agency) => ({
												label: agency.displayName,
												value: agency.id,
											}))}
											placeholder="Select one"
											condensed
											optionsClassName="max-h-[35vh]"
										/>
									</FormItem>
									<FormItem condensed>
										<FormItemLabel condensed>Agent</FormItemLabel>
										<FormDropdown
											control={formMethods.control}
											name="agentId"
											options={
												agentPreviews?.map((agent) => ({
													label: formatName(agent),
													value: agent.id,
												})) ?? []
											}
											placeholder="Select one"
											readonly={agentPreviewsLoading || !!!agentPreviews}
											condensed
											optionsClassName="max-h-[35vh]"
										/>
									</FormItem>
								</FormRow>
								<FormRow>
									<FormItem condensed>
										<FormItemLabel condensed>Account</FormItemLabel>
										<FormDropdown
											control={formMethods.control}
											name="bond.suretyAccountId"
											options={
												agencyAccountPreviews?.map((account) => ({
													label: account.displayName ?? "??",
													value: account.id,
												})) ?? []
											}
											placeholder="Select one"
											condensed
											readonly={agencyAccountPreviewsLoading || !!!agencyAccountPreviews}
											optionsClassName="max-h-[35vh]"
										/>
									</FormItem>
									<FormItem condensed>
										<FormItemLabel condensed>Principal</FormItemLabel>
										<FormDropdown
											control={formMethods.control}
											name="bond.companyContactId"
											options={
												suretyAccount?.companies.map((company) => ({
													label: company.name,
													value: company.id,
												})) ?? []
											}
											placeholder="Select one"
											readonly={agencyAccountPreviewsLoading || !!!agencyAccountPreviews}
											condensed
											optionsClassName="max-h-[35vh]"
										/>
									</FormItem>
								</FormRow>
								<FormItem condensed schemaInclude={contractSuretyType === "bid_to_final"}>
									<FormItemLabel condensed>Bid Bond</FormItemLabel>
									<FormDropdown
										control={formMethods.control}
										name="bond.bondCreatedFromId"
										options={
											filteredBondPreviews?.map((bond) => ({
												label: bond.number,
												value: bond.id,
											})) ?? []
										}
										placeholder={
											principalCompanyContactId === ""
												? "Select a principal before selecting a bid bond"
												: !!!filteredBondPreviews || filteredBondPreviews.length === 0
													? "0 Bid Bonds"
													: "Select one"
										}
										condensed
									/>
								</FormItem>
								<FormRow>
									<FormItem>
										<FormItemLabel>Carrier</FormItemLabel>
										<FormDropdown
											control={formMethods.control}
											name="bond.carrierName"
											options={[
												{ label: "Accelerant", value: "accelerant" },
												{ label: "Lexington National", value: "lexington-national-for-accelerant" },
											]}
											placeholder="Select one"
											condensed
										/>
									</FormItem>
									<FormItem condensed>
										<FormItemLabel condensed>Bond Number</FormItemLabel>
										<FormInput control={formMethods.control} name="bond.number" condensed />
									</FormItem>
									<FormItem condensed>
										<FormItemLabel condensed>SFAA Code</FormItemLabel>
										<FormNumberInput
											control={formMethods.control}
											name="bond.sfaaCode"
											min={100}
											max={999}
											maxLength={3}
											condensed
										/>
									</FormItem>
									<FormItem condensed>
										<FormItemLabel condensed>Status</FormItemLabel>
										<FormDropdown
											control={formMethods.control}
											name="bond.status"
											options={[
												{ label: "Open", value: SuretyBondStatus.open },
												{ label: "Closed", value: SuretyBondStatus.closed },
											]}
											placeholder="Select one"
											condensed
										/>
									</FormItem>
								</FormRow>
								<FormRow schemaInclude={status === "closed"}>
									<FormItem condensed>
										<FormItemLabel condensed>Closed Reason</FormItemLabel>
										<FormDropdown
											control={formMethods.control}
											name="bond.data.closedReason"
											options={(() => {
												if (contractSuretyType === "bid")
													return bidSuretyBondClosedReasonDef.map((reason) => ({
														label: reason,
														value: reason,
													}));
												return finalContractSuretyBondClosedReasonDef.map((reason) => ({
													label: reason,
													value: reason,
												}));
											})()}
											placeholder="Select one"
											condensed
										/>
									</FormItem>
									<FormItem condensed>
										<FormItemLabel condensed>Closed Date</FormItemLabel>
										<FormDatePicker control={formMethods.control} name="bond.closedAt" condensed />
									</FormItem>
								</FormRow>
								<FormRow>
									<FormItem condensed schemaInclude={contractSuretyType === "bid"}>
										<FormItemLabel condensed>Bid Amount</FormItemLabel>
										<FormCurrencyInput
											control={formMethods.control}
											name="bond.data.bidAmount"
											condensed
										/>
									</FormItem>
									<FormItem condensed>
										<FormItemLabel condensed>Bond Amount</FormItemLabel>
										<FormCurrencyInput
											control={formMethods.control}
											name="bond.bondAmount"
											condensed
										/>
									</FormItem>
									<FormItem condensed schemaInclude={contractSuretyType === "bid"}>
										<FormItemLabel condensed>Bond Amount %</FormItemLabel>
										<FormPercentInput
											control={formMethods.control}
											name="bondForm.data.bondAmountPercentOfBid"
											condensed
										/>
									</FormItem>
									<FormItem condensed schemaInclude={contractSuretyType === "bid"}>
										<FormItemLabel condensed>Bond Amount on Bond Form</FormItemLabel>
										<FormDropdown
											control={formMethods.control}
											name="bondForm.data.bidBondAmountType"
											options={[
												{ label: "Percent", value: "percent" },
												{ label: "Dollars", value: "dollars" },
											]}
											condensed
										/>
									</FormItem>
									<FormItem condensed schemaInclude={contractSuretyType !== "bid"}>
										<FormItemLabel condensed>Contract Amount</FormItemLabel>
										<FormCurrencyInput
											control={formMethods.control}
											name="bond.data.contractAmount"
											condensed
										/>
									</FormItem>
									<FormItem
										condensed
										schemaInclude={contractSuretyType !== "bid" && status === "closed"}
									>
										<FormItemLabel condensed>Contract Amount At Close</FormItemLabel>
										<FormCurrencyInput
											control={formMethods.control}
											name="bond.data.actualContractAmount"
											condensed
										/>
									</FormItem>
								</FormRow>
								<FormRow schemaInclude={contractSuretyType === "bid"}>
									<FormItem condensed>
										<FormItemLabel condensed>Bid Date</FormItemLabel>
										<FormDatePicker
											control={formMethods.control}
											name="bond.data.bidDate"
											condensed
										/>
									</FormItem>
									<FormItem condensed>
										<FormItemLabel condensed>T-Listing Required</FormItemLabel>
										<FormYesNo control={formMethods.control} name="bond.data.tListingRequired" />
									</FormItem>
									<FormItem condensed>
										<FormItemLabel condensed>Final Bond Form Type</FormItemLabel>
										<FormDropdown
											control={formMethods.control}
											name="bond.data.finalBondFormType"
											options={[
												{ label: "Performance & Payment", value: "pnp" },
												{ label: "Performance", value: "performance" },
												{ label: "Payment", value: "payment" },
											]}
											placeholder="Select one"
											condensed
										/>
									</FormItem>
									<FormItem condensed>
										<FormItemLabel condensed>Final Bond = Completion Bond</FormItemLabel>
										<FormYesNo
											control={formMethods.control}
											name="bond.data.isFinalBondCompletionBond"
										/>
									</FormItem>
								</FormRow>
								<FormRow>
									<FormItem condensed schemaInclude={contractSuretyType === "bid"}>
										<FormItemLabel condensed>Final Bond Rate</FormItemLabel>
										<FormPercentInput
											control={formMethods.control}
											name="bond.data.finalBondContingentRate"
											precision={4}
											condensed
										/>
									</FormItem>
									<FormItem condensed schemaInclude={contractSuretyType !== "bid"}>
										<FormItemLabel condensed>Rate</FormItemLabel>
										<FormPercentInput
											control={formMethods.control}
											name="rate"
											precision={4}
											condensed
										/>
									</FormItem>
									<FormItem condensed schemaInclude={contractSuretyType === "bid"}>
										<FormItemLabel condensed>Final Bond Premium</FormItemLabel>
										<FormCurrencyInput
											control={formMethods.control}
											name="bond.data.finalBondContingentPremium"
											condensed
										/>
									</FormItem>
									<FormItem condensed schemaInclude={contractSuretyType !== "bid"}>
										<FormItemLabel condensed>Premium</FormItemLabel>
										<FormCurrencyInput control={formMethods.control} name="premium" condensed />
									</FormItem>
									<FormItem>
										<FormItemLabel condensed>
											{contractSuretyType === "bid" ? "Final Bond Commission %" : "Commission %"}
										</FormItemLabel>
										<FormPercentInput
											control={formMethods.control}
											name="agencyCommissionPercentage"
											precision={2}
											condensed
										/>
									</FormItem>
								</FormRow>
							</>
						)}
					</FormItemGroup>
					{contractSuretyType !== "" && (
						<>
							<FormSection header="Bond Form">
								<FormItemGroup condensed>
									<FormRow>
										<FormItem condensed>
											<FormItemLabel condensed>Issuance Method</FormItemLabel>
											<FormDropdown
												control={formMethods.control}
												name="bondForm.issuanceMethod"
												options={[
													{ label: "Electronic", value: SuretyBondIssuanceMethod.electronic },
													{ label: "Physical", value: SuretyBondIssuanceMethod.physical },
													{ label: "Agent", value: SuretyBondIssuanceMethod.agent },
												]}
												placeholder="Select one"
												condensed
											/>
										</FormItem>
										<FormItem condensed schemaInclude={contractSuretyType !== "bid"}>
											<FormItemLabel condensed>Bond Form Type</FormItemLabel>
											<FormDropdown
												control={formMethods.control}
												name="bondForm.data.bondFormType"
												options={[
													{ label: "Performance & Payment", value: "pnp" },
													{ label: "Performance", value: "performance" },
													{ label: "Payment", value: "payment" },
												]}
												placeholder="Select one"
												condensed
											/>
										</FormItem>
										<FormItem condensed>
											<FormItemLabel condensed>Effective Date</FormItemLabel>
											<FormDatePicker
												control={formMethods.control}
												name="bondForm.effectiveDate"
												condensed
											/>
										</FormItem>
									</FormRow>
									<FormItem condensed>
										<FormItemLabel condensed>Description</FormItemLabel>
										<FormInput
											control={formMethods.control}
											name="bondForm.data.contractDescription"
											condensed
										/>
									</FormItem>
									<FormRow>
										<FormItem condensed>
											<FormItemLabel condensed>Obligee Name</FormItemLabel>
											<FormInput
												control={formMethods.control}
												name="bond.obligees.0.name"
												condensed
											/>
										</FormItem>
										<FormItem condensed>
											<FormItemLabel condensed>Obligee Role</FormItemLabel>
											<FormDropdown
												control={formMethods.control}
												name="bond.obligees.0.role"
												options={obligeeRoleOptions}
												placeholder="Select one"
												condensed
												optionsClassName="max-h-[35vh]"
											/>
										</FormItem>
									</FormRow>
									<FormItem condensed>
										<FormItemLabel condensed>Obligee Street</FormItemLabel>
										<FormInput
											control={formMethods.control}
											name="bond.obligees.0.address.street"
											condensed
										/>
									</FormItem>
									<FormRow>
										<FormItem condensed>
											<FormItemLabel condensed>Obligee City</FormItemLabel>
											<FormInput
												control={formMethods.control}
												name="bond.obligees.0.address.city"
												condensed
											/>
										</FormItem>
										<FormItem condensed>
											<FormItemLabel condensed>Obligee State</FormItemLabel>
											<FormDropdown
												control={formMethods.control}
												name="bond.obligees.0.address.state"
												options={usStateOrTerritoryOptions}
												placeholder="Select one"
												condensed
												optionsClassName="max-h-[35vh]"
											/>
										</FormItem>
										<FormItem condensed>
											<FormItemLabel condensed>Obligee Zip</FormItemLabel>
											<FormNumberInput
												control={formMethods.control}
												name="bond.obligees.0.address.zip"
												condensed
												maxLength={5}
											/>
										</FormItem>
									</FormRow>
									<FormItem condensed>
										<FormItemLabel>Issued Bond Form</FormItemLabel>
										<LocalFileUpload
											value={issuedBondFormFile ? [issuedBondFormFile] : []}
											onChange={(files) => formMethods.setValue("issuedBondFormFile", files[0])}
											allowDelete={true}
											allowedTypesAndLabels={{
												[FileType.filled_bond_form]: "Filled Bond Form",
											}}
											allowedExtensions={["pdf"]}
											requiredTypes={[FileType.filled_bond_form]}
											maxTypesPerFile={1}
											maxFiles={1}
											hideChecklist={true}
											showErrors={formMethods.formState.errors.issuedBondFormFile !== undefined}
										/>
									</FormItem>
								</FormItemGroup>
							</FormSection>
							<FormSection header="Project">
								<FormItemGroup condensed>
									<FormSchemaInclude schemaInclude={contractSuretyType !== "bid"}>
										<FormRow>
											<FormItem condensed>
												<FormItemLabel condensed>Contract Date</FormItemLabel>
												<FormDatePicker
													control={formMethods.control}
													name="bond.data.contractDate"
													condensed
												/>
											</FormItem>
											<FormItem condensed>
												<FormItemLabel condensed>Expected Completion Date</FormItemLabel>
												<FormDatePicker
													control={formMethods.control}
													name="bond.data.expectedCompletionDate"
													condensed
												/>
											</FormItem>
											<FormItem condensed schemaInclude={status === "closed"}>
												<FormItemLabel condensed>Actual Completion Date</FormItemLabel>
												<FormDatePicker
													control={formMethods.control}
													name="bond.data.actualCompletionDate"
													condensed
												/>
											</FormItem>
										</FormRow>
									</FormSchemaInclude>
									<FormItemGroup condensed schemaInclude={contractSuretyType !== "bid_to_final"}>
										<FormItem condensed>
											<FormItemLabel condensed>Description</FormItemLabel>
											<FormInput
												control={formMethods.control}
												name="bond.data.project.description"
												condensed
											/>
										</FormItem>
										<FormRow>
											<FormItem condensed>
												<FormItemLabel condensed>City</FormItemLabel>
												<FormInput
													control={formMethods.control}
													name="bond.data.project.address.city"
													condensed
												/>
											</FormItem>
											<FormItem condensed>
												<FormItemLabel condensed>State</FormItemLabel>
												<FormDropdown
													control={formMethods.control}
													name="bond.data.project.address.state"
													options={usStateOrTerritoryOptions}
													placeholder="Select one"
													condensed
													optionsClassName="max-h-[35vh]"
												/>
											</FormItem>
										</FormRow>
										<FormItem condensed>
											<FormItemLabel condensed>Scope of work</FormItemLabel>
											<FormNAICSCodeSelect
												control={formMethods.control}
												name="bond.data.project.scopeOfWork"
												selectText="Select NAICS Codes"
												editText="Edit NAICS Codes"
												searchPlaceholder="Search scopes of work..."
												title="Select scopes of work"
											/>
										</FormItem>
										<FormRow>
											<FormItem condensed>
												<FormItemLabel condensed>Months To Complete</FormItemLabel>
												<FormNumberInput
													control={formMethods.control}
													name="bond.data.project.monthsToComplete"
													min={1}
													condensed
												/>
											</FormItem>
											<FormItem condensed>
												<FormItemLabel condensed>Contract Warranty Months</FormItemLabel>
												<FormNumberInput
													control={formMethods.control}
													name="bond.data.project.contractWarrantyMonths"
													min={0}
													condensed
												/>
											</FormItem>
										</FormRow>
										<FormItem condensed>
											<FormQuestionLabel subLabel="Check all that apply">
												Does the contract include any of the following damages?
											</FormQuestionLabel>
											<FormCheckboxGroup
												control={formMethods.control}
												name="bond.data.project.contractDamages"
												options={contractDamageOptions}
												className="bg-gray-50 border border-gray-300"
											/>
										</FormItem>
										<FormItem condensed>
											<FormQuestionLabel subLabel="Check all that apply">
												Do any of the following apply to the contract?
											</FormQuestionLabel>
											<FormCheckboxGroup
												control={formMethods.control}
												name="bond.data.project.contractHazards"
												options={contractHazardOptions}
												className="bg-gray-50 border border-gray-300"
											/>
										</FormItem>
									</FormItemGroup>
								</FormItemGroup>
							</FormSection>
						</>
					)}
					<div className="flex justify-end">
						<Button
							color="light-blue"
							filled
							onClick={formMethods.handleSubmit(handleSubmit)}
							disabled={contractSuretyType === ""}
						>
							Import Bond
						</Button>
					</div>
				</form>
			</div>
			{importBondLoading && <LoadingModal />}
		</div>
	);
};
