import { z } from 'zod';
import { usStateOrTerritorySchema, naicsCodeSchema, contractHazardSchema, contractDamageSchema } from '../../../../../enums.mjs';
import { city } from '../../../../opaque.mjs';
import { safeNumberSchema } from '../../../../utils.mjs';

var Underwriting;
(function (Underwriting) {
    (function (Quote) {
        let Input;
        (function (Input) {
            Input.schema = z.object({
                completionBond: z.boolean().nullish(),
            });
        })(Input = Quote.Input || (Quote.Input = {}));
        Quote.baseSchema = z.object({
            modelVersion: z.string(),
            input: Input.schema,
        });
    })(Underwriting.Quote || (Underwriting.Quote = {}));
    (function (Project) {
        let Input;
        (function (Input) {
            Input.schema = z.object({
                description: z.string(),
                address: z.object({
                    city: city,
                    state: usStateOrTerritorySchema,
                }),
                scopeOfWork: z.array(naicsCodeSchema).min(1),
                monthsToComplete: safeNumberSchema.min(1),
                contractWarrantyMonths: safeNumberSchema.nonnegative(),
                contractHazards: z.array(contractHazardSchema),
                contractDamages: z.array(contractDamageSchema),
            });
        })(Input = Project.Input || (Project.Input = {}));
        let Calculations;
        (function (Calculations) {
            Calculations.schema = z.object({
                scopeFit: z.boolean(),
                projectMonthsToCompletePlusContractWarrantyMonths: z.number(),
            });
        })(Calculations = Project.Calculations || (Project.Calculations = {}));
        Project.schema = z.object({
            modelVersion: z.string(),
            input: Input.schema,
            calculations: Calculations.schema,
        });
    })(Underwriting.Project || (Underwriting.Project = {}));
})(Underwriting || (Underwriting = {}));

export { Underwriting };
