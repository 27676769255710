import { MainNav } from "../../../components/ui/MainNav";

export const AdminMainNav = () => (
	<MainNav
		label="Admin"
		items={{
			dashboard: import.meta.env.VITE_TOOLS_ADMIN_ACTION_ITEMS_ENABLED === "true",
			requests: true,
			bonds: true,
			accounts: true,
		}}
	/>
);
