import { z } from 'zod';
import { userId, suretyAccountId, suretyBondId, suretyQuoteId, agencyId, actionItemId } from '../../types/opaque.mjs';
import '../../types/utils.mjs';
import '../../types/agency/carrier-agency-relationship.types.mjs';
import '../../types/credit-report/company-credit-report.mjs';
import '../../types/credit-report/individual-credit-report.mjs';
import '../../types/payment/payment-template.mjs';
import '../../types/surety/account/surety-account.types.mjs';
import '../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../types/surety/bond/surety-bond.types.mjs';
import '../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../types/surety/work-in-progress-statement/surety-work-in-progress-statement.mjs';
import '../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../types/surety/indemnity/indemnity-agreement.mjs';
import '../../types/user/user.types.mjs';
import '../../types/entityRelation/entity-relation.mjs';

var Assign;
(function (Assign) {
    (function (Request) {
        const baseSchema = z.object({
            underwriterUserId: userId,
        });
        const accountSchema = baseSchema.extend({
            entityType: z.literal("account"),
            entityId: suretyAccountId,
            reassignAll: z.boolean(),
        });
        const bondSchema = baseSchema.extend({
            entityType: z.literal("bond"),
            entityId: suretyBondId,
        });
        const quoteSchema = baseSchema.extend({
            entityType: z.literal("quote"),
            entityId: suretyQuoteId,
        });
        const agencySchema = baseSchema.extend({
            entityType: z.literal("agency"),
            entityId: agencyId,
        });
        const actionItemSchema = baseSchema.extend({
            entityType: z.literal("actionItem"),
            entityId: actionItemId,
        });
        Request.schema = z.discriminatedUnion("entityType", [
            accountSchema,
            bondSchema,
            quoteSchema,
            agencySchema,
            actionItemSchema,
        ]);
    })(Assign.Request || (Assign.Request = {}));
})(Assign || (Assign = {}));

export { Assign };
