import { z } from 'zod';
import { usStateOrTerritorySchema } from '../../enums.mjs';
import { streetAddress, city, zipCode, phoneNumber, fein, fsr } from '../opaque.mjs';
import { currencySchema } from '../utils.mjs';

var Vendor;
(function (Vendor) {
    Vendor.experianExecutiveElementsSchema = z.object({
        bankruptcyCount: z.number().nullish(),
        earliestBankruptcyDate: z.string().nullish(),
        mostRecentBankruptcyDate: z.string().nullish(),
        taxLienCount: z.number().nullish(),
        earliestTaxLienDate: z.string().nullish(),
        mostRecentTaxLienDate: z.string().nullish(),
        judgmentCount: z.number().nullish(),
        earliestJudgmentDate: z.string().nullish(),
        mostRecentJudgmentDate: z.string().nullish(),
        collectionCount: z.number().nullish(),
        legalBalance: z.number().nullish(),
        uccFilings: z.number().nullish(),
        uccDerogatoryCount: z.number().nullish(),
        currentAccountBalance: z.number().nullish(),
        currentTradelineCount: z.number().nullish(),
        monthlyAverageDbt: z.number().nullish(),
        highestDbt6Months: z.number().nullish(),
        highestDbt5Quarters: z.number().nullish(),
        activeTradelineCount: z.number().nullish(),
        allTradelineBalance: z.number().nullish(),
        allTradelineCount: z.number().nullish(),
        averageBalance5Quarters: z.number().nullish(),
        singleLineHighCredit: z.number().nullish(),
        lowBalance6Months: z.number().nullish(),
        highBalance6Months: z.number().nullish(),
        earliestCollectionDate: z.string().nullish(),
        mostRecentCollectionDate: z.string().nullish(),
        currentDbt: z.number().nullish(),
        yearOfIncorporation: z.string().nullish(),
        taxId: z.string().nullish(),
        earliestUccDate: z.string().nullish(),
        mostRecentUccDate: z.string().nullish(),
        bankruptcyIndicator: z.boolean().nullish(),
        judgmentIndicator: z.boolean().nullish(),
        taxLienIndicator: z.boolean().nullish(),
        inquiriesPrevious9Months: z.number().nullish(),
        lienBalance: z.number().nullish(),
        judgmentBalance: z.number().nullish(),
    });
    Vendor.experianFSRFactorSchema = z.object({
        code: z.string(),
        definition: z.string(),
    });
    Vendor.experianFSRTrendSchema = z.object({
        quarter: z.string(),
        score: z.number(),
    });
    Vendor.reportSchema = z.object({
        name: z.string().nullish(),
        address: z.object({
            street: streetAddress.nullish(),
            city: city.nullish(),
            state: usStateOrTerritorySchema.nullish(),
            zip: zipCode.nullish(),
        }),
        phone: phoneNumber.nullish(),
        fein: fein.nullish(),
        yearFoundedOrCreditEstablished: z.number().nullish(),
        bankruptcy: z.boolean().nullish(),
        judgmentBalance: currencySchema.nullish(),
        lienBalance: currencySchema.nullish(),
        experianFsr: fsr.nullish(),
        experianFsrFactors: z.array(Vendor.experianFSRFactorSchema).nullish(),
        experianFsrTrends: z.array(Vendor.experianFSRTrendSchema).nullish(),
        experianBin: z.string().nullish(),
        experianExecutiveElements: Vendor.experianExecutiveElementsSchema.nullish(),
    });
})(Vendor || (Vendor = {}));

export { Vendor };
