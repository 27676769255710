import { z } from 'zod';
import { paymentTypeSchema, paymentMethodSchema } from '../../enums.mjs';
import { paymentVendorName, days, months, weeks, years } from '../opaque.mjs';

const paymentTemplateSchema = z.object({
    type: paymentTypeSchema,
    vendorName: paymentVendorName,
    method: paymentMethodSchema.nullish(),
    dueFromFirstPayment: z.boolean(),
    dueFromPreviousPayment: z.boolean(),
    dueFromEndOfMonth: z.boolean(),
    dueFromEndOfYear: z.boolean(),
    dueDays: days,
    dueMonths: months,
    dueWeeks: weeks,
    dueYears: years,
});

export { paymentTemplateSchema };
