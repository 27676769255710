import { z } from 'zod';
import { indemnityStatusSchema, indemnityAgreementSignatureMethodSchema } from '../../../../enums.mjs';
import { suretyIndemnityAgreementId, companyContactId, email, individualContactId, isoDateTimeSchema, userId } from '../../../../types/opaque.mjs';
import { zswitch } from '../../../../types/utils.mjs';
import '../../../../types/agency/carrier-agency-relationship.types.mjs';
import '../../../../types/credit-report/company-credit-report.mjs';
import '../../../../types/credit-report/individual-credit-report.mjs';
import '../../../../types/payment/payment-template.mjs';
import '../../../../types/surety/account/surety-account.types.mjs';
import '../../../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../../../types/surety/bond/surety-bond.types.mjs';
import '../../../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../../../types/surety/work-in-progress-statement/surety-work-in-progress-statement.mjs';
import '../../../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../../../types/surety/indemnity/indemnity-agreement.mjs';
import '../../../../types/user/user.types.mjs';
import '../../../../types/entityRelation/entity-relation.mjs';

var Get;
(function (Get) {
    (function (Response) {
        const baseSchema = z.object({
            status: indemnityStatusSchema,
        });
        let Incomplete;
        (function (Incomplete) {
            Incomplete.schema = baseSchema.extend({
                id: suretyIndemnityAgreementId,
                status: indemnityStatusSchema.exclude(["complete"]),
                signatureMethod: indemnityAgreementSignatureMethodSchema,
                indemnitors: z.object({
                    companies: z.array(z.object({
                        id: companyContactId,
                        name: z.string(),
                        signerEmail: email.optional(),
                        completed: z.boolean().optional(),
                    })),
                    individuals: z
                        .array(z.object({
                        id: individualContactId,
                        name: z.string(),
                        signerEmail: email.optional(),
                        completed: z.boolean().optional(),
                    }))
                        .default([]),
                }),
            });
        })(Incomplete = Response.Incomplete || (Response.Incomplete = {}));
        let Completed;
        (function (Completed) {
            Completed.schema = Incomplete.schema.extend({
                status: indemnityStatusSchema.extract(["complete"]),
                completedAt: isoDateTimeSchema.optional(),
                completedByUserId: userId.optional(),
            });
        })(Completed = Response.Completed || (Response.Completed = {}));
        Response.schema = zswitch((input) => {
            switch (input.status) {
                case "pending":
                    return Incomplete.schema;
                case "review":
                    return Incomplete.schema;
                case "complete":
                    return Completed.schema;
                default:
                    return z.never();
            }
        });
    })(Get.Response || (Get.Response = {}));
})(Get || (Get = {}));
var Upload;
(function (Upload) {
    (function (Request) {
        Request.schema = z.object({
            file: z.object({
                name: z.string(),
                base64: z.string(),
            }),
        });
    })(Upload.Request || (Upload.Request = {}));
})(Upload || (Upload = {}));

export { Get, Upload };
