import { z } from 'zod';
import { SuretyType } from '../../../../enums.mjs';
import { Get as Get$1 } from '../surety-bond-mixins.dto.mjs';

var Get;
(function (Get) {
    (function (Response) {
        Response.rawSchema = z.object({
            suretyType: z.literal(SuretyType.contract),
        });
        Response.baseSchema = Get$1.Response.baseSchema.extend(Response.rawSchema.shape);
    })(Get.Response || (Get.Response = {}));
})(Get || (Get = {}));

export { Get };
