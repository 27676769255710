import { useEffect, useRef, useState } from "react";
import { HiXMark } from "react-icons/hi2";
import { assertIsNode } from "../../utils";
import { cn } from "../lib/utils";

export const AutoSaveTextarea = ({
	content,
	isUpdate,
	placeholder,
	className,
	closeEditor,
	createFn,
	updateFn,
}: {
	content: string | undefined;
	isUpdate: boolean;
	placeholder?: string;
	className?: string;
	closeEditor: () => void;
	createFn: (content: string) => void;
	updateFn: (content: string) => void;
}) => {
	const [textAreaContent, setTextAreaContent] = useState(content || "");
	const ref = useRef<HTMLTextAreaElement>(null);

	const wrapperRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		function handleClickOutside(event: MouseEvent) {
			assertIsNode(event.target);
			if (wrapperRef?.current && !wrapperRef.current.contains(event.target)) {
				if (textAreaContent.length > 0 && textAreaContent !== content) {
					if (!isUpdate) {
						createFn(textAreaContent);
					}
				}
				if (isUpdate && content !== textAreaContent) {
					updateFn(textAreaContent);
				}
				closeEditor();
			}
		}
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [wrapperRef, textAreaContent, content, closeEditor]);

	useEffect(() => {
		if (ref.current) {
			ref.current.focus();
			ref.current.style.height = "auto";
			ref.current.style.height = ref.current.scrollHeight + "px";
		}
	}, [ref]);

	const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		setTextAreaContent(event.target.value);
		if (ref.current) {
			ref.current.style.height = "auto";
			ref.current.style.height = ref.current.scrollHeight + "px";
		}
	};

	const handleBlur = () => {
		if (textAreaContent.length > 0 && textAreaContent !== content) {
			if (!isUpdate) {
				createFn(textAreaContent);
			}
		}
		if (isUpdate && content !== textAreaContent) {
			updateFn(textAreaContent);
		}
		closeEditor();
	};

	return (
		<div ref={wrapperRef}>
			<div className={cn("w-full border border-gray-200 rounded-md", className)}>
				<div>
					<div className="flex flex-row justify-end items-center px-[8px] py-2 h-[24px] bg-gray-50 rounded-t-md">
						<HiXMark
							className="text-gray-400 hover:text-gray-500 cursor-pointer text-[12px] stroke-[1.25]"
							onClick={closeEditor}
						/>
					</div>
					<div className="border-b border-gray-200"></div>
				</div>
				<textarea
					value={textAreaContent}
					className="w-full h-[54px] bg-white text-[13px] px-3 py-2 resize-none focus:outline-none"
					onChange={handleChange}
					placeholder={placeholder || ""}
					onFocus={(e) =>
						e.currentTarget.setSelectionRange(
							e.currentTarget.value.length,
							e.currentTarget.value.length,
						)
					}
					onBlur={handleBlur}
					ref={ref}
				/>
			</div>
		</div>
	);
};
