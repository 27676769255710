import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react";
import { useMemo } from "react";
import { HiCheck, HiChevronRight } from "react-icons/hi2";
import { ActionItemList } from "../../../../../../domain/admin/action-item/api";
import { ActionItem } from "../../../../../../domain/admin/action-item/type";
import { AdminActionItem } from "../components/AdminActionItem";

export const AdminActionItemListSection = ({
	actionItems,
	setCurrentActionItem,
	filterParams,
}: {
	actionItems: ActionItemList;
	setCurrentActionItem: (actionItem: ActionItem, isEditing?: boolean) => void;
	filterParams?: any; //TODO: DEFINE FILTER PARAMS
}) => {
	const filteredSortedLists: { incomplete: ActionItem[]; complete: ActionItem[] } = useMemo(() => {
		if (!filterParams) {
			const allActionItems = Object.values(actionItems).sort((a, b) => {
				const aCompleted = !!a.completedAt;
				const bCompleted = !!b.completedAt;

				if (aCompleted !== bCompleted) {
					return aCompleted ? 1 : -1;
				}

				const aEffectiveDate = a.dueAt ? a.dueAt : a.createdAt;
				const bEffectiveDate = b.dueAt ? b.dueAt : b.createdAt;

				return aEffectiveDate.toMillis() - bEffectiveDate.toMillis();
			});
			const incomplete = allActionItems.filter((actionItem) => !actionItem.completedAt);
			const complete = allActionItems.filter((actionItem) => actionItem.completedAt);

			return { incomplete, complete };
		}
		return { incomplete: [], complete: [] }; // Left as a hook where we can dynamically filter and sort the action items
	}, [actionItems, filterParams]);

	return (
		<div className="pb-8">
			{filteredSortedLists.incomplete.map((actionItem, idx) => (
				<AdminActionItem
					actionItem={actionItem}
					setCurrentActionItem={setCurrentActionItem}
					key={`actionItem-${idx}`}
				/>
			))}
			{filteredSortedLists.complete.length > 0 && (
				<Disclosure as="div" className="flex flex-col" defaultOpen={true}>
					<DisclosureButton className="group/expand z-20 flex items-center py-[8px] space-x-[16px] sticky top-0 left-0 right-0 font-medium px-[12px] mb-[-1px] bg-gray-50 cursor-pointer border-t border-gray-200">
						<div className="flex items-center h-full gap-[16px]">
							<HiChevronRight className="text-[12px] stroke-[.75] text-gray-400 ui-open:text-gray-400 ui-open:rotate-90" />
							<div className="flex-1 flex items-center space-x-[12px] justify-between">
								<HiCheck className="w-[12px] h-[12px] text-green-500" />
								<div className="text-[12px] text-gray-500">Completed</div>
							</div>
						</div>
					</DisclosureButton>
					<DisclosurePanel>
						{filteredSortedLists.complete.map((actionItem, idx) => (
							<AdminActionItem
								actionItem={actionItem}
								setCurrentActionItem={setCurrentActionItem}
								key={`actionItem-${idx}`}
							/>
						))}
					</DisclosurePanel>
				</Disclosure>
			)}
		</div>
	);
};
