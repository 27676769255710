import { z } from 'zod';
import { monthShortSchema } from '../../enums.mjs';
import { Underwriting } from '../../types/surety/account/surety-account.types.mjs';

var Get;
(function (Get) {
    (function (Response) {
        Response.schema = z.object({
            agencyDisplayName: z.string(),
            averageAccountLetterGrade: z.object({
                average: z.nativeEnum(Underwriting.LetterGrade),
                count: z.object((() => {
                    const result = {};
                    Object.values(Underwriting.LetterGrade).forEach((letterGrade) => {
                        result[letterGrade] = z.number().min(0);
                    });
                    return result;
                })()),
            }),
            quoteCount3Month: z
                .object({
                month: monthShortSchema,
                count: z.number().min(0),
            })
                .array(),
            commissionTotal3Month: z
                .object({
                month: monthShortSchema,
                total: z.number().min(0),
            })
                .array(),
            quoteCountTotal: z.number().min(0),
            bondCountTotal: z.number().min(0),
            commissionTotal: z.number().min(0),
        });
    })(Get.Response || (Get.Response = {}));
})(Get || (Get = {}));

export { Get };
