import { Decimal } from 'decimal.js-light';
import { isPlainObject } from 'is-plain-object';
import { path } from 'rambda';
import { match } from 'ts-pattern';
import { z, ZodObject, ZodType, ZodNever } from 'zod';
import { fileExtensionSchema, fileTypeSchema, fileExtensionDef, fileTypeDef, FileExtension, FileType, getBaseNaicsCode, naicsCodeGroupMap } from '../enums.mjs';
import { applyCurrencyBrand, addressSchema, rateSchema, fileId, attachmentId, suretyBondFormTemplateId } from './opaque.mjs';

const buildCoercedNumberSchema = (numberSchema = z.number()) => z
    .any()
    .transform((val) => {
    if (val === "")
        return undefined;
    if (val === null || val === undefined)
        return val;
    return Number(val);
})
    .pipe(numberSchema);
const buildCoercedOpaqueNumberSchema = (opaqueSchema) => buildCoercedNumberSchema(opaqueSchema.unwrap()).brand();
const safeNumberSchema = z.coerce
    .number({ required_error: "Required" })
    .max(2147483647)
    .min(-2147483648);
const _currencySchema = z
    .number({ required_error: "Required" })
    .min(-99999999999999.99)
    .max(99999999999999.99);
const currencySchema = buildCoercedOpaqueNumberSchema(applyCurrencyBrand(_currencySchema));
const nonNegativeCurrencySchema = buildCoercedOpaqueNumberSchema(applyCurrencyBrand(_currencySchema.min(0)));
const toISODate = (dt) => dt.toISODate();
const toISODateTime = (dt) => dt.toISO();
const addressKeys = Object.keys(addressSchema.shape);
const contractSuretyTypeToString = (contractSuretyType) => {
    return match(contractSuretyType)
        .with("bid", () => "Bid")
        .with("bid_to_final", () => "Bid To Final")
        .with("final", () => "Final")
        .exhaustive();
};
const entityTypeToDisplayString = (entityType) => {
    return match(entityType)
        .with("account", () => "Account")
        .with("bond", () => "Bond")
        .with("quote", () => "Bond Request")
        .with("actionItem", () => "Action Item")
        .exhaustive();
};
const baseUploadedFile = z.object({
    id: z.string(),
    name: z.string(),
    extension: fileExtensionSchema,
    types: z.array(fileTypeSchema).optional(),
});
const uploadedFileWithoutId = baseUploadedFile.omit({ id: true });
const uploadedFileSchema = (allowedFileTypes, allowedFileExtensions) => z.object({
    id: fileId,
    name: z.string(),
    extension: z.enum((allowedFileExtensions ?? [...fileExtensionDef])),
    types: z
        .array(z.enum((allowedFileTypes ?? [...fileTypeDef])))
        .default([]),
    typesConfirmedByAdmin: z.boolean().default(false),
});
const attachmentSchema = (allowedFileTypes, allowedFileExtensions) => z.object({
    id: attachmentId,
    file: uploadedFileSchema(allowedFileTypes, allowedFileExtensions),
    createdAtMs: z.number(),
});
const uploadedFileCollectionSchema = (requiredFileTypes, allowedFileTypes, allowedFileExtensions) => z.array(uploadedFileSchema(allowedFileTypes, allowedFileExtensions)).refine((files) => {
    if (requiredFileTypes &&
        !requiredFileTypes.every((req) => files.some((file) => file.types?.includes(req) && file.types.length > 0)))
        return false;
    if (allowedFileTypes &&
        !files.every((file) => file.types?.every((type) => allowedFileTypes.includes(type))))
        return false;
    return true;
});
const getSchemaTypeFromData = (data) => {
    const base = {};
    for (const key of Object.keys(data.shape)) {
        const value = data.shape[key];
        if (key === "files") {
            base[key] = z
                .object({
                allowedFileTypes: z.array(fileTypeSchema),
                requiredFileTypes: z.array(fileTypeSchema),
            })
                .optional();
        }
        else if (value instanceof ZodObject) {
            if (Object.keys(value.shape).length === addressKeys.length &&
                addressKeys.every((key) => key in value.shape)) {
                base[key] = z.literal(true).optional();
            }
            else {
                base[key] = getSchemaTypeFromData(value).optional();
            }
        }
        else {
            base[key] = z.literal(true).optional();
        }
    }
    return z.object(base);
};
const getSectionSchemaTypeFromData = (data) => {
    return z
        .record(data.keyof(), z
        .object({
        index: z.number(),
        sectionLabel: z.string(),
        include: z.record(z.any()),
        readonly: z.record(z.any()).optional(),
        dataOnly: z.record(z.any()).optional(),
    })
        .optional())
        .superRefine((val, ctx) => {
        const keys = Object.keys(val);
        for (const key of keys) {
            const value = val[key];
            if (value !== undefined) {
                const schema = getSchemaTypeFromData(data.shape[key]);
                const parsedInclude = schema.safeParse(val[key].include);
                const parsedReadonly = schema.optional().safeParse(val[key].readonly);
                const parsedDataOnly = schema.optional().safeParse(val[key].dataOnly);
                if (!parsedInclude.success) {
                    parsedInclude.error.issues.forEach((issue) => ctx.addIssue(issue));
                }
                if (!parsedReadonly.success) {
                    parsedReadonly.error.issues.forEach((issue) => ctx.addIssue(issue));
                }
                if (!parsedDataOnly.success) {
                    parsedDataOnly.error.issues.forEach((issue) => ctx.addIssue(issue));
                }
            }
        }
        return z.NEVER;
    });
};
const buildCreditReportVendorResponseDtoSchema = (vendorReport) => z.union([
    z.object({
        success: z.literal(false),
    }),
    z.object({
        success: z.literal(true),
        fileFound: z.literal(false),
    }),
    z.object({
        success: z.literal(true),
        fileFound: z.literal(true),
        report: vendorReport,
    }),
]);
const buildSuretyQuoteBondFormDraftSchema = (bondFormType) => z.object({
    templateId: z.union([z.string(), z.literal("")]),
    upload: z.object({
        type: bondFormType,
        files: z
            .array(uploadedFileSchema([FileType.blank_bond_form], [
            FileExtension.pdf,
            FileExtension.png,
            FileExtension.gif,
            FileExtension.jpg,
            FileExtension.jpeg,
            FileExtension.docx,
            FileExtension.doc,
        ]))
            .min(1),
    }),
});
const stringOrEmptyString = z.union([z.string(), z.literal("")]);
const boolOrEmptyString = z.union([z.boolean(), z.literal("")]);
const _sectionSchemaIncludes = (validationSchemaShape, formSchema, sectionName, includePath) => {
    const schemaSection = formSchema[sectionName];
    if (schemaSection) {
        return _schemaIncludes(validationSchemaShape, schemaSection, includePath);
    }
    return {};
};
const _schemaIncludes = (validationSchemaShape, formSchema, includePath) => {
    if (includePath !== undefined) {
        if (path(includePath, formSchema.include)) {
            return validationSchemaShape;
        }
        return {};
    }
    return validationSchemaShape;
};
const includesSchema = (value, validationSchema) => {
    return zswitch(() => {
        if (value !== undefined) {
            return validationSchema;
        }
        return z.any().transform(() => undefined);
    });
};
const maybePartialSchema = (partial, validationSchema) => {
    if (partial) {
        return validationSchema.nullish();
    }
    return validationSchema;
};
const pruneKeys = (targetObj, referenceObj) => {
    Object.keys(targetObj).forEach((key) => {
        if (referenceObj[key] === undefined) {
            delete targetObj[key];
        }
    });
    return targetObj;
};
const bondFormSubmissionSchema = (allowedTypes) => {
    return z.union([
        z.object({
            templateId: suretyBondFormTemplateId,
        }),
        z.object({
            upload: z.object({
                type: z.enum(allowedTypes),
                files: z
                    .array(uploadedFileSchema([FileType.blank_bond_form], [
                    FileExtension.pdf,
                    FileExtension.png,
                    FileExtension.gif,
                    FileExtension.jpg,
                    FileExtension.jpeg,
                    FileExtension.docx,
                    FileExtension.doc,
                ]))
                    .min(1),
            }),
        }),
    ]);
};
const draftBondFormSchema = (allowedTypes) => {
    return z.object({
        templateId: stringOrEmptyString,
        upload: z.object({
            type: z.union([z.enum(allowedTypes), z.literal("")]),
            files: z.array(uploadedFileSchema([FileType.blank_bond_form], [
                FileExtension.pdf,
                FileExtension.png,
                FileExtension.gif,
                FileExtension.jpg,
                FileExtension.jpeg,
                FileExtension.docx,
                FileExtension.doc,
            ])),
        }),
    });
};
const stripEmpty = (value) => {
    if (value === "")
        return undefined;
    return value;
};
const nullEmpty = (value) => {
    if (value === "")
        return null;
    return value;
};
const stripEmptySchema = (schema) => {
    return z
        .any()
        .transform((val) => {
        return stripEmpty(val);
    })
        .pipe(schema);
};
const numberToStringSchema = (min) => z.number().pipe(z.coerce.string().min(min ?? 0, `${min} Minimum`));
function processCreateParams(params) {
    if (!params)
        return {};
    const { errorMap, invalid_type_error, required_error, description } = params;
    if (errorMap && (invalid_type_error || required_error)) {
        throw new Error(`Can't use "invalid_type_error" or "required_error" in conjunction with custom error map.`);
    }
    if (errorMap !== undefined)
        return { errorMap: errorMap, description };
    const customMap = (iss, ctx) => {
        if (iss.code !== "invalid_type")
            return { message: ctx.defaultError };
        if (typeof ctx.data === "undefined") {
            return { message: required_error ?? ctx.defaultError };
        }
        return { message: invalid_type_error ?? ctx.defaultError };
    };
    return { errorMap: customMap, description };
}
class ZodSwitch extends ZodType {
    _parse(input) {
        const { ctx } = this._processInputParams(input);
        const switchFn = this._def.switchFn;
        if (ctx.common.async) {
            return Promise.resolve()
                .then(async () => {
                return await switchFn(ctx.data);
            })
                .then(async (schema) => {
                if (schema !== undefined) {
                    return await schema._parseAsync({
                        data: ctx.data,
                        path: ctx.path,
                        parent: ctx,
                    });
                }
                return await ZodNever.create()._parseAsync({
                    data: ctx.data,
                    path: ctx.path,
                    parent: ctx,
                });
            });
        }
        const schema = switchFn(ctx.data);
        if (schema instanceof Promise) {
            throw new Error("Async refinement encountered during synchronous parse operation. Use .parseAsync instead.");
        }
        if (schema !== undefined) {
            return schema._parseSync({
                data: ctx.data,
                path: ctx.path,
                parent: ctx,
            });
        }
        return ZodNever.create()._parseSync({
            data: ctx.data,
            path: ctx.path,
            parent: ctx,
        });
    }
    static create(switchFn, params) {
        return new ZodSwitch({
            switchFn,
            typeName: "ZodSwitch",
            ...processCreateParams(params),
        });
    }
}
const zswitch = ZodSwitch.create;
const stripEmptyDeep = (value, returnEmptyObject = false) => {
    if (value === "")
        return undefined;
    if (Array.isArray(value))
        return value.map((item) => stripEmptyDeep(item, returnEmptyObject));
    if (isPlainObject(value)) {
        const newObj = {};
        for (const key in value) {
            const newValue = stripEmptyDeep(value[key], returnEmptyObject);
            if (newValue !== undefined) {
                newObj[key] = newValue;
            }
        }
        if (Object.keys(newObj).length || returnEmptyObject) {
            return newObj;
        }
        return undefined;
    }
    return value;
};
const nullEmptyDeep = (value, returnEmptyObject = false) => {
    if (value === "")
        return null;
    if (Array.isArray(value))
        return value.map((item) => nullEmptyDeep(item, returnEmptyObject));
    if (isPlainObject(value)) {
        const newObj = {};
        for (const key in value) {
            const newValue = nullEmptyDeep(value[key], returnEmptyObject);
            if (newValue !== undefined) {
                newObj[key] = newValue;
            }
        }
        if (Object.keys(newObj).length || returnEmptyObject) {
            return newObj;
        }
        return null;
    }
    return value;
};
const isSupersetOf = (a, b) => {
    if (a.size < b.size)
        return false;
    for (const val of b) {
        if (!a.has(val))
            return false;
    }
    return true;
};
const isSubsetOf = (a, b) => {
    if (a.size > b.size)
        return false;
    for (const val of a) {
        if (!b.has(val))
            return false;
    }
    return true;
};
const isSetEqual = (a, b) => {
    if (a.size !== b.size)
        return false;
    for (const val of a) {
        if (!b.has(val))
            return false;
    }
    return true;
};
const isSetOverlap = (a, b) => {
    let subject;
    let target;
    if (a.size > b.size) {
        subject = b;
        target = a;
    }
    else {
        subject = a;
        target = b;
    }
    for (const val of subject) {
        if (target.has(val))
            return true;
    }
    return false;
};
const getSetIntersectionSize = (a, b) => {
    let subject;
    let target;
    if (a.size > b.size) {
        subject = b;
        target = a;
    }
    else {
        subject = a;
        target = b;
    }
    let count = 0;
    for (const val of subject) {
        if (target.has(val))
            count++;
    }
    return count;
};
const getNAICSCodeLabelGroups = (naicsCodes) => {
    const naicsCodeGroupsLabels = new Map();
    const baseNaicsCodeSet = new Set();
    naicsCodes.forEach((code) => baseNaicsCodeSet.add(getBaseNaicsCode(code)));
    baseNaicsCodeSet.forEach((baseCode) => {
        let group = naicsCodeGroupMap.get(baseCode);
        if (group === undefined) {
            console.error(`No group found for base code ${baseCode}`);
            return;
        }
        const groupLabels = naicsCodeGroupsLabels.get(group.label) ?? {
            label: group.label,
            groupSubCodeCount: group.subCodes.size,
            subCodesLabels: [],
        };
        const subCodeLabelSet = new Set(groupLabels.subCodesLabels);
        group.subCodes.forEach((label, subCode) => {
            if (typeof subCode === "string") {
                if (naicsCodes.has(subCode)) {
                    subCodeLabelSet.add(label);
                }
            }
            else if (isSetOverlap(subCode, naicsCodes)) {
                subCodeLabelSet.add(label);
            }
        });
        groupLabels.subCodesLabels = [...subCodeLabelSet];
        naicsCodeGroupsLabels.set(group.label, groupLabels);
    });
    return [...naicsCodeGroupsLabels.values()];
};
const initializeExposure = () => ({
    quotes: 0,
    bonds: 0,
    total: 0,
});
const exposureSchema = z.object({
    quotes: z.number(),
    bonds: z.number(),
    total: z.number(),
});
const suretyAccountBondingLineSchema = z.object({
    rate: rateSchema,
    singleLimit: nonNegativeCurrencySchema,
    aggregateLimit: nonNegativeCurrencySchema,
    isPublished: z.boolean(),
    autoUpdate: z.boolean(),
});
const numberFormatter = new Intl.NumberFormat("en");
function formatToDollar(value, round = false) {
    if (value === undefined || value === null || value === "") {
        return value;
    }
    return ("$" +
        numberFormatter.format(new Decimal(round ? Math.round(Number(value)) : value).toDecimalPlaces(2).toNumber()));
}
function formatToPercent(value, precision = 2, defaultValue) {
    if (value === undefined || value === null) {
        return defaultValue ?? "";
    }
    return `${new Decimal(value).times(100).toDecimalPlaces(precision).toNumber()}%`;
}
const formatYesNo = (value) => {
    if (value === undefined || value === null)
        return value;
    return value ? "Yes" : "No";
};
const enumMap = (enumeration, obj) => {
    const validateKeysInEnum = (record) => Object.keys(record).every((key) => enumeration.safeParse(key).success);
    return z.record(obj).refine(validateKeysInEnum);
};
const keys = (obj) => {
    return Object.keys(obj);
};
const preProcessFileTypeSchema = (schema) => {
    return z.object({
        types: z.preprocess((val) => (val ? val.split(", ") : []), z.array(schema)),
    });
};

export { ZodSwitch, _schemaIncludes, _sectionSchemaIncludes, attachmentSchema, baseUploadedFile, bondFormSubmissionSchema, boolOrEmptyString, buildCreditReportVendorResponseDtoSchema, buildSuretyQuoteBondFormDraftSchema, contractSuretyTypeToString, currencySchema, draftBondFormSchema, entityTypeToDisplayString, enumMap, exposureSchema, formatToDollar, formatToPercent, formatYesNo, getNAICSCodeLabelGroups, getSchemaTypeFromData, getSectionSchemaTypeFromData, getSetIntersectionSize, includesSchema, initializeExposure, isSetEqual, isSetOverlap, isSubsetOf, isSupersetOf, keys, maybePartialSchema, nonNegativeCurrencySchema, nullEmpty, nullEmptyDeep, numberToStringSchema, preProcessFileTypeSchema, pruneKeys, safeNumberSchema, stringOrEmptyString, stripEmpty, stripEmptyDeep, stripEmptySchema, suretyAccountBondingLineSchema, toISODate, toISODateTime, uploadedFileCollectionSchema, uploadedFileSchema, uploadedFileWithoutId, zswitch };
