import { PopoverPanel } from "@headlessui/react";
import { ActionItemId, Relations, UserId, actionItemStatus } from "@inrev/common";
import { DateTime } from "luxon";
import { useState } from "react";
import { Button } from "../../../../../../components/ui/Button";
import { CheckMarkBubble } from "../../../../../../components/ui/CheckMarkBubble";
import { DatePicker } from "../../../../../../components/ui/DatePicker";
import { Menu } from "../../../../../../components/ui/Menu";
import { RichTextEditor } from "../../../../../../components/ui/RichTextEditor";
import {
	useCreateActionItem,
	useUpdateActionItem,
} from "../../../../../../domain/admin/action-item/api";
import { useFetchAdminUsers } from "../../../../../../domain/admin/admin-user/api";
import { useAuthenticatedUser } from "../../../../../../providers/AuthenticatedUserProvider";
import { assigneeUserFromQuery } from "../../../AdminAssigneeControls";
import { ActionItemSelectAssignee } from "../components/ActionItemSelectAssignee";
import { ActionItemTitleInput } from "../components/ActionItemTitleInput";
import { AdminActionItemLayout } from "../layouts/AdminActionItemLayout";

export const AdminActionItemCreateSection = ({
	isOpen,
	relation,
	defaultUnderwriterId,
	close,
}: {
	isOpen: boolean;
	relation: Relations.ActionItems;
	defaultUnderwriterId: UserId;
	close: () => void;
}) => {
	if (!isOpen) return null;

	return (
		<CreateOrUpdateActionItem
			relation={relation}
			defaultUnderwriterId={defaultUnderwriterId}
			close={close}
		/>
	);
};

type CreateOrUpdateActionItemProps = {
	relation: Relations.ActionItems;
	defaultUnderwriterId: UserId;
	inputDefaults?: {
		title?: string;
		content?: string | null;
		dueAt?: DateTime | null;
		assigneeId?: UserId;
		isComplete?: boolean;
	};
	isUpdate?: boolean;
	close: () => void;
} & (
	| {
			isUpdate: true;
			actionItemId: ActionItemId;
	  }
	| {
			isUpdate?: false;
			actionItemId?: never;
	  }
);

export const CreateOrUpdateActionItem = ({
	relation,
	defaultUnderwriterId,
	close,
	inputDefaults,
	isUpdate,
	actionItemId,
}: CreateOrUpdateActionItemProps) => {
	const { user } = useAuthenticatedUser();
	const { createActionItem } = useCreateActionItem();
	const { updateActionItem } = useUpdateActionItem();
	const { adminUsers = [] } = useFetchAdminUsers();
	const [isComplete, toggleComplete] = useState(inputDefaults?.isComplete || false);
	const [title, setTitle] = useState(inputDefaults?.title || "");
	const [actionItemContent, setActionItemContent] = useState(inputDefaults?.content || "");
	const [dueAt, setDueAt] = useState<DateTime>(inputDefaults?.dueAt || DateTime.now());
	const [assigneeId, setAssigneeId] = useState<UserId>(
		inputDefaults?.assigneeId || defaultUnderwriterId,
	);
	const [errors, setErrors] = useState<{ title?: string }>({});

	const createFn = () => {
		const inputErrors = validateInput({ title });
		if (Object.keys(inputErrors).length > 0) {
			setErrors(inputErrors);
			return;
		}
		const actionItem = {
			title,
			content: "", // should content be available on creation?
			status: isComplete ? actionItemStatus.complete : actionItemStatus.open,
			ownerId: user.id,
			assigneeId: assigneeId,
			dueAt: dueAt,
			completedByUserId: isComplete ? assigneeId : undefined,
			completedAt: isComplete ? DateTime.now() : undefined,
			...relation,
		};

		createActionItem(actionItem);
		close();
	};

	const updateFn = () => {
		if (!actionItemId) return;
		const inputErrors = validateInput({ title });
		if (Object.keys(inputErrors).length > 0) {
			setErrors(inputErrors);
			return;
		}

		const actionItem = {
			...(title !== inputDefaults?.title && { title }),
			...(isComplete !== inputDefaults?.isComplete && {
				status: isComplete ? actionItemStatus.complete : actionItemStatus.open,
				completedByUserId: isComplete ? assigneeId : undefined,
				completedAt: isComplete ? DateTime.now() : undefined,
			}),
			...(actionItemContent !== inputDefaults?.content && { content: actionItemContent }),
			...(assigneeId !== inputDefaults?.assigneeId && { assigneeId }),
			...(dueAt !== inputDefaults?.dueAt && { dueAt }),
		};

		if (Object.keys(actionItem).length !== 0) {
			updateActionItem({ actionItemId, updatedFields: actionItem, relation });
		}
		close();
	};

	const validateInput = ({ title }: { title?: string }) => {
		const errors: { title?: string } = {};
		if (!title || title.length === 0) errors.title = "Title is required";
		return errors;
	};

	const footer = (
		<div className="min-w-full flex items-center justify-between flex-col gap-4">
			{isUpdate && (
				<div className="min-w-full">
					<RichTextEditor content={inputDefaults?.content || ""} onUpdate={setActionItemContent} />
				</div>
			)}
			<div className="flex gap-4">
				<Button color="light-blue" filled onClick={isUpdate ? updateFn : createFn}>
					{isUpdate ? "Update" : "Create"}
				</Button>
				<Button color="light-gray" filled onClick={close}>
					Cancel
				</Button>
			</div>
		</div>
	);

	const content = (
		<>
			<div className="flex flex-col justify-start gap-2 flex-grow">
				<ActionItemTitleInput updateFn={setTitle} title={title} error={errors.title} />
				<div className="flex items-center gap-2">
					<div className="text-[12px] text-gray-500 pt-[2px]">Due: </div>
					<Menu
						button={
							<div className="text-gray-500 text-[12px] font-[450] underline cursor-pointer hover:text-gray-800">
								{dueAt.toFormat("LLL dd, yyyy")}
							</div>
						}
						anchor="top"
					>
						<PopoverPanel>
							{({ close }) => (
								<DatePicker
									selectedValue={dueAt.toJSDate()}
									defaultMonth={DateTime.now()}
									onChange={(value: string | "") => {
										if (!value) return;
										setDueAt(DateTime.fromISO(value));
									}}
									setOpen={() => close()}
								/>
							)}
						</PopoverPanel>
					</Menu>
				</div>
			</div>
			<div className="flex items-center justify-center self-start min-w-fit">
				<ActionItemSelectAssignee
					defaultUnderwriterId={defaultUnderwriterId}
					userList={adminUsers.map(assigneeUserFromQuery)}
					selectedUserId={assigneeId}
					setUserId={setAssigneeId}
				/>
			</div>
		</>
	);

	return (
		<AdminActionItemLayout
			containerClassName={isUpdate ? "border-b-0" : ""}
			statusIcon={
				<CheckMarkBubble
					isComplete={isComplete}
					toggleComplete={() => toggleComplete(!isComplete)}
				/>
			}
			content={content}
			footer={footer}
		/>
	);
};
