import { IconType } from "react-icons/lib";
import { cn } from "../lib/utils";

export const ToolIcon = ({
	Icon,
	onClick,
	className,
}: { Icon: IconType; onClick?: () => void; className?: string }) => {
	return (
		<div
			className={cn(
				"h-[19px] w-[19px] flex items-center justify-center cursor-pointer text-gray-400 hover:text-gray-800",
				className,
			)}
			onClick={onClick}
		>
			<Icon />
		</div>
	);
};
