import { HiCheckCircle } from "react-icons/hi2";
import { cn } from "../lib/utils";

export const CheckMarkBubble = ({
	isComplete,
	toggleComplete,
	className,
}: {
	isComplete: boolean;
	toggleComplete: () => void;
	className?: string;
}) => {
	return (
		<div className={cn("w-[18px] self-start", className)}>
			{isComplete ? (
				<HiCheckCircle
					className="align-top fill-[#34B668] w-[19.7px] h-[19.7px] mx-[-2px] mt-[3.5px] cursor-pointer hover:fill-green-600"
					onClick={(e) => {
						e.stopPropagation();
						e.preventDefault();
						toggleComplete();
					}}
				/>
			) : (
				<div
					className="w-[16px] h-[16px] rounded-full border-gray-500 border hover:bg-gray-300 cursor-pointer mt-[4.5px] box-border"
					onClick={(e) => {
						e.stopPropagation();
						e.preventDefault();
						toggleComplete();
					}}
				/>
			)}
		</div>
	);
};
