import { z } from 'zod';
import { usStateOrTerritorySchema } from '../../enums.mjs';
import { companyCreditReportId, streetAddress, city, zipCode, fsr, isoDateTimeSchema } from '../../types/opaque.mjs';
import '../../types/utils.mjs';
import '../../types/agency/carrier-agency-relationship.types.mjs';
import '../../types/credit-report/company-credit-report.mjs';
import '../../types/credit-report/individual-credit-report.mjs';
import '../../types/payment/payment-template.mjs';
import '../../types/surety/account/surety-account.types.mjs';
import '../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../types/surety/bond/surety-bond.types.mjs';
import '../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../types/surety/work-in-progress-statement/surety-work-in-progress-statement.mjs';
import '../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../types/surety/indemnity/indemnity-agreement.mjs';
import '../../types/user/user.types.mjs';
import '../../types/entityRelation/entity-relation.mjs';

var Get;
(function (Get) {
    Get.responseSchema = z.object({
        id: companyCreditReportId,
        companyName: z.string(),
        companyAddress: streetAddress.optional(),
        companyCity: city,
        companyState: usStateOrTerritorySchema,
        companyZip: zipCode.optional(),
        yearsInBusinessOrCreditAge: z.number().positive().optional(),
        bankruptcy: z.boolean().optional(),
        judgmentBalance: z.number().optional(),
        lienBalance: z.number().optional(),
        experianFsr: fsr.optional(),
        createdAt: isoDateTimeSchema,
    });
})(Get || (Get = {}));

export { Get };
