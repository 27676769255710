import { z } from 'zod';
import '../../../../../types/opaque.mjs';
import { zswitch } from '../../../../../types/utils.mjs';
import '../../../../../types/agency/carrier-agency-relationship.types.mjs';
import '../../../../../types/credit-report/company-credit-report.mjs';
import '../../../../../types/credit-report/individual-credit-report.mjs';
import '../../../../../types/payment/payment-template.mjs';
import '../../../../../types/surety/account/surety-account.types.mjs';
import '../../../../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../../../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../../../../types/surety/bond/surety-bond.types.mjs';
import '../../../../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../../../../types/surety/work-in-progress-statement/surety-work-in-progress-statement.mjs';
import '../../../../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../../../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../../../../types/surety/indemnity/indemnity-agreement.mjs';
import '../../../../../types/user/user.types.mjs';
import '../../../../../types/entityRelation/entity-relation.mjs';
import { Get as Get$1, Import as Import$3, Close as Close$2 } from './admin-bid-contract-surety-bond.dto.mjs';
import * as adminBidContractSuretyBond_dto from './admin-bid-contract-surety-bond.dto.mjs';
export { adminBidContractSuretyBond_dto as Bid };
import { Import as Import$1 } from './admin-bid-to-final-contract-surety-bond.dto.mjs';
import * as adminBidToFinalContractSuretyBond_dto from './admin-bid-to-final-contract-surety-bond.dto.mjs';
export { adminBidToFinalContractSuretyBond_dto as BidToFinal };
import { Get as Get$2, Import as Import$2, Close as Close$1 } from './admin-final-contract-surety-bond.dto.mjs';
import * as adminFinalContractSuretyBond_dto from './admin-final-contract-surety-bond.dto.mjs';
export { adminFinalContractSuretyBond_dto as Final };

var Get;
(function (Get) {
    (function (Response) {
        Response.schema = z.discriminatedUnion("contractSuretyType", [
            Get$1.Response.schema,
            Get$2.Response.schema,
        ]);
    })(Get.Response || (Get.Response = {}));
})(Get || (Get = {}));
var Import;
(function (Import) {
    (function (Request) {
        Request.schema = zswitch((input) => {
            switch (input.contractSuretyType) {
                case "bid":
                    return Import$3.Request.schema;
                case "final":
                    return Import$2.Request.schema;
                case "bid_to_final":
                    return Import$1.Request.schema;
                default:
                    return z.never();
            }
        });
    })(Import.Request || (Import.Request = {}));
})(Import || (Import = {}));
var Close;
(function (Close) {
    (function (Request) {
        Request.schema = zswitch((input) => {
            switch (input.contractSuretyType) {
                case "bid":
                    return Close$2.Request.schema;
                case "final":
                    return Close$1.Request.schema;
                default:
                    return z.never();
            }
        });
    })(Close.Request || (Close.Request = {}));
})(Close || (Close = {}));

export { Close, Get, Import };
