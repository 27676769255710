import { z } from 'zod';
import { Data as Data$2 } from './draft-bid-contract-surety-quote.types.mjs';
import * as draftBidContractSuretyQuote_types from './draft-bid-contract-surety-quote.types.mjs';
export { draftBidContractSuretyQuote_types as Bid };
import { Data as Data$4 } from './draft-bid-to-final-contract-surety-quote.types.mjs';
import * as draftBidToFinalContractSuretyQuote_types from './draft-bid-to-final-contract-surety-quote.types.mjs';
export { draftBidToFinalContractSuretyQuote_types as BidToFinal };
import { Data as Data$3 } from './draft-final-contract-surety-quote.types.mjs';
import * as draftFinalContractSuretyQuote_types from './draft-final-contract-surety-quote.types.mjs';
export { draftFinalContractSuretyQuote_types as Final };
import { naicsCodeSchema, contractSuretyTypeSchema, suretyBondFormTypeDef, SuretyType } from '../../../../../enums.mjs';
import { suretyAccountId, companyContactId } from '../../../../opaque.mjs';
import { stringOrEmptyString, uploadedFileSchema, draftBondFormSchema, getSectionSchemaTypeFromData } from '../../../../utils.mjs';
import { Data as Data$1 } from '../../../account/draft/contract/contract-surety-account-draft.mjs';

var Data;
(function (Data) {
    let Principal;
    (function (Principal) {
        Principal.individualSchema = Data$1.Details.Individual.schema;
        Principal.encryptedIndividualSchema = Data$1.Details.Individual.encryptedSchema;
        Principal.companySchema = Data$1.Details.Company.schema;
    })(Principal = Data.Principal || (Data.Principal = {}));
    Data.principalSchema = z.object({
        newPrincipal: z.boolean(),
        principalAccountId: z.union([suretyAccountId, z.literal("")]),
        principalCompanyId: z.union([companyContactId, z.literal("")]),
        creditReportPermission: Data$1.Details.schema.shape.creditReportPermission,
        exposureSize: z.union([
            z.literal("small"),
            z.literal("medium"),
            z.literal("large"),
            z.literal(""),
        ]),
        company: Principal.companySchema.extend({
            address: stringOrEmptyString,
        }),
        companies: z.array(Principal.companySchema),
        fundNames: z.array(z.string()),
        individuals: z.array(Principal.individualSchema),
    });
    Data.encryptedPrincipalSchema = Data.principalSchema.extend({
        individuals: z.array(Principal.encryptedIndividualSchema),
    });
    Data.historySchema = Data$1.History.schema;
    Data.financialsSchema = Data$1.Financials.schema;
    let Bond;
    (function (Bond) {
        Bond.projectSchema = z.object({
            description: stringOrEmptyString,
            address: z.object({
                city: stringOrEmptyString,
                state: stringOrEmptyString,
            }),
            scopeOfWork: z.array(naicsCodeSchema),
            monthsToComplete: stringOrEmptyString,
            contractWarrantyMonths: stringOrEmptyString,
            contractDamages: z.array(z.string()),
            contractHazards: z.array(z.string()),
            contractDate: stringOrEmptyString,
            files: z.array(uploadedFileSchema([...Data$2.projectFileTypes, ...Data$3.projectFileTypes])),
        });
    })(Bond = Data.Bond || (Data.Bond = {}));
    Data.bondSchema = z
        .object({
        type: z.union([contractSuretyTypeSchema, z.literal("")]),
        tListingRequired: z.union([z.boolean(), z.literal("")]),
        bondAmount: z.union([z.string(), z.literal("")]),
        project: Bond.projectSchema,
        bondForm: draftBondFormSchema([...suretyBondFormTypeDef]),
    })
        .extend(Data$2.bondSchema.shape)
        .extend(Data$3.bondSchema.shape)
        .extend(Data$4.bondSchema.shape);
})(Data || (Data = {}));
const dataSchema = z.object({
    principal: Data.principalSchema,
    history: Data.historySchema,
    financials: Data.financialsSchema,
    bond: Data.bondSchema,
});
const encryptedDataSchema = dataSchema.extend({
    principal: Data.encryptedPrincipalSchema,
});
const schemaSchema = getSectionSchemaTypeFromData(dataSchema);
const draftSchema = z.object({
    suretyType: z.literal(SuretyType.contract),
    data: dataSchema,
    schema: schemaSchema,
});
const encryptedDraftSchema = draftSchema.extend({
    data: encryptedDataSchema,
});

export { Data, dataSchema, draftSchema, encryptedDataSchema, encryptedDraftSchema, schemaSchema };
