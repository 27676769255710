import { z } from 'zod';
import { suretyTypeSchema, SuretyType } from '../../../enums.mjs';
import { zswitch } from '../../../types/utils.mjs';
import { Submit as Submit$1 } from './contract/contract-surety-account.dto.mjs';
import * as contractSuretyAccount_dto from './contract/contract-surety-account.dto.mjs';
export { contractSuretyAccount_dto as Contract };
import { Get as Get$1 } from './draft/surety-account-draft.dto.mjs';
import * as suretyAccountDraft_dto from './draft/surety-account-draft.dto.mjs';
export { suretyAccountDraft_dto as Draft };
import * as suretyAccountPreview_dto from './preview/surety-account-preview.dto.mjs';
export { suretyAccountPreview_dto as Preview };
import { Get as Get$2 } from './submitted/submitted-surety-account.dto.mjs';
import * as submittedSuretyAccount_dto from './submitted/submitted-surety-account.dto.mjs';
export { submittedSuretyAccount_dto as Submitted };

var Get;
(function (Get) {
    (function (Response) {
        Response.schema = zswitch((input) => {
            if (input.status === "draft") {
                return Get$1.Response.schema;
            }
            return Get$2.Response.schema;
        });
    })(Get.Response || (Get.Response = {}));
})(Get || (Get = {}));
var Submit;
(function (Submit) {
    (function (Request) {
        (function (Base) {
            Base.schema = zswitch((input) => {
                const typedInput = z
                    .object({
                    suretyType: suretyTypeSchema,
                })
                    .safeParse(input);
                if (!typedInput.success)
                    return z.never();
                switch (typedInput.data.suretyType) {
                    case "contract":
                        return Submit$1.Request.Base.schema;
                    default:
                        return z.never();
                }
            });
        })(Request.Base || (Request.Base = {}));
        (function (New) {
            New.schema = zswitch((input) => {
                const typedInput = z
                    .object({
                    suretyType: suretyTypeSchema,
                })
                    .safeParse(input);
                if (!typedInput.success)
                    return z.never();
                switch (typedInput.data.suretyType) {
                    case "contract":
                        return Submit$1.Request.New.schema;
                    default:
                        return z.never();
                }
            });
        })(Request.New || (Request.New = {}));
        (function (Existing) {
            Existing.buildDtoSchema = (formSchema) => zswitch((input) => {
                const typedInput = z
                    .object({
                    suretyType: z.literal(SuretyType.contract),
                })
                    .safeParse(input);
                if (!typedInput.success)
                    return z.never();
                switch (typedInput.data.suretyType) {
                    case "contract":
                        return Submit$1.Request.Existing.buildDtoSchema(formSchema);
                    default:
                        return z.never();
                }
            });
        })(Request.Existing || (Request.Existing = {}));
    })(Submit.Request || (Submit.Request = {}));
    (function (Response) {
        Response.schema = Get.Response.schema;
    })(Submit.Response || (Submit.Response = {}));
})(Submit || (Submit = {}));

export { Get, Submit };
