import { useEffect, useRef, useState } from "react";
import { HiXMark } from "react-icons/hi2";
import { assertIsNode } from "../../utils";

export const AutoSaveInput = ({
	content,
	placeholder,
	createFn,
	closeEditor,
}: {
	content: string | undefined;
	placeholder?: string;
	createFn: (content: string) => void;
	closeEditor: () => void;
}) => {
	const [inputContent, setInputContent] = useState(content || "");
	const ref = useRef<HTMLInputElement>(null);

	const wrapperRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		function handleClickOutside(event: MouseEvent) {
			assertIsNode(event.target);
			if (wrapperRef?.current && !wrapperRef.current.contains(event.target)) {
				if (inputContent.length > 0) {
					createFn(inputContent);
				}
				closeEditor();
			}
		}
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [wrapperRef, inputContent, closeEditor]);

	useEffect(() => {
		if (ref.current) {
			ref.current.focus();
		}
	}, [ref]);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setInputContent(event.target.value);
	};

	const handleBlur = () => {
		if (inputContent.length > 0) {
			createFn(inputContent);
		}
		closeEditor();
	};

	return (
		<div ref={wrapperRef}>
			<div className="w-full flex flex-row justify-between items-center">
				<input
					value={inputContent}
					className="w-full h-[26px] bg-white border border-gray-300 text-[12px] px-3 py-2 rounded-md focus:outline-none"
					onChange={handleChange}
					placeholder={placeholder || ""}
					onBlur={handleBlur}
					ref={ref}
				/>
				<div
					onClick={closeEditor}
					className="flex items-center justify-center p-0 min-w-[19px] h-[19px] cursor-pointer text-gray-400 hover:text-gray-500 ml-3"
				>
					<HiXMark />
				</div>
			</div>
		</div>
	);
};
