import '../../../types/opaque.mjs';
import { zswitch } from '../../../types/utils.mjs';
import '../../../types/agency/carrier-agency-relationship.types.mjs';
import '../../../types/credit-report/company-credit-report.mjs';
import '../../../types/credit-report/individual-credit-report.mjs';
import '../../../types/payment/payment-template.mjs';
import '../../../types/surety/account/surety-account.types.mjs';
import '../../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../../types/surety/bond/surety-bond.types.mjs';
import '../../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../../types/surety/work-in-progress-statement/surety-work-in-progress-statement.mjs';
import '../../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../../types/surety/indemnity/indemnity-agreement.mjs';
import '../../../types/user/user.types.mjs';
import '../../../types/entityRelation/entity-relation.mjs';
import { Get as Get$1, Close as Close$1 } from './contract/contract-surety-bond.dto.mjs';
import * as contractSuretyBond_dto from './contract/contract-surety-bond.dto.mjs';
export { contractSuretyBond_dto as Contract };
import * as suretyBondPreview_dto from './preview/surety-bond-preview.dto.mjs';
export { suretyBondPreview_dto as Preview };

var Get;
(function (Get) {
    (function (Response) {
        Response.schema = zswitch((input) => {
            switch (input.suretyType) {
                case "contract":
                    return Get$1.Response.schema;
            }
        });
    })(Get.Response || (Get.Response = {}));
})(Get || (Get = {}));
var Close;
(function (Close) {
    (function (Request) {
        Request.schema = zswitch((input) => {
            switch (input.suretyType) {
                case "contract":
                    return Close$1.Request.schema;
            }
        });
    })(Close.Request || (Close.Request = {}));
})(Close || (Close = {}));

export { Close, Get };
