import * as agentPreview_dto from './agent-preview.dto.mjs';
export { agentPreview_dto as Preview };
import * as agentUserPreview_dto from './agent-user-preview.dto.mjs';
export { agentUserPreview_dto as UserPreview };
import * as agencyStats_dto from './agency-stats.dto.mjs';
export { agencyStats_dto as AgencyStats };
import { z } from 'zod';
import { agentUserRoleSchema, usStateOrTerritorySchema } from '../../enums.mjs';
import { email, firstName, lastName, npn, phoneNumber } from '../../types/opaque.mjs';
import '../../types/utils.mjs';
import '../../types/agency/carrier-agency-relationship.types.mjs';
import '../../types/credit-report/company-credit-report.mjs';
import '../../types/credit-report/individual-credit-report.mjs';
import '../../types/payment/payment-template.mjs';
import '../../types/surety/account/surety-account.types.mjs';
import '../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../types/surety/bond/surety-bond.types.mjs';
import '../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../types/surety/work-in-progress-statement/surety-work-in-progress-statement.mjs';
import '../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../types/surety/indemnity/indemnity-agreement.mjs';
import '../../types/user/user.types.mjs';
import '../../types/entityRelation/entity-relation.mjs';

var Invite;
(function (Invite) {
    (function (Request) {
        Request.schema = z.object({
            email: email,
            role: agentUserRoleSchema,
        });
    })(Invite.Request || (Invite.Request = {}));
})(Invite || (Invite = {}));
var CompleteAccountSetup;
(function (CompleteAccountSetup) {
    (function (Request) {
        Request.schema = z.object({
            firstName: firstName,
            lastName: lastName,
            npn: npn,
            phone: phoneNumber,
            residentLicenseState: usStateOrTerritorySchema,
        });
    })(CompleteAccountSetup.Request || (CompleteAccountSetup.Request = {}));
})(CompleteAccountSetup || (CompleteAccountSetup = {}));

export { CompleteAccountSetup, Invite };
