import { DateTime } from "luxon";
import { HiArrowRight } from "react-icons/hi";

export const ActionItemDateDisplay = ({
	dueAt,
	completedAt,
}: { dueAt?: DateTime | null; completedAt?: DateTime | null }) => {
	if (!dueAt) {
		return <></>;
	}
	if (!completedAt && dueAt) {
		return (
			<div className="text-gray-500 text-[12px]">{dueAt.toLocal().toFormat("LLL dd, yyyy")}</div>
		);
	}
	if (!completedAt && !dueAt) {
		return <div>No Due Date Set</div>;
	}
	if (completedAt) {
		return (
			<div className="flex gap-[10px] justify-start">
				<div className="text-gray-400 text-[11px]">{dueAt.toLocal().toFormat("LLL dd, yyyy")}</div>
				<HiArrowRight className="text-gray-400 w-[7.2px]" />
				<div className="text-gray-800 text-[11px]">
					{completedAt.toLocal().toFormat("LLL dd, yyyy")}
				</div>
			</div>
		);
	}
	return <></>;
};
