import { useMemo, useState } from "react";
import { HiArrowDownTray, HiOutlineArchiveBox, HiOutlineEye, HiOutlineLink } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import { useGetFileUrl } from "../../../../../api";
import { ConfirmationModalClickWrapper } from "../../../../../components/layout/ConfirmationModalClickWrapper";
import { LoadingModal } from "../../../../../components/layout/LoadingModal";
import {
	ArchivedBanner,
	CompleteBanner,
	GoBanner,
	StopBanner,
} from "../../../../../components/ui/Banner";
import { Button } from "../../../../../components/ui/Button";
import { Card } from "../../../../../components/ui/Card";
import { LocalFileUpload } from "../../../../../components/ui/LocalFileUpload";
import { MoreActionsMenu } from "../../../../../components/ui/MoreActionsMenu";
import { FormItemLabel } from "../../../../../components/ui/form/FormItemLabel";
import { bondRequestIssuanceMethodTypeLabelMap } from "../../../../../constants";
import {
	useArchiveRequest,
	useUnarchiveRequest,
	useUploadAgentIssuedBondForm,
} from "../../../../../domain/agent/request/api";
import { BondRequest } from "../../../../../domain/agent/request/types";
import { LocallyUploadedFile } from "../../../../../types";
import { SubmittedBondRequestLayout } from "../../../../shared/request/SubmittedBondRequestLayout";

type PendingBondRequestViewProps = {
	request: BondRequest & Extract<BondRequest, { status: "pending" }>;
};

export const PendingBondRequestView = ({ request }: PendingBondRequestViewProps) => {
	const navigate = useNavigate();
	const [agentIssuedBondFormFileUpload, setAgentIssuedBondFormFileUpload] = useState<
		LocallyUploadedFile<"filled_bond_form">[]
	>([]);
	const { uploadAgentIssuedBondForm, uploadAgentIssuedBondFormIsLoading } =
		useUploadAgentIssuedBondForm(request.id);
	const { getFileUrl: getBondFormFileUrl } = useGetFileUrl();
	const { archiveRequest } = useArchiveRequest();
	const { unarchiveRequest } = useUnarchiveRequest();
	const agentIssueStatus = useMemo(() => {
		if (request.bondIssuance.method === "agent") {
			if (
				request.bondIssuance.pendingBondFormReview ||
				request.bondIssuance.pendingIndemnityAgreement
			)
				return "not-ready";
			if (request.bondIssuance.pendingAgentIssue) return "ready";
			if (request.bondIssuance.pendingAgentIssueAdminReview) return "complete";
		}
	}, [request.bondIssuance]);
	const [termsAndConditionsOpen, setTermsAndConditionsOpen] = useState<boolean>(false);

	return (
		<SubmittedBondRequestLayout
			request={request}
			banner={
				<>
					{request.archived && <ArchivedBanner onUnarchive={() => unarchiveRequest(request.id)} />}
					{(() => {
						if (!!agentIssueStatus) {
							if (agentIssueStatus === "ready")
								return (
									<div className="flex flex-col gap-y-[20px] mb-[10px]">
										{
											<GoBanner
												text={
													<span>
														Ready for Agent Issue With Bond Number{" "}
														<span className="font-mono font-normal ml-[5px] select-text">
															{request.bondIssuance.bondNumber}
														</span>
													</span>
												}
											/>
										}
										<Card className="flex flex-col gap-y-[15px] p-[30px]">
											<FormItemLabel>Please upload the issued bond form</FormItemLabel>
											<LocalFileUpload
												value={agentIssuedBondFormFileUpload}
												onChange={setAgentIssuedBondFormFileUpload}
												allowDelete={true}
												allowedTypesAndLabels={{
													filled_bond_form: "Issued Bond Form",
												}}
												allowedExtensions={["pdf"]}
												maxFiles={1}
											/>
											<ConfirmationModalClickWrapper
												message="Are you sure?"
												confirmButtonText="Upload"
												onConfirm={() =>
													uploadAgentIssuedBondForm(agentIssuedBondFormFileUpload[0])
												}
											>
												<Button
													color={agentIssuedBondFormFileUpload.length === 0 ? "gray" : "light-blue"}
													filled
													thinFont
													className="w-fit mt-[3px] self-end"
													disabled={agentIssuedBondFormFileUpload.length === 0}
												>
													Upload Issued Bond Form
												</Button>
											</ConfirmationModalClickWrapper>
										</Card>
									</div>
								);
							if (agentIssueStatus === "complete")
								return <CompleteBanner text="Agent Issue Complete" />;
							return (
								<StopBanner text="Not Ready for Agent Issue: Please wait until you receive a notification to issue the bond" />
							);
						}
						return <></>;
					})()}
				</>
			}
			actionButtons={
				<>
					<div className="w-full flex flex-col border border-gray-500 divide-y divide-gray-500 rounded text-[14px]">
						<div className="p-[5px] text-center text-[13px] italic">Bond Issuance Method</div>
						<div className="p-[8px] px-[12px] flex justify-center font-semibold">
							<div className="w-max">
								{bondRequestIssuanceMethodTypeLabelMap[request.bondIssuance.method]}
							</div>
						</div>
					</div>
					<MoreActionsMenu
						items={[
							...(() => {
								if (
									request.bondIssuance.pendingAgentIssue ||
									request.bondIssuance.pendingAgentIssueAdminReview
								) {
									return [
										{
											label: "Download Bond Form",
											icon: <HiArrowDownTray className="text-[15px]" />,
											onClick: () =>
												getBondFormFileUrl({
													baseUrl: `/v2/surety/quotes/${request.id}/bond-form`,
													queryKey: ["bondFormFileUrl", request.id, true],
													asDownload: true,
												}),
										},
									];
								}
								return [];
							})(),
							{
								label: "View Terms & Conditions",
								icon: <HiOutlineEye className="text-[15px]" />,
								onClick: () => setTermsAndConditionsOpen(true),
							},
							{
								label: "Archive",
								icon: <HiOutlineArchiveBox className="text-[15px]" />,
								onClick: () => {
									archiveRequest(request.id);
								},
							},
						]}
						panelClassName="mt-[10px] w-[200px]"
					/>
				</>
			}
			termsAndConditionsOpen={termsAndConditionsOpen}
			cardBottom={
				<div className="w-full h-fit min-h-fit">
					<div className="w-full h-fit min-h-fit flex flex-col space-y-[40px] p-[40px] pt-[25px] bg-gray-50 border-t border-t-gray-200 rounded-b">
						{agentIssueStatus !== "ready" && (
							<div className="flex-col space-y-[14px]">
								<span className="text-[18px] text-gray-900 font-medium">
									What are we waiting on?
								</span>
								<ul className="text-gray-900 text-[14px] font-normal list-disc px-[18px] *:pl-[5px] space-y-[8px]">
									{request.bondIssuance.pendingIndemnityAgreement && (
										<li>
											<div className="flex items-center">
												<div>One or more indemnitors need to sign the indemnity agreement</div>
												<Button
													color="none"
													className="px-[5px] py-[0px] h-[14px]"
													onClick={() => navigate(`/accounts/${request.account.id}?st=indemnity`)}
												>
													<HiOutlineLink className="text-[14px] py-[0px]" />
												</Button>
											</div>
										</li>
									)}
									{request.bondIssuance.pendingBondFormReview && (
										<li>An inRev admin is reviewing the uploaded bond form</li>
									)}
									{request.bondIssuance.pendingInRevIssue &&
										request.bondIssuance.method === "electronic" && (
											<li>
												An inRev admin is generating an electronic bond form with digital signatures
												and seals
											</li>
										)}
									{request.bondIssuance.pendingInRevIssue &&
										request.bondIssuance.method === "physical" && (
											<li>
												An inRev admin is generating a physical bond form with wet signatures and
												raised seals
											</li>
										)}
									{request.bondIssuance.pendingAgentIssueAdminReview && (
										<li>An inRev admin is reviewing the agent issued bond form</li>
									)}
								</ul>
							</div>
						)}
						{request.bondIssuance.pendingAgentIssue && (
							<div className="flex-col space-y-[14px]">
								<span className="text-[18px] text-gray-900 font-medium">
									How do I issue this bond?
								</span>
								<ol className="text-gray-900 text-[14px] font-normal list-decimal  px-[18px] *:pl-[5px] space-y-[8px]">
									<li>Wait until you are notified that your bond is ready to issue</li>
									<li>Download the bond form</li>
									<li className="space-y-[12px] list-decimal list-item">
										<span>Apply an effective date that is between:</span>
										<ul className="list-disc list-inside bg-gray-200/70 w-fit px-[20px] py-[10px] rounded-md">
											<li>2 days before the date of underwriting approval</li>
											<li>60 days after the date of underwriting approval</li>
										</ul>
									</li>
									{!!request.bondIssuance.bondNumber && (
										<li className="pt-[15px] pb-[10px]">
											Apply bond number{" "}
											<span className="font-mono bg-gray-200/80 py-[6px] px-[10px] ml-[3px] rounded-sm">
												{request.bondIssuance.bondNumber}
											</span>
										</li>
									)}
									{!!!request.bondIssuance.bondNumber && <li>Apply the provided bond number</li>}
									<li>
										Apply the{" "}
										<span className="font-semibold underline">{request.carrier.legalName}</span>{" "}
										seal
									</li>
									<li>Upload the issued bond form</li>
								</ol>
							</div>
						)}
					</div>
				</div>
			}
		>
			{uploadAgentIssuedBondFormIsLoading && <LoadingModal />}
		</SubmittedBondRequestLayout>
	);
};
