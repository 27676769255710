import { HiPlus } from "react-icons/hi2";

export const AdminActionItemsHeader = ({
	filterComponent,
	createActionItem,
}: {
	filterComponent: React.ReactNode;
	createActionItem: () => void;
}) => {
	return (
		<div className="flex flex-row justify-between items-center w-full h-[40px] border-b border-gray-100 p-5 sticky top-0 z-10">
			<div className="text-gray-700 text-[13px] font-medium">Action Items</div>
			<div className="flex items-center gap-4">
				{filterComponent}
				<div
					className="text-[12px] flex items-center justify-center cursor-pointer text-gray-400 hover:text-gray-800"
					onClick={createActionItem}
				>
					<HiPlus />
				</div>
			</div>
		</div>
	);
};
