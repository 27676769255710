import { Dtos, formatToDollar, formatToPercent } from "@inrev/common";
import { Card } from "../../../components/ui/Card";
import { formatFromISODate } from "../../../utils";
import { AdminDataList, AdminDataListProps } from "./AdminDataList";

type AdminBondRequestBidResultsCardProps = {
	request: Dtos.SuretyQuote.Submitted.Contract.BidToFinal.Get.Response.Admin;
};

export const AdminBondRequestBidResultsCard = ({
	request,
}: AdminBondRequestBidResultsCardProps) => {
	const data = (() => {
		const data: AdminDataListProps["data"] = [];

		data.push({
			label: "Low Bid (Principal's Bid)",
			value: formatToDollar(request.actualBidAmount),
		});
		data.push({ label: "Second Lowest Bid", value: formatToDollar(request.secondBid) });

		data.push({ label: "Contract Amount", value: formatToDollar(request.contractAmount) });
		data.push({
			label: "Bid Spread",
			value: formatToPercent(request.underwriting.quote.calculations.bidSpread),
		}),
			data.push({
				label: "Actual Bid / Requested Bid",
				value: formatToPercent(request.underwriting.quote.calculations.actualToRequestedBidSpread),
			});
		data.push({
			label: "Bid Date",
			value: formatFromISODate(request.actualBidDate),
		});
		data.push({
			label: "Contract Date",
			value: formatFromISODate(request.contractDate),
		});
		data.push({
			label: "Days From Bid Date To Contract Date",
			value: request.underwriting.quote.calculations.daysFromBidToContractDate,
		});

		return data;
	})();

	return (
		<Card>
			<div className="w-full px-[33px] pt-[26px] pb-[25px] flex flex-col space-y-[25px]">
				<div className="text-[18px] text-gray-800 font-semibold leading-[23px]">Bid Results</div>
				<AdminDataList data={data} />
			</div>
		</Card>
	);
};
