import { z } from 'zod';
import '../../../../../../types/opaque.mjs';
import { zswitch } from '../../../../../../types/utils.mjs';
import '../../../../../../types/agency/carrier-agency-relationship.types.mjs';
import '../../../../../../types/credit-report/company-credit-report.mjs';
import '../../../../../../types/credit-report/individual-credit-report.mjs';
import '../../../../../../types/payment/payment-template.mjs';
import '../../../../../../types/surety/account/surety-account.types.mjs';
import '../../../../../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../../../../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../../../../../types/surety/bond/surety-bond.types.mjs';
import '../../../../../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../../../../../types/surety/work-in-progress-statement/surety-work-in-progress-statement.mjs';
import '../../../../../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../../../../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../../../../../types/surety/indemnity/indemnity-agreement.mjs';
import '../../../../../../types/user/user.types.mjs';
import '../../../../../../types/entityRelation/entity-relation.mjs';
import { Get as Get$3 } from './submitted-bid-contract-surety-quote-preview.dto.mjs';
import * as submittedBidContractSuretyQuotePreview_dto from './submitted-bid-contract-surety-quote-preview.dto.mjs';
export { submittedBidContractSuretyQuotePreview_dto as Bid };
import { Get as Get$1 } from './submitted-bid-to-final-contract-surety-quote-preview.dto.mjs';
import * as submittedBidToFinalContractSuretyQuotePreview_dto from './submitted-bid-to-final-contract-surety-quote-preview.dto.mjs';
export { submittedBidToFinalContractSuretyQuotePreview_dto as BidToFinal };
import { Get as Get$2 } from './submitted-final-contract-surety-quote-preview.dto.mjs';
import * as submittedFinalContractSuretyQuotePreview_dto from './submitted-final-contract-surety-quote-preview.dto.mjs';
export { submittedFinalContractSuretyQuotePreview_dto as Final };

var Get;
(function (Get) {
    (function (Response) {
        Response.schema = zswitch((input) => {
            switch (input.contractSuretyType) {
                case "bid":
                    return Get$3.Response.schema;
                case "final":
                    return Get$2.Response.schema;
                case "bid_to_final":
                    return Get$1.Response.schema;
                default:
                    return z.never();
            }
        });
    })(Get.Response || (Get.Response = {}));
})(Get || (Get = {}));

export { Get };
