import { FileExtension, SuretyType } from "@inrev/common";
import { useEffect, useState } from "react";
import { HiOutlineCheckCircle, HiXMark } from "react-icons/hi2";
import { ConfirmationModalClickWrapper } from "../../../../components/layout/ConfirmationModalClickWrapper";
import { LoadingModal } from "../../../../components/layout/LoadingModal";
import { Button } from "../../../../components/ui/Button";
import { Card } from "../../../../components/ui/Card";
import { CardGrayBody } from "../../../../components/ui/CardGrayBody";
import { LocalFileUpload } from "../../../../components/ui/LocalFileUpload";
import { NotificationCard } from "../../../../components/ui/NotificationCard";
import { PDFViewerControl } from "../../../../components/ui/PDFViewerModal";
import { useAdminUploadCompletedIndemnityAgreement } from "../../../../domain/admin/account/api";
import type { AdminSuretyAccount } from "../../../../domain/admin/account/types";
import { useAdminDeleteFinancialStatement } from "../../../../domain/admin/financial-statement/api";
import { LocallyUploadedFile } from "../../../../types";
import { AdminDataList } from "../../shared/AdminDataList";

export const IndemnityAgreementSection = ({
	account,
	setPdfViewerControl,
}: {
	account: AdminSuretyAccount;
	setPdfViewerControl: (control: PDFViewerControl | undefined) => void;
}) => {
	const indemnity = account.contract.indemnity;
	const [indemnityAgreementFileUpload, setIndemnityAgreementFileUpload] = useState<
		LocallyUploadedFile[]
	>([]);
	const {
		uploadCompletedIndemnityAgreement,
		uploadCompletedIndemnityAgreementIsLoading,
		uploadCompletedIndemnityAgreementSuccess,
	} = useAdminUploadCompletedIndemnityAgreement(account.id, SuretyType.contract);
	const { deleteFinancialStatementIsLoading } = useAdminDeleteFinancialStatement(account.id);

	useEffect(() => {
		if (uploadCompletedIndemnityAgreementSuccess) setIndemnityAgreementFileUpload([]);
	}, [uploadCompletedIndemnityAgreementSuccess]);

	return (
		<CardGrayBody
			header={
				<div className="flex items-center space-x-[10px]">
					<div className="flex-1">Indemnity</div>
					<div className="flex items-center space-x-[5px] text-[14px] text-gray-500 italic">
						{indemnity.upToDate && (
							<>
								<HiOutlineCheckCircle className="text-[18px] stroke-[1.75] text-inrev-green" />
								<span>Up to date</span>
							</>
						)}
						{!indemnity.upToDate && (
							<>
								<HiXMark className="text-[18px] stroke-[1.75] text-inrev-red" />
								<span>Not up to date</span>
							</>
						)}
					</div>
				</div>
			}
			contentClassName="p-[40px] pt-[25px] space-y-[35px]"
			headerClassName="py-[15px] px-[25px] text-[17px] font-medium"
		>
			<div className="flex flex-col space-y-[14px] w-fit min-w-[70%] auto-rows-min">
				<div className="text-[15px] text-gray-500 italic">Required Indemnitors</div>
				<div className="flex flex-col space-y-[5px]">
					{indemnity.requiredIndemnitors.companies.map((indemnitor, index) => (
						<Card
							key={index}
							className="flex items-center space-x-[30px] px-[15px] py-[10px] shadow-sm"
						>
							<span className="text-[14px] flex-1">{indemnitor.name}</span>
						</Card>
					))}
					{indemnity.requiredIndemnitors.individuals.map((indemnitor, index) => (
						<Card
							key={index}
							className="flex items-center space-x-[30px] px-[15px] py-[10px] shadow-sm"
						>
							<div className="flex items-center flex-1 space-x-[10px] text-[14px]">
								<span>{indemnitor.name}</span>
								{indemnitor.email && (
									<span className="text-gray-500 font-medium">{indemnitor.email}</span>
								)}
							</div>
						</Card>
					))}
				</div>
			</div>
			{!!(
				indemnity.indemnityWaivers.companies.length || indemnity.indemnityWaivers.individuals.length
			) && (
				<div className="flex flex-col space-y-[14px] w-fit min-w-[70%] auto-rows-min">
					<div className="text-[15px] text-gray-500 italic">Waived Indemnitors</div>
					<div className="flex flex-col space-y-[5px]">
						{indemnity.indemnityWaivers.companies.map((waiver, index) => (
							<Card
								key={index}
								className="flex items-center space-x-[30px] px-[15px] py-[10px] shadow-sm"
							>
								<span className="text-[14px] flex-1">{waiver.name}</span>
							</Card>
						))}
						{indemnity.indemnityWaivers.individuals.map((indemnitor, index) => (
							<Card
								key={index}
								className="flex items-center space-x-[30px] px-[15px] py-[10px] shadow-sm"
							>
								<div className="flex items-center flex-1 space-x-[10px] text-[14px]">
									<span>{indemnitor.name}</span>
									{indemnitor.email && (
										<span className="text-gray-500 font-medium">{indemnitor.email}</span>
									)}
								</div>
							</Card>
						))}
					</div>
				</div>
			)}
			<div className="flex flex-col space-y-[10px] w-full auto-rows-min">
				<div className="flex items-center justify-between space-x-[15px]">
					<div className="text-[15px] text-gray-500 italic">Indemnity Agreement</div>
					{!!indemnity.indemnityAgreements.length && (
						<Button
							color="gray"
							filled
							thinFont
							onClick={() =>
								setPdfViewerControl({
									fetchUrl: `/v2/admin/surety/accounts/${account.id}/indemnity-agreement/${SuretyType.contract}`,
									queryKey: ["indemnityAgreementFileUrl", account.id, SuretyType.contract],
								})
							}
						>
							View
						</Button>
					)}
				</div>
				{!!indemnity.indemnityAgreements.length &&
					indemnity.indemnityAgreements[0].status === "pending" &&
					indemnity.indemnityAgreements[0].signatureMethod === "physical" && (
						<NotificationCard
							type="action"
							text="Upload copy of completed physical indemnity agreement"
						/>
					)}
				{!indemnity.indemnityAgreements.length && (
					<span className="!mt-[10px] text-[15px] text-gray-800">None</span>
				)}
				{!!indemnity.indemnityAgreements.length && (
					<Card className="flex flex-col space-y-[20px] px-[15px] py-[10px] pb-[20px] shadow-sm">
						<AdminDataList
							data={[
								{
									label: "Status",
									value: indemnity.indemnityAgreements[0].status,
								},
								{
									label: "Signature Method",
									value: indemnity.indemnityAgreements[0].signatureMethod,
								},
								{
									label: "Indemnitors",
									value: (
										<div className="w-fit flex flex-col gap-[8px] text-[14px]">
											{indemnity.indemnityAgreements[0].indemnitors.companies.map(
												(indemnitor, index) => (
													<div
														key={index}
														className="flex justify-between items-center flex-1 space-x-[10px] text-[14px]"
													>
														<span className="text-left">{indemnitor.name}</span>
														{indemnitor.signerEmail && (
															<>
																<div className="w-[1px] h-[18px] bg-gray-200"></div>
																<span className="text-gray-500 font-medium text-right">
																	{indemnitor.signerEmail}
																</span>
															</>
														)}
														{indemnity.indemnityAgreements[0].signatureMethod === "electronic" && (
															<>
																<div className="w-[1px] h-[18px] bg-gray-200"></div>
																{indemnitor.completed !== undefined && !indemnitor.completed && (
																	<span className="italic font-normal text-gray-500">
																		Hasn't signed
																	</span>
																)}
																{indemnitor.completed && (
																	<span className="font-medium text-inrev-green">Signed</span>
																)}
															</>
														)}
													</div>
												),
											)}
											{indemnity.indemnityAgreements[0].indemnitors.individuals.map(
												(indemnitor, index) => (
													<div
														key={index}
														className="flex justify-between items-center flex-1 space-x-[10px] text-[14px]"
													>
														<span className="text-left">{indemnitor.name}</span>
														{indemnitor.signerEmail && (
															<>
																<div className="w-[1px] h-[18px] bg-gray-200"></div>
																<span className="text-gray-500 font-medium text-right">
																	{indemnitor.signerEmail}
																</span>
															</>
														)}
														{indemnity.indemnityAgreements[0].signatureMethod === "electronic" && (
															<>
																<div className="w-[1px] h-[18px] bg-gray-200"></div>
																{!indemnitor.completed && (
																	<span className="italic font-normal text-gray-500">
																		Hasn't signed
																	</span>
																)}
																{indemnitor.completed && (
																	<span className="font-medium text-inrev-green">Signed</span>
																)}
															</>
														)}
													</div>
												),
											)}
										</div>
									),
								},
							]}
						/>
						{indemnity.indemnityAgreements.length && (
							<div className="flex flex-col items-end space-y-[20px]">
								<LocalFileUpload
									value={indemnityAgreementFileUpload}
									allowedTypesAndLabels={{
										indemnity_agreement: "Completed Indemnity Agreement",
									}}
									maxFiles={1}
									allowedExtensions={[FileExtension.pdf]}
									onChange={setIndemnityAgreementFileUpload}
								/>
								<ConfirmationModalClickWrapper
									message="Are you sure?"
									subMessage="The system will issue bonds for any request in this account that is only pending an indemnity agreement"
									shouldConfirm={!!indemnityAgreementFileUpload.length}
									onConfirm={() =>
										indemnity.id &&
										uploadCompletedIndemnityAgreement({
											file: indemnityAgreementFileUpload[0],
											indemnityAgreementId: indemnity.id,
										})
									}
								>
									<Button
										color="light-blue"
										thinFont
										filled
										className="w-fit"
										disabled={!indemnityAgreementFileUpload.length}
									>
										Upload
									</Button>
								</ConfirmationModalClickWrapper>
								{uploadCompletedIndemnityAgreementIsLoading && <LoadingModal />}
								{deleteFinancialStatementIsLoading && <LoadingModal />}
							</div>
						)}
					</Card>
				)}
			</div>
		</CardGrayBody>
	);
};
