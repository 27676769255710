import { useAuth0 } from "@auth0/auth0-react";
import { UnknownArrayOrTuple, UnknownRecord } from "type-fest/source/internal";
import { ApiError } from "../providers/GlobalErrorHandlingProvider";

export const useAuthHeader = () => {
	const { getAccessTokenSilently } = useAuth0();
	const getAuthHeader = async () => {
		return `Bearer ${await getAccessTokenSilently()}`;
	};

	return { getAuthHeader };
};

type UseRequestFn = {
	<T>(
		url: string,
		body?: UnknownRecord | UnknownArrayOrTuple | FormData,
		responseType?: "json",
	): Promise<T>;
	<T>(
		url: string,
		body?: UnknownRecord | UnknownArrayOrTuple | FormData,
		responseType?: "text",
	): Promise<string>;
	<T>(
		url: string,
		body?: UnknownRecord | UnknownArrayOrTuple | FormData,
		responseType?: "response",
	): Promise<Response>;
	<T>(
		url: string,
		body?: UnknownRecord | UnknownArrayOrTuple | FormData,
		responseType?: "none",
	): Promise<void>;
	<T>(
		url: string,
		body?: UnknownRecord | UnknownArrayOrTuple | FormData,
		responseType?: "json" | "text" | "response" | "none",
	): Promise<T | string | Response | void>;
};

const useRequestFn = (method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE") => {
	const { getAuthHeader } = useAuthHeader();

	const requestFn: UseRequestFn = async <T>(
		url: string,
		body?: UnknownRecord | UnknownArrayOrTuple | FormData,
		responseType: "json" | "text" | "response" | "none" = "json",
	) => {
		try {
			const headers: HeadersInit = {
				Authorization: await getAuthHeader(),
			};
			if (body !== undefined && !(body instanceof FormData)) {
				headers["Content-Type"] = "application/json";
			}

			const response = await fetch(import.meta.env.VITE_API_URL + url, {
				method,
				mode: "cors",
				headers,
				body: (() => {
					if (body instanceof FormData) return body;
					return JSON.stringify(body);
				})(),
			});
			if (!response.ok) {
				const responseErr = await response.json();
				throw new ApiError(responseErr, { url, method, body });
			}
			if (responseType === "json") return (await response.json()) as T;
			if (responseType === "text") return await response.text();
			if (responseType === "response") return await response;
		} catch (error) {
			throw error;
		}
	};

	return requestFn;
};

export const useRequest = () => ({
	get: useRequestFn("GET"),
	post: useRequestFn("POST"),
	put: useRequestFn("PUT"),
	patch: useRequestFn("PATCH"),
	_delete: useRequestFn("DELETE"),
});
