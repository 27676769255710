import { z } from 'zod';
import { Exposure as Exposure$1, Metadata as Metadata$1 } from './contract/contract-surety-account.types.mjs';
import * as contractSuretyAccount_types from './contract/contract-surety-account.types.mjs';
export { contractSuretyAccount_types as Contract };
import * as suretyAccountDraft from './draft/surety-account-draft.mjs';
export { suretyAccountDraft as Draft };
import { exposureSchema } from '../../utils.mjs';

var Exposure;
(function (Exposure) {
    Exposure.initialize = () => ({
        quotes: 0,
        bonds: 0,
        total: 0,
        contract: Exposure$1.initialize(),
    });
    Exposure.schema = exposureSchema.extend({
        contract: Exposure$1.schema,
    });
})(Exposure || (Exposure = {}));
var Metadata;
(function (Metadata) {
    Metadata.schema = z.object({
        contract: Metadata$1.schema.nullish(),
    });
})(Metadata || (Metadata = {}));
var Config;
(function (Config) {
    Config.schema = z.object({
        pauseBondIssuance: z.boolean(),
        adminManagedReUnderwriteForNonExpiredUnderwriting: z.boolean(),
        useSyntheticFinancialStatement: z.boolean(),
    });
})(Config || (Config = {}));
var Underwriting;
(function (Underwriting) {
    (function (LetterGrade) {
        LetterGrade["A"] = "A";
        LetterGrade["A_MINUS"] = "A-";
        LetterGrade["B_PLUS"] = "B+";
        LetterGrade["B"] = "B";
        LetterGrade["B_MINUS"] = "B-";
        LetterGrade["F"] = "F";
    })(Underwriting.LetterGrade || (Underwriting.LetterGrade = {}));
})(Underwriting || (Underwriting = {}));
var ContactRelationshipConfig;
(function (ContactRelationshipConfig) {
    let Contract;
    (function (Contract) {
        Contract.schema = z.object({
            includeInUnderwriting: z.boolean(),
            includeInIndemnity: z.boolean(),
        });
    })(Contract = ContactRelationshipConfig.Contract || (ContactRelationshipConfig.Contract = {}));
    ContactRelationshipConfig.schema = z.object({
        contract: Contract.schema,
    });
})(ContactRelationshipConfig || (ContactRelationshipConfig = {}));

export { Config, ContactRelationshipConfig, Exposure, Metadata, Underwriting };
