import { useEffect, useState } from "react";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { PercentInput } from "../PercentInput";

type FormPercentInputProps<TFieldValues extends FieldValues> = {
	className?: string;
	control: Control<TFieldValues, any, any>;
	name: Path<TFieldValues>;
	errorMessage?: string;
	precision?: number;
	min?: number;
	max?: number;
	condensed?: true;
	compact?: true;
	updateOnBlur?: true;
	readonly?: boolean;
};

export const FormPercentInput = <TFieldValues extends FieldValues>(
	props: FormPercentInputProps<TFieldValues>,
) => {
	const [controlledValue, setControlledValue] = useState<string>("");

	return (
		<Controller
			{...props}
			render={({ field, fieldState }) => {
				useEffect(() => {
					if (props.updateOnBlur) setControlledValue(field.value);
				}, []);
				useEffect(() => {
					if (props.updateOnBlur && field.value !== controlledValue) {
						field.onBlur();
					}
				}, [field.value]);
				return (
					<PercentInput
						{...props}
						{...field}
						defaultValue={field.value}
						value={props.updateOnBlur ? controlledValue : field.value}
						error={fieldState.error}
						onChange={(e) =>
							props.updateOnBlur
								? setControlledValue(e.target.value ?? "")
								: field.onChange(e.target.value ?? "")
						}
						onBlur={props.updateOnBlur ? () => field.onChange(controlledValue) : field.onBlur}
					/>
				);
			}}
		/>
	);
};
