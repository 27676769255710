import { UserType } from "@inrev/common";
import { useContext, useState } from "react";
import { QueryClient, useQuery } from "react-query";
import { ADMIN_BASE_URL, AGENT_BASE_URL } from "../../../constants";
import { useAuthenticatedUser } from "../../../providers/AuthenticatedUserProvider";
import { GlobalErrorMessageModalContext } from "../../../providers/GlobalErrorHandlingProvider";
import { useRequest } from "../../../utils/request";
import { SuretyAccountPreview } from "../../agent/account/types";
import { SuretyBondPreview } from "../../agent/bond/types";
import { BondRequestPreview } from "../../agent/request/types";

export const useFetchAccountPreviews = () => {
	const { user } = useAuthenticatedUser();
	const { get } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);
	const baseUrl = user.type === UserType.admin ? ADMIN_BASE_URL : AGENT_BASE_URL;

	const { data, error, isLoading } = useQuery({
		queryKey: ["accountPreviews"],
		queryFn: async () => await get<SuretyAccountPreview[]>(baseUrl + "/surety/accounts/previews"),
		onError: (error: Error) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return {
		accountPreviews: data,
		accountPreviewsError: error,
		accountPreviewsIsLoading: isLoading,
	};
};

export const useFetchBondRequestPreviews = () => {
	const { user } = useAuthenticatedUser();
	const { get } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);
	const baseUrl = user.type === UserType.admin ? ADMIN_BASE_URL : AGENT_BASE_URL;

	const { data, error, isLoading } = useQuery({
		queryKey: ["bondRequestPreviews"],
		queryFn: async () => await get<BondRequestPreview[]>(baseUrl + "/surety/quotes/previews"),
		onError: (error: Error) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return {
		bondRequestPreviews: data,
		bondRequestPreviewsError: error,
		bondRequestPreviewsIsLoading: isLoading,
	};
};

export const useFetchBondPreviews = () => {
	const { user } = useAuthenticatedUser();
	const { get } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);
	const baseUrl = user.type === UserType.admin ? ADMIN_BASE_URL : AGENT_BASE_URL;

	const { data, error, isLoading } = useQuery({
		queryKey: ["bondPreviews"],
		queryFn: async () => await get<SuretyBondPreview[]>(baseUrl + "/surety/bonds/previews"),
		onError: (error: Error) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return { bondPreviews: data, bondPreviewsError: error, bondPreviewsIsLoading: isLoading };
};

export const usePrefetchPreviews = (queryClient: QueryClient) => {
	const { user } = useAuthenticatedUser();
	const { get } = useRequest();
	const [prefetchPreviewsIsLoading, setPrefetchPreviewsIsLoading] = useState<boolean>(true);

	const baseUrl = user.type === UserType.admin ? ADMIN_BASE_URL : AGENT_BASE_URL;

	const prefetchPreviews = async () => {
		await Promise.all([
			queryClient.prefetchQuery({
				queryKey: ["accountPreviews"],
				queryFn: async () =>
					await get<SuretyAccountPreview[]>(baseUrl + "/surety/accounts/previews"),
			}),
			queryClient.prefetchQuery({
				queryKey: ["bondRequestPreviews"],
				queryFn: async () => {
					return await get<BondRequestPreview[]>(baseUrl + "/surety/quotes/previews");
				},
			}),
			queryClient.prefetchQuery({
				queryKey: ["bondPreviews"],
				queryFn: async () => await get<SuretyBondPreview[]>(baseUrl + "/surety/bonds/previews"),
			}),
		]);

		setPrefetchPreviewsIsLoading(false);
	};

	return { prefetchPreviews, prefetchPreviewsIsLoading };
};
