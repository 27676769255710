import { z } from 'zod';
import { Get as Get$1 } from '../../../surety/bond/surety-bond-mixins.dto.mjs';

var Get;
(function (Get) {
    (function (Response) {
        Response.baseSchema = Get$1.Response.baseSchema
            .omit({ bondForm: true })
            .extend({
            closedNote: z.string().optional(),
        });
    })(Get.Response || (Get.Response = {}));
})(Get || (Get = {}));

export { Get };
