import { AttachmentFile, AttachmentFileType, defaultAttachmentTypeLabelMap } from "@inrev/common";
import { ReactNode } from "react";
import { HiOutlineArrowDownTray } from "react-icons/hi2";
import { useGetFileUrl, useGetFilesAsZip } from "../../api";
import { FileUploadItem } from "../ui/FileUploadItem";
import { Spinner } from "../ui/Spinner";
import { AttachmentUpload, AttachmentUploadProps } from "../ui/Upload";

type AttachmentSectionProps<
	TFileType extends AttachmentFileType = AttachmentFileType,
	TAllowedTypesAndLabels extends Partial<Record<TFileType, string>> = Partial<
		Record<TFileType, string>
	>,
	TAllowedType extends Extract<TFileType, keyof TAllowedTypesAndLabels> = Extract<
		TFileType,
		keyof TAllowedTypesAndLabels
	>,
	TRequired extends TAllowedType[] | undefined = TAllowedType[] | undefined,
> = {
	attachments: AttachmentFile<TFileType>[];
	upload?: Omit<
		AttachmentUploadProps<TFileType, TAllowedTypesAndLabels, TAllowedType, TRequired>,
		"value"
	>;
	download?: {
		baseUrl: string;
		baseQueryKey: string;
	};
	downloadAllName?: string;
	updateTypes?: Omit<
		AttachmentUploadProps<TFileType, TAllowedTypesAndLabels, TAllowedType, TRequired>,
		"value"
	>;
	expandedByDefault?: boolean;
	allowTypeConfirmation?: boolean;
	showTabs?: boolean;
	typeLabelMap: Partial<typeof defaultAttachmentTypeLabelMap>;
	allowDelete?: boolean;
	header?: string;
	subHeader?: ReactNode;
};

export const AttachmentSection = <
	TFileType extends AttachmentFileType = AttachmentFileType,
	TAllowedTypesAndLabels extends Partial<Record<TFileType, string>> = Partial<
		Record<TFileType, string>
	>,
	TAllowedType extends Extract<TFileType, keyof TAllowedTypesAndLabels> = Extract<
		TFileType,
		keyof TAllowedTypesAndLabels
	>,
	TRequired extends TAllowedType[] | undefined = TAllowedType[] | undefined,
>({
	attachments,
	upload,
	download,
	downloadAllName,
	typeLabelMap,
	allowDelete,
	showTabs,
	header = "Attachments",
	allowTypeConfirmation,
	subHeader,
	expandedByDefault,
}: AttachmentSectionProps<TFileType, TAllowedTypesAndLabels, TAllowedType, TRequired>) => {
	const { getFileUrl } = useGetFileUrl();
	const { getFilesAsZip, filesAsZipLoading } = useGetFilesAsZip();

	const getAllDownloadUrls = () => {
		if (!download) return;
		const files = attachments.map((attachment) => ({
			fileName: attachment.file.name,
			extension: attachment.file.extension,
			url: `${download.baseUrl}/${attachment.id}`,
		}));
		getFilesAsZip({
			files,
			queryKey: `${download.baseQueryKey}-all`,
			zippedFileName: downloadAllName ?? "Attachments",
		});
	};

	return (
		<div className="flex flex-col w-full space-y-[28px]">
			<div className="flex items-center w-full h-fit min-h-fit">
				<span className="flex-1 text-[20px] text-gray-900 font-medium">{header}</span>
				{attachments.length > 0 && (
					<>
						{filesAsZipLoading ? (
							<div className="px-[16px] h-[34px] flex items-center justify-center">
								<Spinner
									spinnerClassName="h-[19px] w-[19px]"
									containerClassName="h-[19px] w-[19px]"
								/>
							</div>
						) : (
							<div
								color="none"
								onClick={() => getAllDownloadUrls()}
								className="cursor-pointer p-[4px] text-gray-600/65 hover:text-gray-800"
							>
								<HiOutlineArrowDownTray className="text-[20px]" />
							</div>
						)}
					</>
				)}
			</div>
			{subHeader && subHeader}
			{(() => {
				if (upload !== undefined) {
					return (
						<AttachmentUpload
							{...upload}
							showTabs={showTabs}
							value={attachments}
							allowDelete={allowDelete}
							enablePDFViewer={true}
							expandedByDefault={expandedByDefault}
							allowTypeConfirmation={allowTypeConfirmation}
							baseUrl={download?.baseUrl}
							queryKey={download?.baseQueryKey}
							onDownload={(attachmentId) =>
								!!download &&
								getFileUrl({
									baseUrl: `${download.baseUrl}/${attachmentId}`,
									queryKey: [download.baseQueryKey, attachmentId, true],
									asDownload: true,
								})
							}
						/>
					);
				}
				if (attachments.length) {
					return (
						<div className="flex flex-col space-y-[10px]">
							{attachments.map((attachment) => (
								<FileUploadItem
									key={attachment.id}
									id={attachment.id}
									name={attachment.file.name}
									enablePDFViewer={true}
									typesConfirmedByAdmin={attachment.file.typesConfirmedByAdmin}
									allowTypeConfirmation={allowTypeConfirmation}
									expandedByDefault={expandedByDefault}
									types={attachment.file.types}
									extension={attachment.file.extension}
									typeLabelMap={typeLabelMap}
									onDownload={(attachmentId) =>
										!!download &&
										getFileUrl({
											baseUrl: `${download.baseUrl}/${attachmentId}`,
											queryKey: [download.baseQueryKey, attachmentId, true],
											asDownload: true,
										})
									}
									allowDelete={false}
								/>
							))}
						</div>
					);
				}

				return <span className="text-[15px] text-gray-600 font-normal italic">No attachments</span>;
			})()}
		</div>
	);
};
