import { z } from 'zod';
import { dateTimeSchema, SuretyBondIssuanceMethod } from '../../../../enums.mjs';
import { Create as Create$1 } from './contract-surety-bond-issuance-mixins.dto.mjs';

var Create;
(function (Create) {
    (function (Request) {
        const baseSchema = Create$1.Request.baseSchema.extend({
            contractSuretyType: z.literal("final"),
        });
        const dataSchema = Create$1.Request.baseDataSchema.extend({
            contractDate: dateTimeSchema,
            contractDescription: z.string(),
        });
        Request.schema = z.discriminatedUnion("issuanceMethod", [
            baseSchema.extend({
                issuanceMethod: z.literal(SuretyBondIssuanceMethod.agent),
                data: z.custom(() => true).transform(() => undefined),
            }),
            baseSchema.extend({
                issuanceMethod: z.literal(SuretyBondIssuanceMethod.electronic),
                data: dataSchema,
            }),
            baseSchema.extend({
                issuanceMethod: z.literal(SuretyBondIssuanceMethod.physical),
                data: dataSchema,
            }),
        ]);
    })(Create.Request || (Create.Request = {}));
})(Create || (Create = {}));

export { Create };
