import { z } from 'zod';
import { dateTimeSchema } from '../../../enums.mjs';
import { companyContactId } from '../../opaque.mjs';
import { currencySchema } from '../../utils.mjs';

const WorkInProgressStatement = z.object({
    projectBacklogGrossProfit: currencySchema.optional(),
    projectBacklogCost: currencySchema,
    companyContactId: companyContactId,
    date: dateTimeSchema,
});

export { WorkInProgressStatement };
