import { useContext, useEffect, useRef, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { HiOutlinePencilSquare, HiOutlineTrash, HiOutlineUser, HiPlus } from "react-icons/hi2";
import { useCustomCompareEffect } from "use-custom-compare";
import { useAddressValidation } from "../../../../../../../api";
import { FormItem } from "../../../../../../../components/layout/form/FormItem";
import { FormItemGroup } from "../../../../../../../components/layout/form/FormItemGroup";
import { FormRow } from "../../../../../../../components/layout/form/FormRow";
import { FormSection } from "../../../../../../../components/layout/form/FormSection";
import { cn } from "../../../../../../../components/lib/utils";
import { Button } from "../../../../../../../components/ui/Button";
import { Icon } from "../../../../../../../components/ui/Icon";
import { RadioCardItem } from "../../../../../../../components/ui/RadioCardItem";
import { FormAddressAutocomplete } from "../../../../../../../components/ui/form/FormAddressAutocomplete";
import { FormErrorCard } from "../../../../../../../components/ui/form/FormErrorCard";
import { IndividualCreditReportPermission } from "../../../../../../../components/ui/form/FormIndividualCreditReportPermissionList";
import { FormInput } from "../../../../../../../components/ui/form/FormInput";
import { FormItemLabel } from "../../../../../../../components/ui/form/FormItemLabel";
import { FormQuestionLabel } from "../../../../../../../components/ui/form/FormQuestionLabel";
import { FormRadioGroup } from "../../../../../../../components/ui/form/FormRadioGroup";
import {
	BondRequestDraftData,
	BondRequestIndividualDraftData,
} from "../../../../../../../domain/agent/request/types";
import {
	formatAddress,
	formatName,
	useIsFirstRender,
	useScrollToId,
} from "../../../../../../../utils";
import { DraftBondRequestContext } from "../../DraftBondRequestView";
import { DraftBondRequestNewPrincipalCompanyModal } from "./DraftBondRequestNewPrincipalCompanyModal";
import { DraftBondRequestNewPrincipalIndividualModal } from "./DraftBondRequestNewPrincipalIndividualModal";

export const DraftBondRequestNewPrincipalSection = () => {
	const domReadyRef = useScrollToId();
	const isFirstRender = useIsFirstRender();
	const { request } = useContext(DraftBondRequestContext);
	const principalSchema = request.draft.schema.principal;
	const formMethods = useFormContext<BondRequestDraftData>();
	const principalAddress = formMethods.watch("principal.company.address");
	const principalFein = formMethods.watch("principal.company.fein");
	const companiesFieldArray = useFieldArray({
		control: formMethods.control,
		name: "principal.companies",
		keyName: "draftId",
	});
	const [selectedCompanyIndex, setSelectedCompanyIndex] = useState<number | undefined>();
	const [companyEditorOpen, setCompanyEditorOpen] = useState<boolean>(false);
	const companies = formMethods.watch("principal.companies");
	const individualsFieldArray = useFieldArray({
		control: formMethods.control,
		name: "principal.individuals",
		keyName: "draftId",
	});
	const [selectedIndividualIndex, setSelectedIndividualIndex] = useState<number | undefined>();
	const [individualEditorOpen, setIndividualEditorOpen] = useState<boolean>(false);
	const individuals = formMethods.watch("principal.individuals");
	const owners = individuals.filter((individual) => individual.type === "owner");
	const creditReportPermission = formMethods.watch("principal.creditReportPermission");
	const creditReportPermissionFieldArray = useFieldArray({
		control: formMethods.control,
		name: "principal.creditReportPermission",
		keyName: "id",
	});
	const individualsContainerRef = useRef<HTMLDivElement>(null);
	const { addressFieldControl } = useAddressValidation<BondRequestDraftData>(
		formatAddress(principalAddress),
		formMethods,
		"principal.company.address",
		{ validateOnFirstBlur: true },
	);

	const handleDeleteCompany = (companyIndex: number) => {
		companiesFieldArray.remove(companyIndex);
	};
	const handleDeleteIndividual = (
		individualIndex: number,
		spouse?: BondRequestIndividualDraftData,
	) => {
		const individualCreditReportPermissionIndex = creditReportPermission.findIndex(
			(permission) => permission.id === individuals[individualIndex].draftId,
		);
		if (individualCreditReportPermissionIndex === -1) throw new Error();

		if (spouse) {
			const spouseIndex = individuals.findIndex(
				(individual) => individual.draftId === spouse.draftId,
			);
			if (spouseIndex === -1) throw new Error();

			if (spouse.type === "owner") {
				individualsFieldArray.update(spouseIndex, { ...spouse, spouseDraftId: "", married: false });
				creditReportPermissionFieldArray.remove(individualCreditReportPermissionIndex);
				individualsFieldArray.remove(individualIndex);
			} else {
				creditReportPermissionFieldArray.remove(individualCreditReportPermissionIndex);
				individualsFieldArray.remove([individualIndex, spouseIndex]);
			}
		} else {
			creditReportPermissionFieldArray.remove(individualCreditReportPermissionIndex);
			individualsFieldArray.remove(individualIndex);
		}
	};

	useCustomCompareEffect(
		() => {
			if (!isFirstRender && !!owners.length && individualsContainerRef.current) {
				document
					.getElementById("individuals")
					?.scrollIntoView({ block: "center", behavior: "smooth" });
			}
		},
		[{ length: owners.length }],
		(prev, next) => prev.length >= next.length,
	);

	useEffect(() => {
		if (formMethods.formState.isSubmitted) {
			formMethods.trigger();
		}
	}, [companies, individuals, creditReportPermission]);

	useEffect(() => {
		if (selectedCompanyIndex !== undefined) setCompanyEditorOpen(true);
	}, [selectedCompanyIndex]);

	useEffect(() => {
		if (!companyEditorOpen) setSelectedCompanyIndex(undefined);
	}, [companyEditorOpen]);

	useEffect(() => {
		if (selectedIndividualIndex !== undefined) setIndividualEditorOpen(true);
	}, [selectedIndividualIndex]);

	useEffect(() => {
		if (!individualEditorOpen) setSelectedIndividualIndex(undefined);
	}, [individualEditorOpen]);

	if (!principalSchema) return null;
	return (
		<div
			ref={domReadyRef}
			className="w-full min-h-fit flex flex-col space-y-[65px] pt-[18px] pb-[125px]"
		>
			<FormItemGroup schemaInclude={principalSchema.include.company}>
				<FormRow>
					<FormItem className="flex-1">
						<FormItemLabel marker>Name</FormItemLabel>
						<FormInput name="principal.company.name" control={formMethods.control} />
					</FormItem>
					<FormItem className="w-1/3">
						<FormItemLabel marker>FEIN</FormItemLabel>
						<FormInput
							name="principal.company.fein"
							transformer={{
								input: (value) => value as string,
								output: (value) => value.replace(/\D/g, ""),
							}}
							control={formMethods.control}
						/>
					</FormItem>
				</FormRow>
				<FormItem>
					<FormItemLabel marker>Address</FormItemLabel>
					<FormAddressAutocomplete control={addressFieldControl} />
				</FormItem>
			</FormItemGroup>
			<FormErrorCard
				error={formMethods.formState.errors.principal?.companies}
				className="!mt-[60px] !mb-[-25px]"
			/>
			<FormSection
				topPadding
				header="Company Owners, Subsidiaries, and Affiliates"
				marker
				subHeader={
					<span
						className={cn(
							formMethods.formState.errors.principal?.companies ? "text-red-500" : undefined,
						)}
					>
						Please list all companies that are owners of, subsidiaries of, or generally affiliated
						with the principal
					</span>
				}
				id="companies"
				schemaInclude={principalSchema.include.companies}
			>
				{companies.length ? (
					<div className="w-full flex flex-col space-y-[25px] ml-[-5px] mb-[25px]">
						{companies.map((company, companyIndex) => (
							<div
								key={company.draftId}
								className="w-full flex flex-col space-y-[10px] last:pb-[5px]"
							>
								<div
									key={company.draftId}
									className={cn(
										`w-full h-[50px] flex items-center space-x-[15px] p-[15px] bg-white border border-gray-200 rounded-[5px] shadow-sm cursor-pointer`,
										{
											"border-red-500": formMethods.getFieldState(
												`principal.companies.${companyIndex}`,
											).error,
										},
									)}
									onClick={() => setSelectedCompanyIndex(companyIndex)}
								>
									<HiOutlineUser className="text-[18px] text-gray-800" />
									<div className="flex-1 font-medium text-gray-900">{company.name}</div>
									{formMethods.getFieldState(`principal.companies.${companyIndex}`).error && (
										<span className="text-red-500 ml-2">
											{
												formMethods.getFieldState(`principal.companies.${companyIndex}`).error
													?.message
											}
										</span>
									)}
									<div className="flex items-center space-x-[5px]">
										<div className="p-[5px] hover:bg-gray-100 rounded">
											<HiOutlinePencilSquare className="text-[19px] text-gray-600" />
										</div>
										<div
											className="p-[5px] hover:bg-gray-100 rounded"
											onClick={(e) => {
												e.stopPropagation();
												handleDeleteCompany(companyIndex);
											}}
										>
											<HiOutlineTrash className="text-[19px] text-red-500" />
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
				) : null}
				<Button
					onClick={() => setCompanyEditorOpen(true)}
					className="w-fit"
					color="gray"
					rounded
					filled
					thinFont
				>
					<div className="flex min-w-fit items-center space-x-[5px]">
						<HiPlus className="text-[17px] stroke-[0.65] text-gray-900" />
						<div className="min-w-fit">Add Company</div>
					</div>
				</Button>
				{companyEditorOpen && (
					<DraftBondRequestNewPrincipalCompanyModal
						principalFein={principalFein}
						companies={companies}
						fieldArray={companiesFieldArray}
						selectedIndex={selectedCompanyIndex}
						onClose={() => setCompanyEditorOpen(false)}
					/>
				)}
			</FormSection>
			<FormSection
				topPadding
				header="Individual Owners"
				marker
				subHeader={
					<span
						className={cn(
							formMethods.formState.errors.principal?.individuals !== undefined &&
								!Array.isArray(formMethods.formState.errors.principal.individuals)
								? "text-red-500"
								: undefined,
						)}
					>
						Please list all individuals that own at least 20% of the principal or any of the above
						companies
					</span>
				}
				id="individuals"
				schemaInclude={principalSchema.include.individuals}
			>
				{individuals.length ? (
					<div className="w-full flex flex-col space-y-[25px] ml-[-5px] mb-[30px]">
						{owners.map((owner) => {
							const formattedOwnerName = formatName(owner);
							const ownerIndex = individuals.findIndex(
								(individual) => individual.draftId === owner.draftId,
							);
							if (ownerIndex === -1) throw new Error();
							const ownerCreditReportPermissionIndex = creditReportPermission.findIndex(
								(permission) => permission.id === owner.draftId,
							);
							const ownerCreditReportPermission =
								creditReportPermission[ownerCreditReportPermissionIndex];
							const spouse = individuals.find(
								(individual) => individual.spouseDraftId === owner.draftId,
							);

							return (
								<div
									key={owner.draftId}
									className="w-full flex flex-col space-y-[10px] last:pb-[5px]"
								>
									<div
										key={owner.draftId}
										className="w-full h-[50px] flex items-center space-x-[15px] p-[15px] bg-white border border-gray-200 rounded-[5px] shadow-sm cursor-pointer"
										onClick={() => setSelectedIndividualIndex(ownerIndex)}
									>
										<HiOutlineUser className="text-[18px] text-gray-800" />
										<div className="flex-1 flex items-center space-x-[5px]">
											<div className="font-medium text-gray-900">{formattedOwnerName}</div>
											{spouse?.type === "spouse" && (
												<div className="text-gray-500">& {formatName(spouse)}</div>
											)}
										</div>
										<div className="flex items-center space-x-[5px]">
											<div className="p-[5px] hover:bg-gray-100 rounded">
												<HiOutlinePencilSquare className="text-[19px] text-gray-600" />
											</div>
											<div
												className="p-[5px] hover:bg-gray-100 rounded"
												onClick={(e) => {
													e.stopPropagation();
													handleDeleteIndividual(ownerIndex, spouse);
												}}
											>
												<HiOutlineTrash className="text-[19px] text-red-500" />
											</div>
										</div>
									</div>
									{ownerCreditReportPermissionIndex !== -1 && (
										<IndividualCreditReportPermission
											value={ownerCreditReportPermission.permission}
											name={formattedOwnerName}
											error={
												formMethods.formState.errors.principal?.creditReportPermission?.[
													ownerCreditReportPermissionIndex
												]?.permission
											}
											className="ml-[10px] text-[13px]"
											onSelect={() =>
												creditReportPermissionFieldArray.update(ownerCreditReportPermissionIndex, {
													...ownerCreditReportPermission,
													permission: true,
												})
											}
											onDeselect={() =>
												creditReportPermissionFieldArray.update(ownerCreditReportPermissionIndex, {
													...ownerCreditReportPermission,
													permission: false,
												})
											}
										/>
									)}
								</div>
							);
						})}
					</div>
				) : null}
				<Button
					onClick={() => setIndividualEditorOpen(true)}
					className="ml-[-5px] w-fit"
					color="gray"
					rounded
					filled
					thinFont
				>
					<div className="flex min-w-fit items-center space-x-[5px]">
						<HiPlus className="text-[20px] text-gray-800" />
						<div className="min-w-fit">Add Individual</div>
					</div>
				</Button>
				{individualEditorOpen && (
					<DraftBondRequestNewPrincipalIndividualModal
						individuals={individuals}
						individualsFieldArray={individualsFieldArray}
						creditReportPermission={creditReportPermission}
						creditReportPermissionFieldArray={creditReportPermissionFieldArray}
						selectedIndex={selectedIndividualIndex}
						onClose={() => setIndividualEditorOpen(false)}
					/>
				)}
			</FormSection>
			<FormItem schemaInclude={principalSchema.include.exposureSize}>
				<FormQuestionLabel marker className="pb-[5px]">
					Which best describes the total bonding need for this principal and all of its owners and
					subsidiaries over the next 12 months?
				</FormQuestionLabel>
				<FormRadioGroup
					name="principal.exposureSize"
					control={formMethods.control}
					className="w-full max-w-[510px] min-w-max"
				>
					<RadioCardItem value="small">
						<div className="py-[20px] px-[25px] w-full h-full flex flex-col items-start space-y-[8px] leading-[20px]">
							<div className="text-[16px] text-gray-900 font-semibold leading-[18px]">
								Up to $500k
							</div>
							<div className="text-[14px] text-gray-600">
								Minimal eligibility details, credit score
							</div>
							<div className="text-[12px] text-gray-800 font-medium mt-[4px] leading-[15px] flex space-x-[6px] items-center">
								<Icon type="lightning" className="fill-inrev-yellow" />
								<span>Fastest underwriting</span>
							</div>
						</div>
					</RadioCardItem>
					<RadioCardItem value="medium">
						<div className="py-[20px] px-[25px] w-full h-full flex flex-col items-start space-y-[8px] leading-[20px]">
							<div className="text-[16px] text-gray-900 font-semibold leading-[18px]">
								$500k - $1M
							</div>
							<div className="text-[14px] text-gray-600">
								Some financial details, some eligibility details, credit score
							</div>
						</div>
					</RadioCardItem>
					<RadioCardItem value="large">
						<div className="py-[20px] px-[25px] w-full h-full flex flex-col items-start space-y-[8px] leading-[20px]">
							<div className="text-[16px] text-gray-900 font-semibold leading-[18px]">
								More than $1M
							</div>
							<div className="text-[14px] text-gray-600">
								Full financial details, full eligibility details, credit score
							</div>
							<div className="text-[12px] text-gray-800 font-medium mt-[4px] leading-[15px] flex space-x-[6px] items-center">
								<Icon type="long-arrow" width={8} height={11} className="fill-inrev-green" />
								<span>Highest limits</span>
							</div>
						</div>
					</RadioCardItem>
				</FormRadioGroup>
			</FormItem>
		</div>
	);
};
