import { Relations, UserId } from "@inrev/common";
import { useState } from "react";
import { CheckMarkBubble } from "../../../../../../components/ui/CheckMarkBubble";
import {
	useDeleteActionItem,
	useUpdateActionItem,
} from "../../../../../../domain/admin/action-item/api";
import { ActionItem } from "../../../../../../domain/admin/action-item/type";
import { useAuthenticatedUser } from "../../../../../../providers/AuthenticatedUserProvider";
import { AdminAssigneeControls } from "../../../AdminAssigneeControls";
import { ToolContextMenu, ToolContextMenuItem } from "../../shared/components/ToolContextMenu";
import { ActionItemContentDisplay } from "../components/ActionItemContentDisplay";
import { ActionItemDateDisplay } from "../components/ActionItemDateDisplay";
import { ActionItemTitleDisplay } from "../components/ActionItemTitleDisplay";
import { AdminActionItemLayout } from "../layouts/AdminActionItemLayout";
import { CreateOrUpdateActionItem } from "./AdminActionItemCreateSection";

export const AdminActionItemFull = ({
	actionItem,
	defaultUnderwriterId,
	isEditingDefault,
}: { actionItem: ActionItem; defaultUnderwriterId: UserId; isEditingDefault: boolean }) => {
	const { user } = useAuthenticatedUser();
	const { toggleCompleted } = useUpdateActionItem();
	const { deleteActionItem } = useDeleteActionItem();
	const [isEditing, setIsEditing] = useState(isEditingDefault);

	const content = (
		<>
			<div className="flex flex-col justify-start gap-2 flex-grow">
				<ActionItemTitleDisplay title={actionItem.title} completed={!!actionItem.completedAt} />
				<ActionItemDateDisplay dueAt={actionItem.dueAt} completedAt={actionItem.completedAt} />
			</div>
			<div className="flex items-center gap-2 justify-center self-start min-w-fit">
				<AdminAssigneeControls
					assignedEntity={{ entityType: "actionItem", entityId: actionItem.id }}
					currentAssignee={actionItem.assigneeId}
				/>
				<ToolContextMenu anchor={{ gap: -16, to: "bottom start" }}>
					<ToolContextMenuItem
						onClick={() => navigator.clipboard.writeText(`${window.location}`)}
						label="Copy Link"
					/>
					<ToolContextMenuItem onClick={() => setIsEditing(true)} label="Edit" />
					<ToolContextMenuItem
						onClick={() => {
							deleteActionItem({
								actionItemId: actionItem.id,
								relation: {
									relationType: actionItem.relationType,
									relationId: actionItem.relationId,
								} as Relations.ActionItems,
							});
						}}
						label="Delete"
					/>
				</ToolContextMenu>
			</div>
		</>
	);

	return (
		<>
			{isEditing ? (
				<CreateOrUpdateActionItem
					close={() => {
						setIsEditing(false);
					}}
					isUpdate={true}
					actionItemId={actionItem.id}
					relation={
						{
							relationId: actionItem.relationId,
							relationType: actionItem.relationType,
						} as Relations.ActionItems
					}
					inputDefaults={{
						title: actionItem.title,
						content: actionItem.content,
						dueAt: actionItem.dueAt,
						assigneeId: actionItem.assigneeId,
						isComplete: actionItem.status === "complete",
					}}
					defaultUnderwriterId={defaultUnderwriterId}
				/>
			) : (
				<AdminActionItemLayout
					containerClassName="border-b-0"
					statusIcon={
						<CheckMarkBubble
							isComplete={actionItem.status === "complete"}
							toggleComplete={() => toggleCompleted(actionItem, user.id)}
						/>
					}
					content={content}
					footer={
						actionItem.content !== undefined &&
						actionItem.content !== null &&
						actionItem.content.length > 0 && (
							<ActionItemContentDisplay content={actionItem.content} />
						)
					}
				/>
			)}
		</>
	);
};
