import { z } from 'zod';
import { SuretyAccountStatus } from '../../../../../enums.mjs';
import { draftSchema, Data } from '../../../../../types/surety/account/draft/contract/contract-surety-account-draft.mjs';
import { Get as Get$1 } from '../../surety-account.dto.mixins.mjs';

var Get;
(function (Get) {
    (function (Response) {
        Response.schema = Get$1.Response.baseSchema.extend({
            status: z.literal(SuretyAccountStatus.draft),
            draft: draftSchema,
        });
    })(Get.Response || (Get.Response = {}));
})(Get || (Get = {}));
var Create;
(function (Create) {
    (function (Request) {
        Request.schema = z.object({
            suretyType: z.literal("contract"),
        });
    })(Create.Request || (Create.Request = {}));
    (function (Response) {
        Response.schema = Get.Response.schema;
    })(Create.Response || (Create.Response = {}));
})(Create || (Create = {}));
var Update;
(function (Update) {
    (function (Request) {
        Request.schema = Data.schema;
    })(Update.Request || (Update.Request = {}));
    (function (Response) {
        Response.schema = Get.Response.schema;
    })(Update.Response || (Update.Response = {}));
})(Update || (Update = {}));

export { Create, Get, Update };
